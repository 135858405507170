import styled from 'styled-components';

export const Aside = styled.aside`
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;

  h3 {
    margin: 0;
    margin-bottom: 24px;
    padding: 24px;
    background: white;
    border-radius: 10px;
    text-align: center;
  }
`;

export const Title = styled.span`
  margin-top: 16px;
  font-weight: bold;
  color: #006633;
  display: block;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .span {
    color: #666666;
  }
`;

export const SyndicateContainer = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const DataInfoContainer = styled.div`
  display: flex;
  flex-direction: row;

  span {
    margin-left: 16px;
  }
`;
