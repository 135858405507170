import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col, Form, Button, FormControlProps } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { Formik } from 'formik';
import Select from 'react-select';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/useToast';

import { Container } from './styles';

interface IState {
  id: number;
  uf: string;
}

interface IUnions {
  id: string;
  nome_fantasia: string;
}

interface ISelectProps {
  value: string | undefined;
  label: string;
}

interface IWorker {
  id: string;
  first_name: string;
  last_name: string;
  cpf: string;
  phone: string;
  activity_profile: string;
}

interface IProps {
  handleWorkers: (values: IWorker[]) => void;
  currentPage: number;
  pageHandler: (value: number) => number | void;
}

const FilterBar: React.FC<IProps> = ({
  handleWorkers,
  currentPage,
  pageHandler,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [unions, setUnions] = useState<ISelectProps[]>();
  const [states, setStates] = useState<ISelectProps[]>([]);

  const cpfRef = useRef<HTMLInputElement>(null);

  const typeOptions = [
    { value: 'industrial', label: 'Industrial' },
    { value: 'rural', label: 'Rural' },
    { value: 'market', label: 'Comércio' },
    { value: 'public-service', label: 'Serviço Público' },
  ];

  const filterWorkers = useCallback(
    async values => {
      try {
        const filteredWorkers = await api.get('/workers', {
          params: { ...values, page: currentPage },
        });
        handleWorkers(filteredWorkers.data);
        pageHandler(1);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar as notícias.',
        });
      }
    },
    [addToast, handleWorkers, currentPage, pageHandler],
  );

  const cpfValidator = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    // let cpf = event.target.value.replace(/\D/g, '');
    const cpf = target.value.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/g,
      '$1.$2.$3-$4',
    );

    if (cpfRef.current) {
      cpfRef.current.value = cpf;
    }
  };

  const loadUnions = useCallback(async () => {
    const loadedUnions = await api.get<IUnions[]>('/unions');

    const unionsArray = loadedUnions.data.map(syndicate => {
      return {
        value: syndicate.id,
        label: syndicate.nome_fantasia,
      };
    });

    setUnions(unionsArray);
  }, []);

  const loadStates = useCallback(async () => {
    const loadedStates = await api.get<IState[]>('/ibge/states');

    const parsedStates = await Promise.all(
      loadedStates.data.map(state => {
        return {
          label: state.uf,
          value: state.uf,
        };
      }),
    );

    setStates(parsedStates);
  }, []);

  useEffect(() => {
    if (user.role === 'admin') {
      loadUnions();
      loadStates();
    }
  }, [user, loadUnions, loadStates]);

  return (
    <Container>
      <Formik
        initialValues={{
          name: '',
          cpf: '',
          syndicate_id: '',
          activity_profile: '',
          state: '',
        }}
        onSubmit={filterWorkers}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="findByName">
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="findByCPF">
                  <Form.Control
                    ref={cpfRef}
                    type="text"
                    placeholder="Digite o CPF"
                    maxLength={14}
                    value={values.cpf}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      cpfValidator(event);
                      setFieldValue('cpf', event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              {user.role === 'admin' && (
                <Col>
                  <Form.Group controlId="findBySyndicate">
                    <Select
                      options={unions}
                      placeholder="Selecione o sindicato"
                      onChange={selectedSyndicate => {
                        setFieldValue('syndicate_id', selectedSyndicate?.value);
                      }}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              )}
              {user.role === 'admin' && (
                <Col>
                  <Form.Group controlId="state">
                    <Select
                      options={states}
                      placeholder="Selecione o Estado"
                      onChange={state => {
                        setFieldValue('state', state?.value);
                      }}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Group controlId="findByType">
                  <Select
                    options={typeOptions}
                    onChange={selectedType => {
                      setFieldValue('activity_profile', selectedType?.value);
                    }}
                    placeholder="Escolha o tipo"
                    isClearable
                  />
                </Form.Group>
              </Col>
              <Col className="col-1 d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  <FiSearch size={24} color="#ffffff" />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
