import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

import { Container, Content, Main } from './styles';

interface IBasePageProps {
  title: string;
}

const BasePage: React.FC<IBasePageProps> = ({ children, title }) => {
  return (
    <Container>
      <Sidebar />
      <Content>
        <Header title={title} />
        <Main>{children}</Main>
        <Footer />
      </Content>
    </Container>
  );
};

export default BasePage;
