import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import { Container, Sidebar } from './styles';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/useToast';

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  role: string;
}

interface IMessages {
  id: string;
  key_from_server: 'income' | 'outcome';
  message_type: 'text' | 'audio' | 'document' | 'image';
  data: string;
  media_url: string;
  media_server_url: string;
  media_mime_type: string;
  media_size: number;
  media_server_size: number;
  latitude: number;
  longitude: number;
  created_at: Date;
}

interface IComplaint {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  messages: IMessages[];
  assignedUsers: IUser[];
}

interface ISelectProps {
  value: string;
  label: string;
}

interface IProps {
  complaint: IComplaint | undefined;
}

const LeftSidebar: React.FC<IProps> = ({ complaint }) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState<ISelectProps[]>();
  const [followers, setFollowers] = useState<ISelectProps[]>();

  const handleAssigneeUser = useCallback(
    async assignedUsers => {
      try {
        const assignedUsersIds = assignedUsers.map(
          (assignedUser: ISelectProps) => assignedUser.value,
        );

        await api.put(`/complaints/${complaint?.id}`, {
          assignedUsers: assignedUsersIds,
        });

        addToast({
          title: 'Sucesso',
          body: 'Denúncia atualizada com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar atualizar a denúncia.',
        });
      }
    },
    [addToast, complaint],
  );

  const handleUpdatePriority = useCallback(
    async priority => {
      try {
        await api.put(`/complaints/${complaint?.id}`, {
          priority: priority.value,
        });

        addToast({
          title: 'Sucesso',
          body: 'Denúncia atualizada com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar atualizar a denúncia.',
        });
      }
    },
    [complaint, addToast],
  );

  const priorityOptions = [
    { value: '0', label: 'Não urgente' },
    { value: '1', label: 'Pouco urgente' },
    { value: '2', label: 'Normal' },
    { value: '3', label: 'Urgente' },
    { value: '4', label: 'Muito urgente' },
  ];

  useEffect(() => {
    const loadData = async () => {
      const loadUsers = await api.get<IUser[]>('/users');
      const loadUnions = await api.get<ISyndicate[]>('/unions');

      const parsedUsers = loadUsers.data.map(loadedUser => {
        return {
          value: loadedUser.id,
          label: `${loadedUser.first_name} ${loadedUser.last_name}`,
        };
      });

      const parsedUnions = loadUnions.data.map(loadedSyndicate => {
        return {
          value: loadedSyndicate.id,
          label: loadedSyndicate.nome_fantasia,
        };
      });

      setUsers(parsedUsers);
      setFollowers([...parsedUsers, ...parsedUnions]);

      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading]);

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Sidebar>
            <h3>Protocolo</h3>
            <Form>
              <>
                <Form.Group controlId="assignedUsers-form" className="mb-3">
                  <Form.Label>Responsável</Form.Label>
                  <Select
                    isMulti
                    options={users}
                    placeholder="Selecione o responsável"
                    defaultValue={
                      complaint
                        ? complaint.assignedUsers.map(assignedUser => {
                            return {
                              value: assignedUser.id,
                              label: `${assignedUser.first_name} ${assignedUser.last_name}`,
                            };
                          })
                        : undefined
                    }
                    onChange={value => {
                      handleAssigneeUser(value);
                    }}
                  />
                </Form.Group>
                {/* <Form.Group controlId="formBasicEmail">
                  <Form.Label>Seguidores</Form.Label>
                  <Select isMulti options={followers} />
                </Form.Group> */}
              </>
            </Form>
          </Sidebar>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Sidebar>
            <h3>Prioridade</h3>
            <Form>
              <>
                <Form.Group
                  controlId="formBasicEmail"
                  style={{ flexDirection: 'column', width: '100%' }}
                  className="mb-3"
                >
                  <Select
                    defaultValue={
                      priorityOptions[complaint ? complaint.priority : 2]
                    }
                    options={priorityOptions}
                    onChange={value => {
                      handleUpdatePriority(value);
                    }}
                  />
                </Form.Group>
              </>
            </Form>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
};

export default LeftSidebar;
