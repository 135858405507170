/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useCallback } from 'react';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  FiBell,
  FiAlertTriangle,
  FiUsers,
  FiPaperclip,
  FiMessageSquare,
} from 'react-icons/fi';
import { useAuth } from '../../../../hooks/auth';

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge,
  NotificationContainer,
  NotificationIcon,
  NotificationContent,
} from './styles';

interface INotification {
  id: string;
  content: string;
  user_id: string;
  type:
    | 'complaint'
    | 'message'
    | 'worker'
    | 'union'
    | 'user'
    | 'news'
    | 'companies';
  entity_id: string;
  read: boolean;
  created_at: Date;
}

const NotificationControl: React.FC = () => {
  const { user } = useAuth();

  const [newNotifications, setNewNotifications] = useState<INotification[]>([]);
  const [olderNotifications, setOlderNotifications] = useState<INotification[]>(
    [],
  );

  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const readNotifications = useCallback(
    isOpen => {
      setUnreadNotifications(0);

      if (!isOpen) {
        const unreadNotificationsIds = newNotifications.map(
          notification => notification.id,
        );

      }
    },
    [newNotifications, user],
  );

  const renderNotification = (notification: INotification) => {
    switch (notification.type) {
      case 'complaint':
        return (
          <DropdownItem
            key={notification.id}
            href={`/complaints/${notification.entity_id}`}
          >
            <NotificationContainer>
              <NotificationIcon>
                <FiAlertTriangle color="#ff6600" size={24} />
              </NotificationIcon>
              <NotificationContent>
                <div className="content">
                  <span>{notification.content}</span>
                </div>
                <div className="timestamp">
                  {formatDistance(
                    new Date(notification.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    },
                  )}
                </div>
              </NotificationContent>
            </NotificationContainer>
          </DropdownItem>
        );

      case 'worker':
        return (
          <DropdownItem
            key={notification.id}
            href={`/workers/${notification.entity_id}`}
          >
            <NotificationContainer>
              <NotificationIcon>
                <FiUsers color="#ff6600" size={24} />
              </NotificationIcon>
              <NotificationContent>
                <div className="content">
                  <span>{notification.content}</span>
                </div>
                <div className="timestamp">
                  {formatDistance(
                    new Date(notification.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    },
                  )}
                </div>
              </NotificationContent>
            </NotificationContainer>
          </DropdownItem>
        );

      case 'message':
        return (
          <DropdownItem
            key={notification.id}
            href={`/complaint/${notification.entity_id}`}
          >
            <NotificationContainer>
              <NotificationIcon>
                <FiMessageSquare color="#ff6600" size={24} />
              </NotificationIcon>
              <NotificationContent>
                <div className="content">
                  <span>{notification.content}</span>
                </div>
                <div className="timestamp">
                  {formatDistance(
                    new Date(notification.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    },
                  )}
                </div>
              </NotificationContent>
            </NotificationContainer>
          </DropdownItem>
        );

      case 'news':
        return (
          <DropdownItem
            key={notification.id}
            href={`/news/${notification.entity_id}`}
          >
            <NotificationContainer>
              <NotificationIcon>
                <FiPaperclip color="#ff6600" size={24} />
              </NotificationIcon>
              <NotificationContent>
                <div className="content">
                  <span>{notification.content}</span>
                </div>
                <div className="timestamp">
                  {formatDistance(
                    new Date(notification.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    },
                  )}
                </div>
              </NotificationContent>
            </NotificationContainer>
          </DropdownItem>
        );

      default:
        return '';
    }
  };

  return (
    <Dropdown onToggle={readNotifications}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <FiBell color="#ffffff" size={20} />
        {unreadNotifications > 0 && <Badge>{unreadNotifications}</Badge>}
      </Dropdown.Toggle>

      <DropdownMenu>
        {newNotifications.length > 0 && (
          <h6 className="dropdown-header">Novas</h6>
        )}
        {newNotifications.map(notification => renderNotification(notification))}
        {olderNotifications.length > 0 && (
          <h6 className="dropdown-header">Antigas</h6>
        )}
        {olderNotifications.map(notification =>
          renderNotification(notification),
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationControl;
