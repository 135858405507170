/* eslint-disable import/no-duplicates */
import React, { useEffect, useCallback, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { registerLocale } from 'react-datepicker';
import { ErrorMessage, useFormikContext } from 'formik';

import ptBR from 'date-fns/locale/pt-BR';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import Datepicker from '../../../../components/Datepicker';

registerLocale('ptBR', ptBR);

interface ITransaction {
  id: string;
  invoice_id: string;
  worker_id: string;
  transaction_date: Date;
  value: number | string;
  transaction_method: string;
}

interface IInvoice {
  id: string;
  order: number;
  worker_id: string;
  syndicate_id: string;
  value: number;
  is_paid: boolean;
  invoice_date: Date;
  due_date: Date;
  subscription_id: string;
  transactions: ITransaction[];
}

interface ISelect {
  [key: string]: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IWorker {
  id: string;
  first_name: string;
  last_name: string;
}

interface FormikContextProps {
  syndicate_id: string;
  worker_id: string;
  value: number;
  invoice_date: Date;
  due_date: Date;
}

interface IProps {
  invoice: IInvoice;
}

const InvoiceData: React.FC<IProps> = ({ invoice }) => {
  const { user } = useAuth();
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<FormikContextProps>();

  const [loading, setLoading] = useState(true);

  const [workers, setWorkers] = useState<ISelect[]>();
  const [unions, setUnions] = useState<ISelect[]>();

  const parseWorkers = useCallback((loadedWorkers: IWorker[]) => {
    const parsedWorkers = loadedWorkers.map(worker => {
      return {
        label: `${worker.first_name} ${worker.last_name}`,
        value: worker.id,
      };
    });

    setWorkers(parsedWorkers);
  }, []);

  const cleanup = useCallback(() => {
    setWorkers([] as ISelect[]);
    setUnions([] as ISelect[]);
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (user.role === 'admin') {
        const loadedUnions = await api.get<ISyndicate[]>('/unions');
        if (mounted) {
          setUnions(
            loadedUnions.data.map(syndicate => {
              return {
                label: syndicate.nome_fantasia,
                value: syndicate.id,
              };
            }),
          );
        }
      }

      // load workers by sindicate;
      if (mounted) {
        const loadedWorkers = await api.get(
          `/workers/list-by-sindicate/${invoice.syndicate_id}`,
        );
        parseWorkers(loadedWorkers.data);
        setLoading(false);
      }
    };

    if (mounted && invoice) {
      loadData();
    }

    return () => {
      mounted = false;
      cleanup();
    };
  }, [loading, user, invoice, parseWorkers, cleanup]);

  return (
    <Form>
      {!loading && values && (
        <>
          {user.role === 'admin' && (
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formSyndicate">
                  <Form.Label>Sindicato</Form.Label>
                  <Form.Control
                    as="div"
                    custom
                    isValid={touched.syndicate_id && !errors.syndicate_id}
                    isInvalid={!!errors.syndicate_id}
                  >
                    <Select
                      options={unions}
                      placeholder="Selecione o sindicato"
                      value={unions?.filter(
                        syndicate => syndicate.value === values.syndicate_id,
                      )}
                      isDisabled
                      name="syndicate_id"
                    />
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage name="syndicate_id" />
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formWorker">
                <Form.Control
                  as="div"
                  custom
                  isValid={touched.worker_id && !errors.worker_id}
                  isInvalid={!!errors.worker_id}
                >
                  <Form.Label>Trabalhador</Form.Label>
                  <Select
                    options={workers}
                    placeholder="Selecione o trabalhador"
                    value={workers?.filter(
                      worker => worker.value === values.worker_id,
                    )}
                    isDisabled
                    name="worker_id"
                  />
                </Form.Control>
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                <ErrorMessage name="worker_id" />
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formValue">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  as="div"
                  custom
                  isValid={touched.value && !errors.value}
                  isInvalid={!!errors.value}
                >
                  <NumberFormat
                    prefix={`R$ `}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowLeadingZeros
                    placeholder="Digite o valor da fatura"
                    className="form-control"
                    name="value"
                    value={values.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={invoice.is_paid === true}
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="value" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formInvoiceDate">
                <Form.Label>Data da fatura</Form.Label>
                <Form.Control
                  as="div"
                  custom
                  isValid={touched.invoice_date && !errors.invoice_date}
                  isInvalid={!!errors.invoice_date}
                >
                  <Datepicker
                    locale="ptBR"
                    name="invoice_date"
                    placeholderText="Selecione a data de contribuição"
                    onChange={date => setFieldValue('invoice_date', date)}
                    selected={values.invoice_date}
                    disabled={invoice.is_paid === true}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="invoice_date" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formDueDate">
                <Form.Label>Data de vencimento</Form.Label>
                <Form.Control
                  as="div"
                  custom
                  isValid={touched.due_date && !errors.due_date}
                  isInvalid={!!errors.due_date}
                >
                  <Datepicker
                    locale="ptBR"
                    name="due_date"
                    placeholderText="Selecione a data de contribuição"
                    selected={values.due_date ? values.due_date : undefined}
                    onChange={date => setFieldValue('due_date', date)}
                    disabled={invoice.is_paid === true}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="due_date" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default InvoiceData;
