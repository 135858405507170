import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../../../hooks/useToast';
import api from '../../../../services/api';

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  selected: string;
  handleLoading: (value: boolean) => void;
  refreshFowards: () => Promise<void>;
}

const DeleteFowardModal: React.FC<IProps> = ({
  show,
  handleShow,
  selected,
  refreshFowards,
  handleLoading,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleRemoveFoward = useCallback(async () => {
    try {
      handleLoading(true);
      handleClose();
      const response = await api.delete(`/complaints/foward/${selected}`);
      if (response.status === 200) {
        addToast({
          title: 'Sucesso',
          body: 'Redirecionamento removido com sucesso.',
        });
        await refreshFowards();
      }
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu um erro ao tentar remover o redirecionamento.',
      });
    } finally {
      handleLoading(false);
    }
  }, [handleLoading, handleClose, refreshFowards, selected, addToast]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover redirecionamento de denúncias?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você está certo de que deseja remover o redirecionamento de denúncias?
        Essa ação não poderá ser desfeita e todos os dados serão perdidos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={() => handleRemoveFoward()}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFowardModal;
