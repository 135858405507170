import React, { useCallback, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Col, Form, Button, Row } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import Select from 'react-select';
import { setDefaultLocale } from 'react-datepicker';
import { useToast } from '../../../hooks/useToast';
import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import { Container } from './styles';

interface ICategories {
  id: string;
  title: string;
}

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface ICategories {
  id: string;
  title: string;
}

interface IPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  slug: string;
  status: string;
  type: string;
  author_id: string;
  featured_image: string;
  publishedAt: Date;
  categories: ICategories[];
  author: {
    first_name: string;
    last_name: string;
  };
}

interface ISelectProps {
  value: string | undefined;
  label: string;
}

interface IProps {
  handlePosts: (posts: IPost[]) => any;
  handleLoading: (value: boolean) => void;
  currentPage: number;
  pageHandler: (value: number) => number | void;
}

const FilterBar: React.FC<IProps> = ({
  handlePosts,
  handleLoading,
  currentPage,
  pageHandler,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [unions, setUnions] = useState<ISelectProps[]>();
  const [users, setUsers] = useState<ISelectProps[]>();
  // const [categories, setCategories] = useState<ISelectProps[]>();

  const postsStatus = [
    { value: 'draft', label: 'Rascunho' },
    { value: 'revision', label: 'Revisão pendente' },
    { value: 'published', label: 'Publicado' },
  ];

  const postsTypes = [
    { value: 'default', label: 'Padrão' },
    // { value: 'audio', label: 'Áudio' },
    // { value: 'video', label: 'Vídeo' },
  ];

  // const loadCategories = useCallback(async () => {
  //   const loadedCategories = await api.get<ICategories[]>('/news/categories');

  //   const categoriesArray = loadedCategories.data.map(loadedCategory => {
  //     return {
  //       value: loadedCategory.id,
  //       label: loadedCategory.title,
  //     };
  //   });

  //   setCategories(categoriesArray);
  // }, []);

  const loadUsers = useCallback(async () => {
    const loadedUsers = await api.get<IUser[]>('/users');

    const usersArray = loadedUsers.data.map(loadedUser => {
      return {
        value: loadedUser.id,
        label: `${loadedUser.first_name} ${loadedUser.last_name}`,
      };
    });

    setUsers(usersArray);
  }, []);

  const loadUnions = useCallback(async () => {
    try {
      const loadedUnions = await api.get<ISyndicate[]>('/unions');

      const unionsArray = loadedUnions.data.map(syndicate => {
        return {
          value: syndicate.id,
          label: syndicate.nome_fantasia,
        };
      });

      setUnions(unionsArray);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as notícias.',
      });
    }
  }, [addToast]);

  const filterPosts = useCallback(
    async values => {
      try {
        handleLoading(true);

        const filteredPosts = await api.get('/admin/posts', {
          params: { ...values, page: currentPage },
        });
        handlePosts(filteredPosts.data);

        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar as notícias.',
        });
      }
    },
    [handleLoading, handlePosts, addToast, currentPage],
  );

  useEffect(() => {
    if (user.role === 'admin') {
      loadUnions();
    }

    loadUsers();
  }, [loadUnions, loadUsers, user]);

  return (
    <Container>
      <Formik
        initialValues={{
          title: undefined,
          status: undefined,
          type: undefined,
          syndicate_id: undefined,
          author_id: undefined,
          categories: [],
        }}
        onSubmit={filterPosts}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="SearchByTitle">
                  <Form.Control
                    type="text"
                    placeholder="Digite o título da notícia"
                    onChange={handleChange('title')}
                    value={values.title}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="FilterPostsByStatus">
                  <Select
                    placeholder="Status"
                    options={postsStatus}
                    onChange={status => setFieldValue('status', status?.value)}
                    isClearable
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="FilterPostsByType">
                  <Select
                    placeholder="Tipo"
                    options={postsTypes}
                    onChange={type => setFieldValue('type', type?.value)}
                    isClearable
                  />
                </Form.Group>
              </Col>
              {user.role === 'admin' && (
                <Col>
                  <Form.Group controlId="FilterPostsBySyndicate">
                    <Select
                      placeholder="Sindicato"
                      options={unions}
                      onChange={syndicate => {
                        setFieldValue('syndicate_id', syndicate?.value);
                      }}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Group controlId="FilterPostsByUsers">
                  <Select
                    placeholder="Usuário"
                    options={users}
                    isClearable
                    onChange={selectedUser => {
                      setFieldValue('author_id', selectedUser?.value);
                    }}
                  />
                </Form.Group>
              </Col>
              {/* <Col className="col-2">
                <Form.Group controlId="FilterPostsByCategories">
                  <Select
                    placeholder="Categorias"
                    options={categories}
                    isClearable
                    isMulti
                    onChange={selectedCategory => {
                      setFieldValue(
                        'categories',
                        selectedCategory.map(categoryId => categoryId.value),
                      );
                      // console.log(selectedCategory);

                      // setFieldValue('categories', selectedCategory?.values);
                    }}
                  />
                </Form.Group>
              </Col> */}
              <Col className="col-1">
                <Button variant="primary" type="submit">
                  <FiSearch color="#ffffff" size={20} />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
