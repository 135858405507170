import styled from 'styled-components';

export const HeaderStatistcs = styled.div`
  border-radius: 8px;
  background: #f7f7f7;
  border: solid 2px #e0e0e0;
  width: 100%;
  margin-bottom: 40px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
  background: #fff; /* Fundo branco */
  border-radius: 25px;
  padding: 16px;
  border: 2px solid #e0e0e0; /* Borda cinza claro */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow para sombra */

  h4 {
    color: #006633;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold; 
  }

  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledHeaderStatistcs = styled.div`
  border-radius: 8px;
  background: #f7f7f7;
  width: 100%;
  margin-bottom: 40px;
`;

export const StyledChartContainer = styled.div`
  width: 100%;
  height: 400px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;

  .card-content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 20px;
      font-size: 24px;
      color: #28a745;
    }

    .text-container {
      text-align: center;

      p {
        margin: 0;
      }

      .large-number {
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
`;
