import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  selectedInvoice: string;
  handleRefreshInvoices: () => Promise<void>;
  handleLoading: (value: boolean) => void;
}

const DeleteInvoiceModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleRefreshInvoices,
  selectedInvoice,
  handleLoading,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleDeleteInvoice = useCallback(async () => {
    try {
      handleClose();
      handleLoading(true);

      await api.delete(`/financial/invoices/${selectedInvoice}`);

      addToast({
        title: 'Sucesso',
        body: 'Fatura removida com sucesso.',
      });

      await handleRefreshInvoices();
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu um erro ao tentar remover a fatura. Tente novamente.',
      });
    } finally {
      handleLoading(false);
    }
  }, [
    handleRefreshInvoices,
    selectedInvoice,
    handleLoading,
    handleClose,
    addToast,
  ]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover fatura</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você está certo de que deseja remover essa fatura? Todos os dados serão
        perdidos e as informações não poderão ser recuperadas.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="danger" onClick={() => handleDeleteInvoice()}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteInvoiceModal;
