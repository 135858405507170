import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/useToast';
import LoadingState from '../../components/LoadingState';

import api from '../../services/api';

import { MainContainer, SignInContainer } from './styles';

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef(null);

  const history = useHistory();
  const query = useQuery();

  const [loading, setLoading] = useState(false);

  const formValidation = Yup.object().shape({
    password: Yup.string().required(),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas não são idênticas.',
    ),
  });

  const handleResetPassword = useCallback(
    async ({ password }) => {
      try {
        setLoading(true);
        await api.post('/users-password/reset', {
          password,
          token: query.get('token'),
        });
        addToast({
          title: 'Sucesso',
          body: 'Sua senha foi alterada com sucesso. Você será redirecionado para a página de login.',
        });
        setLoading(false);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar redefinir a sua senha.',
        });
      }
    },
    [addToast, setLoading, history, query],
  );

  useEffect(() => {
    document.title = 'Redefinir senha - Viva Voz - Rede Suco de Laranja';
  }, []);

  return (
    <MainContainer>
      <Container>
        <Row className="justify-content-center">
          {loading && <LoadingState />}
          {loading === false && (
            <SignInContainer>
              <h2>Redefinir senha</h2>
              <p>Digite a sua nova senha e a confirme abaixo:</p>
              <Formik
                initialValues={{ password: '', password_confirmation: '' }}
                onSubmit={handleResetPassword}
                validationSchema={formValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Group controlId="PasswordInput">
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Digite aqui a sua senha"
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="PasswordConfirmationInput">
                      <Form.Label>Confirmação de senha</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Digite aqui a sua senha"
                        onChange={handleChange('password_confirmation')}
                        onBlur={handleBlur('password_confirmation')}
                        value={values.password_confirmation}
                        isValid={
                          touched.password_confirmation &&
                          !errors.password_confirmation
                        }
                        isInvalid={!!errors.password_confirmation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password_confirmation}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Redefinir
                    </Button>
                  </Form>
                )}
              </Formik>
            </SignInContainer>
          )}
        </Row>
      </Container>
    </MainContainer>
  );
};

export default SignIn;
