import React, { useState } from 'react';
import {
  FiHome,
  FiAlertTriangle,
  FiUsers,
  FiPaperclip,
  FiLink,
  FiBell,
  FiSearch,
  FiDollarSign,
} from 'react-icons/fi';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import covidIconWhite from '../../../../assets/icone-covid-white.png';
import covidIconGreen from '../../../../assets/icone-covid-green.png';

import { useAuth } from '../../../../hooks/auth';

import { Container, NavLink, CovidIcon } from './styles';

const Navigation: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [covidImg, setCovidImg] = useState(
    location.pathname.indexOf('/covid-complaints') >= 0
      ? covidIconWhite
      : covidIconGreen,
  );
  function setIsShown(e) {
    if (location.pathname.indexOf('/covid-complaints') < 0) {
      if (e === true) {
        setCovidImg(covidIconWhite);
      } else {
        setCovidImg(covidIconGreen);
      }
    }
  }

  return (
    <Container>
      <NavLink to="/dashboard">
        <FiHome size={24} />
        Painel
      </NavLink>
      <NavLink to="/workers">
        <FiUsers size={24} />
        Trabalhadores
      </NavLink>
      <NavLink to="/complaints">
        <FiAlertTriangle size={24} />
        Denúncias
      </NavLink>
      <NavLink
        to="/covid-complaints"
        onClick={() => {
          setCovidImg(covidIconWhite);
        }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <CovidIcon src={covidImg} />
        Denúncias Covid
      </NavLink>
      <NavLink to="/news">
        <FiPaperclip size={24} />
        Notícias
      </NavLink>
      <NavLink to="/partners">
        <FiLink size={24} />
        Convênios
      </NavLink>
      {user.role === 'admin' && (
        <NavLink to="/notifications">
          <FiBell size={24} />
          Notificações
        </NavLink>
      )}
      <NavLink to="/financial">
        <FiDollarSign size={24} />
        Financeiro
      </NavLink>
      {user.role === 'admin' && (
        <NavLink to="/research">
          <FiSearch size={24} />
          Pesquisa
        </NavLink>
      )}
    </Container>
  );
};

export default Navigation;
