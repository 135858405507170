import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Container, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/useToast';
import { PreviewImage } from '../styles';

interface ISyndicateInfo {
  id: string;
  nome_fantasia: string;
  razao_social: string;
  cnpj: string;
  email: string;
  type: string;
  phone: string;
  address: string;
  address2: string;
  city_id: number;
  state: string;
  postal_code: string;
  avatar: string;
  discount_percentage: number;
}

interface IState {
  uf: string;
  nome: string;
}

interface ICity {
  id: number;
  nome: string;
}

type IProps = {
  show: boolean;
  handleShow: (value: boolean) => void;
  syndicateId: string;
  handleUnions: (unions: any) => void;
  states: IState[];
};

const EditSyndicateModal: React.FC<IProps> = ({
  handleShow,
  show,
  syndicateId,
  handleUnions,
  states,
}) => {
  const { addToast } = useToast();

  const [mounted, setIsMounted] = useState(false);
  const [syndicateInfo, setSyndicateInfo] = useState<
    ISyndicateInfo | undefined
  >(undefined);

  const [cities, setCities] = useState<ICity[]>([]);

  // Image upload
  const [hasFeaturedImg, setHasFeaturedImg] = useState(false);
  const [featuredImgLabel, setFeaturedImgLabel] = useState('Selecionar imagem');
  const [featuredImgSrc, setFeaturedImgSrc] = useState('');

  const resetImageUpload = useCallback(() => {
    setHasFeaturedImg(false);
    setFeaturedImgLabel('Selecionar imagem');
    setFeaturedImgSrc('');
  }, []);

  const handleImageUpload = useCallback(event => {
    if (event.target.files[0]) {
      setHasFeaturedImg(true);

      const alt = event.target.files[0].name;
      const src = URL.createObjectURL(event.target.files[0]);

      setFeaturedImgLabel(alt);
      setFeaturedImgSrc(src);
    }
  }, []);

  const handleLoadCities = useCallback(async (uf: string) => {
    await api
      .get(`/ibge/cities/${uf}`)
      .then(response => setCities(response.data));
  }, []);

  const handleClose = useCallback(() => {
    handleShow(false);
    setIsMounted(false);
  }, [handleShow, setIsMounted]);

  const handleUpdateSyndicate = useCallback(
    async ({ avatar, discount_percentage: discountPercentage, ...rest }) => {
      try {
        const discount_percentage =
          (typeof discountPercentage === 'string'
            ? parseFloat(discountPercentage.replace('%', ''))
            : discountPercentage) / 100;

        const syndicateData = new FormData();

        if (typeof avatar !== 'string') {
          syndicateData.append('avatar', avatar);
        }

        syndicateData.append(
          'document',
          JSON.stringify({ ...rest, discount_percentage }),
        );

        await api.put(`/unions/${syndicateId}`, syndicateData);
        await api.get('/unions').then(response => handleUnions(response.data));
        handleClose();

        addToast({
          title: 'Sucesso',
          body: 'Sindicato atualizado com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar atualizar o sindicato.',
        });
      }
    },
    [syndicateId, handleUnions, addToast, handleClose],
  );

  const loadData = useCallback(async () => {
    const response = await api.get(`/unions/${syndicateId}`);
    setSyndicateInfo(response.data);

    handleLoadCities(response.data.state);

    if (response.data.avatar) {
      setFeaturedImgSrc(response.data.avatar);
      setHasFeaturedImg(true);
    }

    setIsMounted(true);
  }, [syndicateId, setIsMounted, handleLoadCities]);

  useEffect(() => {
    if (!mounted && !!syndicateId && !!show) {
      loadData();
    }
  }, [loadData, mounted, setSyndicateInfo, syndicateId, show]);

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      dialogClassName="modal-70w"
    >
      {syndicateInfo && (
        <Formik
          initialValues={{
            nome_fantasia: syndicateInfo.nome_fantasia,
            razao_social: syndicateInfo.razao_social,
            cnpj: syndicateInfo.cnpj,
            phone: syndicateInfo.phone,
            email: syndicateInfo.email,
            type: syndicateInfo.type,
            discount_percentage: syndicateInfo.discount_percentage * 100,
            address: syndicateInfo.address,
            address2: syndicateInfo.address2,
            city_id: syndicateInfo.city_id,
            state: syndicateInfo.state,
            postal_code: syndicateInfo.postal_code,
            avatar: syndicateInfo.avatar,
          }}
          // validationSchema={validationSchema}
          enableReinitialize
          onSubmit={values => handleUpdateSyndicate(values)}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            errors,
            setFieldValue,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar Sindicato</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={() => handleSubmit()}>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group controlId="first_name">
                          <Form.Label>Nome do Sindicato *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nome do sindicato"
                            value={values.nome_fantasia}
                            onChange={handleChange('nome_fantasia')}
                            onBlur={handleBlur('nome_fantasia')}
                            isValid={
                              touched.nome_fantasia && !errors.nome_fantasia
                            }
                            isInvalid={!!errors.nome_fantasia}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="razao_social">
                          <Form.Label>Razão Social</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Razão social do sindicato"
                            value={values.razao_social}
                            onChange={handleChange('razao_social')}
                            onBlur={handleBlur('razao_social')}
                            isValid={
                              touched.razao_social && !errors.razao_social
                            }
                            isInvalid={!!errors.razao_social}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="cnpj">
                          <Form.Label>CNPJ</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CNPJ do sindicato"
                            value={values.cnpj}
                            onChange={handleChange('cnpj')}
                            onBlur={handleBlur('cnpj')}
                            isValid={touched.cnpj && !errors.cnpj}
                            isInvalid={!!errors.cnpj}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Form.Group controlId="phone">
                          <Form.Label>Telefone</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Telefone do sindicato"
                            value={values.phone}
                            onChange={handleChange('phone')}
                            onBlur={handleBlur('phone')}
                            isValid={touched.phone && !errors.phone}
                            isInvalid={!!errors.phone}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="email">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="E-mail do sindicato"
                            value={values.email}
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Form.Group controlId="postal_code">
                          <Form.Label>CEP</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CEP do sindicato"
                            value={values.postal_code}
                            onChange={handleChange('postal_code')}
                            onBlur={handleBlur('postal_code')}
                            isValid={touched.postal_code && !errors.postal_code}
                            isInvalid={!!errors.postal_code}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="address">
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Endereço do sindicato"
                            value={values.address}
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="address2">
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Complemento do endereço"
                            value={values.address2}
                            onChange={handleChange('address2')}
                            onBlur={handleBlur('address2')}
                            isValid={touched.address2 && !errors.address2}
                            isInvalid={!!errors.address2}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="state">
                          <Form.Label>Estado</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.state}
                            onChange={item => {
                              handleChange('state')(item);
                              handleLoadCities(item.target.value);
                            }}
                            onBlur={handleBlur('state')}
                            isValid={touched.state && !errors.state}
                            isInvalid={!!errors.state}
                          >
                            <option value="0">Selecione um Estado</option>
                            {states.map(state => (
                              <option key={state.uf} value={state.uf}>
                                {state.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="city">
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Selecione uma cidade"
                            value={values.city_id}
                            onChange={handleChange('city_id')}
                            onBlur={handleBlur('city_id')}
                            isValid={touched.city_id && !errors.city_id}
                            isInvalid={!!errors.city_id}
                          >
                            <option value="0" disabled>
                              Selecione uma cidade
                            </option>
                            {cities.map(city => (
                              <option key={city.id} value={city.id}>
                                {city.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="type">
                          <Form.Label>Tipo *</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.type}
                            onChange={handleChange('type')}
                            onBlur={handleBlur('type')}
                            isValid={touched.type && !errors.type}
                            isInvalid={!!errors.type}
                          >
                            <option value="0">Selecione um tipo</option>
                            <option value="rural">Rural</option>
                            <option value="industrial">Industrial</option>
                            <option value="market">Comércio</option>
                            <option value="public-service">
                              Serviço Público
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="type">
                          <Form.Label>Porcentagem de desconto *</Form.Label>
                          <Form.Control
                            as="div"
                            custom
                            isValid={
                              touched.discount_percentage &&
                              !errors.discount_percentage
                            }
                            isInvalid={!!errors.discount_percentage}
                          >
                            <NumberFormat
                              suffix="%"
                              max={100}
                              maxLength={3}
                              placeholder="Digite a porgentagem"
                              value={values.discount_percentage}
                              className="form-control"
                              name="discount_percentage"
                              onChange={event => {
                                handleChange(event);
                              }}
                            />
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.discount_percentage}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {featuredImgSrc && (
                        <Col className="col-3">
                          <PreviewImage
                            src={featuredImgSrc}
                            alt={featuredImgLabel}
                          />
                        </Col>
                      )}
                      <Col>
                        <Form.Group>
                          <Form.Label>Logotipo do sindicato</Form.Label>
                          <Form.File
                            id="edit-avatar"
                            accept="image/png, image/jpeg, image/jpg"
                            label={featuredImgLabel}
                            data-browse="Selecionar"
                            custom
                            onChange={(event: any) => {
                              setFieldValue('avatar', event.target.files[0]);
                              handleImageUpload(event);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditSyndicateModal;
