import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  ProgressBar,
  Accordion,
  Card,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';
import FilterBar from '../../components/CovidComplaints/FilterBar';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

interface IComplaints {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  company: {
    id: string;
    nome_fantasia: string;
  };
  city: {
    id: string;
    name: string;
  };
  is_archived: boolean;
  created_at: Date;
  updated_at: Date;
}

interface PercentageData {
  got_covid?: number;
  got_covid_more?: number;
  how_many_got_covid?: { [interval: string]: number };
  where_got_covid?: { [value: string]: number };
  covid_risk?: number;
}

interface TranslationOptions {
  [key: number]: string;
}

const CovidComplaints: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingComplaints, setLoadingComplaints] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [complaints, setComplaints] = useState<IComplaints[]>();
  const [totalComplaints, setTotalComplaints] = useState(0);

  const receivedComplaints = complaints?.filter(
    complaint => complaint.status === 1,
  );

  const underAnalysisComplaints = complaints?.filter(
    complaint => complaint.status === 2,
  );

  useEffect(() => {
    document.title = 'Denúncias - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const response = await api.get<IComplaints[]>('/complaints', {
        params: { page: currentPage, iscovid: true },
      });
      setComplaints(response.data);
      setTotalComplaints(response.headers['x-total-complaints']);

      setLoading(false);
      setLoadingComplaints(false);
    };

    loadData();
  }, [loading, currentPage]);

  const translateKeys = (key: string): string => {
    const traducoes: { [chaveOriginal: string]: string } = {
      got_covid: 'Teve Covid?',
      got_covid_more: 'Teve mais de um episódio de Covid?',
      how_many_got_covid: 'Quantos tiveram Covid',
      where_got_covid: 'Onde tiveram Covid',
      covid_risk: 'Risco de Covid',
    };

    return traducoes[key] || key;
  };

  const translateRisk = (valor: number): string => {
    const traducoes: TranslationOptions = {
      1: 'Os ônibus da empresa estão sempre lotados e não há distanciamento.',
      2: 'Não há higienizações nas máquinas e equipamentos nos locais de trabalho',
      3: 'Alojamentos com pouco espaço e muitas pessoas',
      4: 'Não estão sendo feitas medidas de temperatura quando chegamos ao trabalho',
      5: 'Não há teste antes de saímos do nosso estado para trabalhar nas fazendas',
      6: 'Não há proteção acrílica entre máquinas para evitar contaminações',
      7: 'Trabalhadores do grupo de risco não são afastados (idosos, grávidas e pessoas com outras doenças)',
      8: 'Empresa exige retorno ao trabalho antes de acabar a quarentena, mesmo quando temos atestado',
      9: 'Trabalhadores doentes não são isolados adequadamente quando estão doentes nos alojamentos coletivos',
      10: 'Não há estrutura de atendimento emergencial para quem passa mal no trabalho',
      11: 'Há sobrecarga por falta de pessoal. Empresa não repõe trabalhadores quando alguém se afasta por Covid',
      12: 'Faltam equipamentos de proteção para nos proteger (luvas e máscaras)',
    };

    return traducoes[valor] || `Valor desconhecido: ${valor}`;
  };

  const translateWhere = (valor: number): string => {
    const traducoes: TranslationOptions = {
      1: 'Na empresa onde eu trabalho.',
      2: 'Através de alguém da família',
      3: 'No transporte público ou empresa',
      4: 'Não sei',
    };

    return traducoes[valor] || `Valor desconhecido: ${valor}`;
  };

  const calculatePercentages = dataArray => {
    const totalResponses = dataArray ? dataArray.length : 1;
    const percentages = {};

    const keysToCalculate = [
      'got_covid',
      'got_covid_more',
      'how_many_got_covid',
      'where_got_covid',
      'covid_risk',
    ];

    keysToCalculate.forEach(key => {
      // eslint-disable-next-line no-plusplus
      if (key === 'how_many_got_covid') {
        const intervals = ['1-10', '10-50', 'Mais de 50'];
        percentages[key] = {};

        intervals.forEach(interval => {
          const count = dataArray?.filter(item => item[key] === interval)
            .length;
          percentages[key][interval] = (count / totalResponses) * 100 || 0;
        });
      } else if (key === 'where_got_covid') {
        percentages[key] = {};
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 4; i++) {
          const count = dataArray?.filter(item => item[key] === i.toString())
            .length;
          percentages[key][i] = (count / totalResponses) * 100 || 0;
        }
      } else if (key === 'covid_risk') {
        percentages[key] = {};
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 12; i++) {
          const count = dataArray?.filter(item =>
            item[key]?.includes(i.toString()),
          ).length;
          percentages[key][i] = (count / totalResponses) * 100 || 0;
        }
      } else if (key === 'got_covid' || key === 'got_covid_more') {
        // Adicionando opções específicas para 'got_covid' e 'got_covid_more'
        const options = ['sim', 'não', 'talvez'];
        percentages[key] = {};

        options.forEach(option => {
          const count = dataArray?.filter(item => item[key] === option).length;
          percentages[key][option] = (count / totalResponses) * 100 || 0;
        });
      } else {
        const count = dataArray?.filter(item => item[key] === 'sim').length;
        percentages[key] = (count / totalResponses) * 100 || 0;
      }
    });

    return percentages;
  };

  const percentages = calculatePercentages(complaints);

  const [collapseStates, setCollapseStates] = useState({
    got_covid: true,
    got_covid_more: true,
    how_many_got_covid: true,
    where_got_covid: true,
    covid_risk: true,
  });

  const toggleCollapse = key => {
    setCollapseStates(prevStates => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  return (
    <BasePage title="Denúncias Covid">
      <Tabs
        defaultActiveKey="reports"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="reports" title="Denúncias">
          {loading && <LoadingState />}
          {loading === false && (
            <Container fluid>
              <FilterBar
                handleLoading={setLoadingComplaints}
                handleComplaints={setComplaints}
                currentPage={currentPage}
                pageHandler={setCurrentPage}
              />
              <Row>
                {loadingComplaints && <LoadingState />}
                {loadingComplaints === false && (
                  <Col>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th style={{ width: '15%' }}>Protocolo</th>
                          <th>Empresa</th>
                          <th>Cidade</th>
                          <th style={{ width: '13%' }}>Recebido em</th>
                          <th style={{ width: '13%' }}>Última atualização</th>
                          <th style={{ width: '8%' }}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {complaints && (
                          <>
                            <tr className="table-active">
                              <td colSpan={6}>Status: Recebido</td>
                            </tr>
                            {receivedComplaints?.map(complaint => (
                              <tr key={complaint.id}>
                                <>
                                  <td>{complaint.protocol}</td>
                                  <td>{complaint.company.nome_fantasia}</td>
                                  <td>
                                    {complaint.city
                                      ? complaint.city.name
                                      : 'Não especificado'}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(complaint.created_at),
                                      "dd/MM/yyyy ' às ' HH:mm",
                                    )}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(complaint.updated_at),
                                      "dd/MM/yyyy ' às ' HH:mm",
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() => {
                                        // eslint-disable-next-line prettier/prettier
                                        history.push(
                                          `/covid-complaints/${complaint.id}`,
                                        );
                                      }}
                                    >
                                      Visualizar
                                    </Button>
                                  </td>
                                </>
                              </tr>
                            ))}
                            <tr className="table-active">
                              <td colSpan={6}>Status: Em análise</td>
                            </tr>
                            {underAnalysisComplaints?.map(complaint => (
                              <tr key={complaint.id}>
                                <>
                                  <td>{complaint.protocol}</td>
                                  <td>{complaint.company.nome_fantasia}</td>
                                  <td>
                                    {complaint.city
                                      ? complaint.city.name
                                      : 'Não especificado'}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(complaint.created_at),
                                      "dd/MM/yyyy ' às ' HH:mm",
                                    )}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(complaint.created_at),
                                      "dd/MM/yyyy ' às ' HH:mm",
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() => {
                                        // eslint-disable-next-line prettier/prettier
                                        history.push(
                                          `/complaints/${complaint.id}`,
                                        );
                                      }}
                                    >
                                      Visualizar
                                    </Button>
                                  </td>
                                </>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </Table>
                    <Pagination
                      totalItems={totalComplaints}
                      currentPage={currentPage}
                      pageHandler={setCurrentPage}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          )}
        </Tab>
        <Tab eventKey="graph" title="Dados">
          <Accordion>
            {Object.keys(percentages).map(key => (
              /* eslint-disable no-nested-ternary */
              <Card key={key}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={key}
                    onClick={() => toggleCollapse(key)}
                  >
                    {translateKeys(key)}
                    {collapseStates[key] ? (
                      <FaArrowDown className="ml-4" />
                    ) : (
                      <FaArrowUp className="ml-4" />
                    )}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={key}>
                  <Card.Body>
                    {key === 'how_many_got_covid' && percentages[key] ? (
                      <div>
                        {Object.keys(percentages[key]).map(interval => (
                          <div className='border border-gray p-3 rounded mb-2' key={interval}>
                            <p>{interval}</p>
                            <ProgressBar
                              now={percentages[key][interval]}
                              label={`${percentages[key][interval]}%`}
                            />
                          </div>
                        ))}
                      </div>
                    ) : key === 'where_got_covid' && percentages[key] ? (
                      <div>
                        {Object.keys(percentages[key]).map(value => (
                          /* eslint-disable no-nested-ternary */
                          <div className='border border-gray p-3 rounded mb-2' key={value}>
                            <h6 className='mt-2'>{translateWhere(Number(value))}</h6>
                            <ProgressBar
                              now={percentages[key][value]}
                              label={`${percentages[key][value]}%`}
                            />
                          </div>
                        ))}
                      </div>
                    ) : key === 'covid_risk' && percentages[key] ? (
                      <div>
                        {Object.keys(percentages[key]).map(value => (
                          <div className='border border-gray p-3 rounded mb-2' key={value}>
                            <p>{translateRisk(Number(value))}</p>
                            <ProgressBar
                              now={percentages[key][value]}
                              label={`${percentages[key][value]}%`}
                            />
                          </div>
                        ))}
                      </div>
                    ) : typeof percentages[key] === 'object' ? (
                      <div>
                        {Object.entries(percentages[key]).map(
                          /* eslint-disable no-nested-ternary */
                          ([subKey, subValue]) => (
                            <div className='border border-gray p-3 rounded mb-2' key={subKey}>
                              <p>{subKey}</p>
                              <ProgressBar
                                now={Number(subValue)}
                                label={`${subValue}%`}
                              />
                            </div>
                          ),
                        )}
                      </div>
                    ) : (
                      <ProgressBar
                        now={percentages[key]}
                        label={`${percentages[key]}%`}
                      />
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Tab>
      </Tabs>
    </BasePage>
  );
};

export default CovidComplaints;
