import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts';

import api from '../../services/api';

import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';
import StasticCard from '../../components/Dashboard/StasticCard';

import { HeaderStatistcs, ChartContainer } from './styles';

interface IPostInfo {
  published: number;
  revision: number;
  draft: number;
}

interface IComplaintsInfoByStatus {
  name: string;
  value: number;
}

interface IComplaintsInfoByCompanies {
  name: string;
  complaints: number;
}

interface IComplaintsInfo {
  complaints: number;
  byStatus: IComplaintsInfoByStatus[];
  byCompanies: IComplaintsInfoByCompanies[];
}

interface IWorkersInfoArray {
  name: string;
  value: number;
}

interface IWorkersInfo {
  workers: number;
  gender: IWorkersInfoArray[];
  activity_profile: IWorkersInfoArray[];
  category: IWorkersInfoArray[];
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [postsInfo, setPostsInfo] = useState<IPostInfo>();
  const [complaintsInfo, setComplaintsInfo] = useState<IComplaintsInfo>(
    {} as IComplaintsInfo,
  );
  const [covidInfo, setCovidInfo] = useState<IComplaintsInfo>(
    {} as IComplaintsInfo,
  );
  const [workersInfo, setWorkersInfo] = useState<IWorkersInfo>();

  const barData = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const data01 = [
    {
      name: 'Group A',
      value: 400,
    },
    {
      name: 'Group B',
      value: 300,
    },
    {
      name: 'Group C',
      value: 300,
    },
    {
      name: 'Group D',
      value: 200,
    },
    {
      name: 'Group E',
      value: 278,
    },
    {
      name: 'Group F',
      value: 189,
    },
  ];

  const data02 = [
    { name: 'Group A', value: 2400 },
    { name: 'Group B', value: 4567 },
    { name: 'Group C', value: 1398 },
    { name: 'Group D', value: 9800 },
    { name: 'Group E', value: 3908 },
    { name: 'Group F', value: 4800 },
  ];

  const COLORS = ['#0fd170', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    document.title = 'Dashboard - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const postsData = await api.get<IPostInfo>('/dashboard/posts');
      const complaintsData = await api.get<IComplaintsInfo>(
        '/dashboard/complaints',
      );
      const covidData = await api.get<IComplaintsInfo>(
        '/dashboard/complaints-covid',
      );
      const workersData = await api.get<IWorkersInfo>('/dashboard/workers');

      setPostsInfo(postsData.data);
      setComplaintsInfo(complaintsData.data);
      setCovidInfo(covidData.data);
      setWorkersInfo(workersData.data);

      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading]);

  return (
    <BasePage title="Painel">
      {loading && <LoadingState />}
      {loading === false && (
        <Container>
          <Row>
            <Col>
              <HeaderStatistcs>
                <Row>
                  <Col className="col-6">
                    <StasticCard
                      data={workersInfo?.workers}
                      text="Trabalhadores cadastrados"
                    />
                  </Col>
                  <Col className="col-6">
                    <StasticCard
                      data={complaintsInfo?.complaints}
                      text="Denúncias recebidas"
                    />
                  </Col>
                  <Col className="col-6">
                    <StasticCard
                      data={covidInfo?.complaints}
                      text="Denúncias Covid recebidas"
                    />
                  </Col>
                  <Col className="col-6">
                    <StasticCard
                      data={postsInfo?.published}
                      text="Notícias publicadas"
                    />
                  </Col>
                </Row>
              </HeaderStatistcs>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <ChartContainer>
                <h4>Empresas com mais denúncias</h4>
                {complaintsInfo.byCompanies && (
                  <ResponsiveContainer
                    width="90%"
                    height="80%"
                    className="mt-3"
                  >
                    <BarChart
                      width={150}
                      height={40}
                      data={complaintsInfo.byCompanies}
                    >
                      <Bar
                        dataKey="complaints"
                        name="Denúncias"
                        fill={COLORS[0]}
                      />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip cursor={false} />
                      <Legend
                        className="mt-2"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
            <Col>
              <ChartContainer>
                <h4>Status das denúncias</h4>
                {complaintsInfo && (
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={complaintsInfo.byStatus}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {complaintsInfo.byStatus.map((entry, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <ChartContainer>
                <h4>Empresas com mais denúncias covid</h4>
                {covidInfo.byCompanies && (
                  <ResponsiveContainer
                    width="90%"
                    height="80%"
                    className="mt-3"
                  >
                    <BarChart
                      width={150}
                      height={40}
                      data={covidInfo.byCompanies}
                    >
                      <Bar
                        dataKey="covid"
                        name="Denúncias Covid"
                        fill={COLORS[0]}
                      />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip cursor={false} />
                      <Legend
                        className="mt-2"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
            <Col>
              <ChartContainer>
                <h4>Status das denúncias covid</h4>
                {covidInfo && (
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={covidInfo.byStatus}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {covidInfo.byStatus.map((entry, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <ChartContainer>
                <h4>Gênero dos trabalhadores</h4>
                {workersInfo && (
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={workersInfo.gender}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {workersInfo.gender.map((entry, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
            <Col>
              <ChartContainer>
                <h4>Perfil dos trabalhadores</h4>
                {workersInfo && (
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={workersInfo.activity_profile}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {workersInfo.gender.map((entry, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
            <Col className="">
              <ChartContainer>
                <h4>Categoria dos trabalhadores</h4>
                {workersInfo && (
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={workersInfo.category}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {workersInfo.gender.map((entry, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </ChartContainer>
            </Col>
          </Row>
        </Container>
      )}
    </BasePage>
  );
};

export default Dashboard;
