import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FiPlus, FiEdit, FiTrash2, FiPieChart } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';

import api from '../../services/api';

import AddResearchModal from './AddResearchModal';
import DeleteResearchModal from './DeleteResearchModal';

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
}

const Research: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [addShowModal, setAddShowModal] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);
  const [selectedResearch, setSelectedResearch] = useState<string>('');

  const [researches, setResearches] = useState<IResearch[]>();

  const openDeleteModal = useCallback((id: string) => {
    setSelectedResearch(id);
    setDeleteShowModal(true);
  }, []);

  useEffect(() => {
    document.title = 'Pesquisa - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const loadedResearches = await api.get<IResearch[]>('/research');
      setResearches(loadedResearches.data);
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading]);

  return (
    <BasePage title="Pesquisa">
      {loading && <LoadingState />}
      {!loading && researches && (
        <Container fluid>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="success"
                className="d-flex align-items-center justify-content-center"
                onClick={() => setAddShowModal(true)}
              >
                <FiPlus className="mr-1" size={20} color="#ffffff" />
                Nova pesquisa
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Título</th>
                    <th>Descrição</th>
                    <th style={{ width: '15%' }}>Data de início</th>
                    <th style={{ width: '15%' }}>Data final</th>
                    <th style={{ width: '8%' }}>Status</th>
                    <th style={{ width: '15%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {researches.map(research => (
                    <tr key={research.id}>
                      <td>{research.title}</td>
                      <td>{research.description}</td>
                      <td>
                        {format(
                          new Date(research.start_date),
                          "dd/MM/yyyy ' às ' HH:mm",
                        )}
                      </td>
                      <td>
                        {format(
                          new Date(research.finish_date),
                          "dd/MM/yyyy ' às ' HH:mm",
                        )}
                      </td>
                      <td>
                        {research.status === 'draft' && 'Rascunho'}
                        {research.status === 'published' && 'Publicado'}
                        {research.status === 'finished' && 'Finalizado'}
                      </td>
                      <td>
                        <Button
                          variant="success"
                          className="mr-1"
                          disabled={research.status === 'draft'}
                          onClick={() => {
                            history.push(`/research/results/${research.id}`);
                          }}
                        >
                          <FiPieChart color="#ffffff" size={20} />
                        </Button>
                        {research.status !== 'finished' && (
                          <Button
                            variant="primary"
                            className="mr-1"
                            onClick={() => {
                              history.push(`/research/${research.id}`);
                            }}
                          >
                            <FiEdit color="#ffffff" size={20} />
                          </Button>
                        )}
                        <Button
                          variant="danger"
                          onClick={() => openDeleteModal(research.id)}
                        >
                          <FiTrash2 color="#ffffff" size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      )}

      <AddResearchModal
        handleShow={setAddShowModal}
        show={addShowModal}
        handleResearches={setResearches}
        handleLoading={setLoading}
      />

      <DeleteResearchModal
        handleShow={setDeleteShowModal}
        show={deleteShowModal}
        handleLoading={setLoading}
        handleResearches={setResearches}
        selectedResearch={selectedResearch}
      />
    </BasePage>
  );
};

export default Research;
