/* eslint-disable */
import React, { useCallback } from 'react';
import {
  Form,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Table,
} from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { Formik, ErrorMessage } from 'formik';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import Datepicker from '../../../../components/Datepicker';
import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

registerLocale('ptBR', ptBR);

interface ITransaction {
  id: string;
  invoice_id: string;
  worker_id: string;
  transaction_date: Date;
  value: number | string;
  transaction_method: string;
}

interface IInvoice {
  id: string;
  order: number;
  worker_id: string;
  syndicate_id: string;
  value: number;
  is_paid: boolean;
  invoice_date: Date;
  due_date: Date;
  subscription_id: string;
  transactions: ITransaction[];
}

interface IProps {
  invoice: IInvoice;
  handleLoading: (value: boolean) => void;
  handleRefreshInvoices: () => Promise<void>;
  refreshInvoice: () => Promise<void>;
}

const PaymentData: React.FC<IProps> = ({
  invoice = {} as IInvoice,
  handleLoading,
  handleRefreshInvoices,
  refreshInvoice = () => undefined,
}) => {
  const { addToast } = useToast();

  const parseValue = useCallback((value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }, []);

  const parseMethod = useCallback((method: string) => {
    switch (method) {
      case 'manual':
        return 'Manual';
        break;
      case 'money':
        return 'Dinheiro';
        break;
      case 'credit-card':
        return 'Cartão de crédito';
        break;
      case 'boleto':
        return 'Boleto bancário';
        break;
      case 'pix':
        return 'PIX';
        break;
      default:
        return 'Manual';
    }
  }, []);

  const handleCreateTransaction = useCallback(
    async ({ transaction_value, ...rest }) => {
      try {
        handleLoading(true);

        const value = parseFloat(
          String(transaction_value).replace('R$ ', '').replace('.', ''),
        );

        await api.post<ITransaction>('/financial/transactions', {
          ...rest,
          value,
        });

        addToast({
          title: 'Sucesso',
          body: 'Transação efetuada com sucesso.',
        });

        await handleRefreshInvoices();
        await refreshInvoice();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar efetuar a transação.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [addToast, handleLoading, handleRefreshInvoices, refreshInvoice],
  );

  const handleDeleteTransaction = useCallback(
    async (id: string) => {
      try {
        handleLoading(true);

        await api.delete(`/financial/transactions/${id}`);

        addToast({
          title: 'Sucesso',
          body: 'Transação removida com sucesso.',
        });

        await handleRefreshInvoices();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar remover a transação.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [addToast, handleRefreshInvoices, handleLoading],
  );

  return (
    <Formik
      initialValues={{
        worker_id: invoice.worker_id,
        invoice_id: invoice.id,
        transaction_date: new Date(Date.now()),
        transaction_value: '',
        transaction_method: 'manual',
      }}
      onSubmit={handleCreateTransaction}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPaymentDate">
                <Form.Label>Data</Form.Label>
                <Form.Control as="div" custom>
                  <Datepicker
                    locale="ptBR"
                    name="transaction_date"
                    selected={values.transaction_date}
                    onChange={date => setFieldValue('invoice_date', date)}
                    placeholderText="Selecione a data da transação"
                    disabled={invoice.is_paid === true}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="transaction_date" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPaymentValue">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  as="div"
                  custom
                  isValid={
                    touched.transaction_value && !errors.transaction_value
                  }
                  isInvalid={!!errors.transaction_value}
                >
                  <NumberFormat
                    prefix={`R$ `}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowLeadingZeros
                    placeholder="Digite o valor do pagamento"
                    className="form-control"
                    value={values.transaction_value}
                    name="transaction_value"
                    onBlur={handleBlur}
                    onChange={handleChange('transaction_value')}
                    disabled={invoice.is_paid === true}
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="transaction_value" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formPaymentMethod">
                <Form.Label>Forma de pagamento</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  disabled
                  value={values.transaction_method}
                  onChange={handleChange}
                  name="transaction_method"
                  isValid={
                    touched.transaction_method && !errors.transaction_method
                  }
                  isInvalid={!!errors.transaction_method}
                >
                  <option>Selecione uma opção</option>
                  <option value="manual">Transação manual</option>
                  <option value="money">Dinheiro</option>
                  <option value="credit-card">Cartão de crédito</option>
                  <option value="boleto">Boleto bancário</option>
                  <option value="pix">PIX</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name="transaction_method" />
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                onClick={() => handleSubmit()}
                disabled={invoice.is_paid === true}
              >
                Adicionar pagamento
              </Button>
            </Col>
          </Row>
          <hr />
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Histórico de transações
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Valor</th>
                        <th>Forma de pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.transactions &&
                        invoice.transactions.map(transaction => (
                          <tr key={transaction.id}>
                            <td>
                              {format(
                                new Date(transaction.transaction_date),
                                'dd/MM/yyyy',
                              )}
                            </td>
                            <td>{parseValue(Number(transaction.value))}</td>
                            <td>
                              {parseMethod(transaction.transaction_method)}
                            </td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  return handleDeleteTransaction(
                                    transaction.id,
                                  );
                                }}
                              >
                                <FiTrash2 color="#ffffff" size={20} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentData;
