/* eslint-disable react/no-unused-prop-types */
import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

interface IAnswer {
  id: string;
  text: string;
}

interface IQuestion {
  id: string;
  text: string;
  type: string;
  answers: IAnswer[];
}

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
  questions: IQuestion[];
}

interface ISelectedQuestion {
  questionId: string;
  action: 'edit' | 'remove';
}

interface IProps {
  questionId: string;
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleResearch: (research: IResearch) => void;
  handleSelected: (value: ISelectedQuestion) => void;
}

const RemoveQuestionModal: React.FC<IProps> = ({
  show,
  handleShow,
  questionId,
  handleLoading,
  handleSelected,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
    handleSelected({} as ISelectedQuestion);
  }, [handleShow, handleSelected]);

  const handleDeleteQuestion = useCallback(async () => {
    try {
      handleLoading(true);

      await api.delete(`/research/question/${questionId}`);

      addToast({
        title: 'Sucesso',
        body: 'A questão foi removida com sucesso.',
      });
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao remover a questão. Tente novamente.',
      });
    } finally {
      handleClose();
      handleLoading(false);
    }
  }, [addToast, handleLoading, questionId, handleClose]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover questão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você está certo de que deseja remover esta questão? A ação não poderá
        ser desfeita e todos os dados serão perdidos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDeleteQuestion}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveQuestionModal;
