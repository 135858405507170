import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Formik } from 'formik';

import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/useToast';

interface IWorker {
  id: string;
  cpf: string;
  first_name: string;
  last_name: string;
  rg?: string;
  activity_profile?: 'rural' | 'industrial' | 'market' | 'public-service';
  is_peasant?: boolean;
  is_outsourced?: boolean;
  worker_function?: string;
  genre: 'male' | 'female' | 'other';
  phone: string;
  situation?: 'temporary' | 'permanent';
  email?: string;
  address?: string;
  city_id?: number;
  state?: string;
  postal_code?: string;
  is_temporary_address?: boolean;
  origin_address?: string;
  origin_city_id?: number;
  origin_state?: string;
  origin_postal_code?: string;
  salary_range?: number;
  is_unionized?: boolean;
  is_syndicate_approved: boolean | null;
  syndicate_approved_at: Date;
  syndicate_id?: string;
  city: {
    nome: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
  is_discount_agreed: boolean;
  is_lgpd_agreed: boolean;
  is_privacy_terms_agreed: boolean;
  created_at: Date;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface ISelectProps {
  [key: string]: any;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  worker: IWorker;
}

const ForwardWorkerModal: React.FC<IProps> = ({ show, handleShow, worker }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [unions, setUnions] = useState<ISelectProps[]>([]);

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleForwardWorker = useCallback(
    async values => {
      try {
        await api.post('/workers/forward', values);

        addToast({
          title: 'Sucesso',
          body: 'Trabalhador redirecionado com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar redirecionar o trabalhador.',
        });
      } finally {
        history.replace('/workers');
      }
    },
    [addToast, history],
  );

  const parseUnions = useCallback(async (loadedUnions: ISyndicate[]) => {
    const parsedUnions = await Promise.all(
      loadedUnions.map(syndicate => {
        return {
          label: syndicate.nome_fantasia,
          value: syndicate.id,
        };
      }),
    );

    setUnions(parsedUnions);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const loadedUnions = await api.get('/unions');
      parseUnions(loadedUnions.data);
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [parseUnions, loading]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{
          workerId: worker.id,
          syndicateId: '',
        }}
        onSubmit={handleForwardWorker}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Encaminhar Trabalhador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="sindicato">
                <Form.Label>Sindicato</Form.Label>
                <Select
                  options={unions}
                  isClearable
                  placeholder="Selecione o sindicato"
                  onChange={selectedSyndicate => {
                    setFieldValue('syndicateId', selectedSyndicate?.value);
                  }}
                />
                <Form.Text className="text-muted">
                  Indique o sindicato para encaminhar este trabalhador
                </Form.Text>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Encaminhar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ForwardWorkerModal;
