import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useToast } from '../../../../hooks/useToast';
import api from '../../../../services/api';

interface IProps {
  segment_id: string | string[];
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleSegments: (segments: []) => void;
}

const DeleteSegmentModal: React.FC<IProps> = ({
  segment_id,
  handleShow,
  handleLoading,
  handleSegments,
  show,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleDeleteSegment = useCallback(async () => {
    try {
      handleLoading(true);
      await api.delete(`/workers-segments/${segment_id}`);
      const updatedSegments = await api.get('/workers-segments');

      handleSegments(updatedSegments.data);

      addToast({
        title: 'Sucesso',
        body: 'O segmento foi removido com sucesso.',
      });
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao remover o segmento.',
      });
    } finally {
      handleClose();
      handleLoading(false);
    }
  }, [segment_id, handleLoading, handleSegments, handleClose, addToast]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover segmento?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Essa ação é irreversível e todos os dados serão perdidos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="danger" onClick={() => handleDeleteSegment()}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSegmentModal;
