import styled from 'styled-components';
import {
  Dropdown as ReactDropdown,
  Badge as ReactBadge,
} from 'react-bootstrap';

export const Container = styled.div`
  padding: 8px;
`;

export const Dropdown = styled(ReactDropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }
`;

export const DropdownMenu = styled(ReactDropdown.Menu)`
  padding: 0;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999;

  .dropdown-header {
    background: #dedede;
    color: #006633;
  }
`;

export const DropdownItem = styled(ReactDropdown.Item)`
  border-bottom: 1px solid #dedede;
  padding: 4px 16px;
  width: 380px;
`;

export const Badge = styled(ReactBadge)`
  color: #ffffff;
  font-size: 10px;
  background: #ff0000;
  border-radius: 50%;

  position: absolute !important;
  top: 4px !important;
  right: 8px !important;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

export const NotificationIcon = styled.div`
  background: #dedede;
  width: 48px;
  height: 48px;
  padding: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  .content {
    span {
      white-space: normal !important;
    }
  }

  .timestamp {
    padding: 8px 0;
    font-size: 12px;
    color: #ccc;
  }
`;
