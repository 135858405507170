import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

import { DatePicker } from './styles';

registerLocale('ptBR', ptBR);

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleResearches: (researches: IResearch[]) => void;
  handleLoading: (value: boolean) => void;
}

const AddResearchModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  handleResearches,
}) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [startDate, setStartDate] = useState<any>(null);
  const [finishDate, setFinishDate] = useState<any>(null);

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleCreateResearch = useCallback(
    async values => {
      try {
        handleLoading(true);

        await api.post<IResearch>('/research', values);

        addToast({
          title: 'Sucesso',
          body: 'A pesquisa foi criada com sucesso.',
        });

        const updatedResearches = await api.get('/research');
        handleResearches(updatedResearches.data);

        handleClose();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar criar uma nova pesquisa.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [addToast, handleLoading, handleResearches, handleClose],
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{
          title: '',
          description: '',
          start_date: '',
          finish_date: '',
        }}
        onSubmit={handleCreateResearch}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar pesquisa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Título *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o título da pesquisa"
                      value={values.title}
                      onChange={handleChange('title')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={handleChange('description')}
                      value={values.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Data de início</Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={date => {
                        setFieldValue('start_date', date);
                        setStartDate(date);
                      }}
                      locale="ptBR"
                      timeInputLabel="Início:"
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeInput
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Data de fim</Form.Label>
                    <DatePicker
                      selected={finishDate}
                      onChange={date => {
                        setFieldValue('finish_date', date);
                        setFinishDate(date);
                      }}
                      locale="ptBR"
                      timeInputLabel="Fim:"
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeInput
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddResearchModal;
