import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006633;
  width: 100vw;
  height: 100vh;
`;

export const SignInContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 10px;
`;

export const LoadingContainer = styled.div`
  background: white;
  border-radius: 10px;
  display: flex;
  max-height: 40vh;
  width: 30vw;
  align-items: center;
  justify-content: center;
`;
