import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

import { Container, DatePicker } from './styles';

const Datepicker: React.FC<ReactDatePickerProps> = props => {
  return (
    <Container>
      <DatePicker {...props} />
    </Container>
  );
};

export default Datepicker;
