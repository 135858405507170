import styled from 'styled-components';
import { Toast as ToastBootstrap } from 'react-bootstrap';

export const Container = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const Toast = styled(ToastBootstrap)`
  margin-bottom: 24px;
`;
