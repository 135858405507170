import styled from 'styled-components';

export const Container = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex: 1;

  form {
    flex: 1;
  }

  .form-group {
    margin: 0;
  }
`;
