import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import jwtDecode from 'jwt-decode';

import api from '../services/api';

interface IAuthToken {
  exp: number;
  iat: number;
}

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  syndicate_id?: string;
}
interface Union {
  id: string;
  name: string;
}

interface AuthState {
  user: User;
  syndicate?: Union;
  token: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  syndicate?: Union;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@VivaVoz:token');
    const user = localStorage.getItem('@VivaVoz:user');
    const syndicate = localStorage.getItem('@VivaVoz:syndicate');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      if (syndicate) {
        return {
          token,
          user: JSON.parse(user),
          syndicate: JSON.parse(syndicate),
        };
      }

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessions', { email, password });
    const { token, user, syndicate } = response.data;

    localStorage.setItem('@VivaVoz:token', token);
    localStorage.setItem('@VivaVoz:user', JSON.stringify(user));

    if (syndicate) {
      localStorage.setItem('@VivaVoz:syndicate', JSON.stringify(syndicate));
    }

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }

    setData({ token, user, syndicate });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@VivaVoz:token');
    localStorage.removeItem('@VivaVoz:user');
    localStorage.removeItem('@VivaVoz:syndicate');
    setData({} as AuthState);
  }, []);

  const checkIfTokenIsValid = useCallback(() => {
    const decoded = jwtDecode<IAuthToken>(data.token);
    const { exp } = decoded;

    const expDate = new Date(exp * 1000);

    if (expDate < new Date(Date.now())) {
      signOut();
    }
  }, [data, signOut]);

  useEffect(() => {
    if (data.token) {
      checkIfTokenIsValid();
    }
  }, [checkIfTokenIsValid, data]);

  return (
    <AuthContext.Provider
      value={{ user: data.user, syndicate: data.syndicate, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used with an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
