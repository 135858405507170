import React, { useCallback } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, FieldArray } from 'formik';
import Select from 'react-select';
import { FiMinusCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

interface IAnswer {
  id: string;
  text: string;
}

interface IQuestion {
  id: string;
  text: string;
  type: string;
  answers: IAnswer[];
}

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
  questions: IQuestion[];
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleResearch: (research: IResearch) => void;
  researchId: string;
}

const AddQuestionModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  handleResearch,
  researchId: selectedResearch,
}) => {
  const { id: researchId } = useParams<{ id: string }>();
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleAddQuestion = useCallback(
    async values => {
      try {
        handleLoading(true);
        await api.post('/research/question', values);
        const updatedResearch = await api.get(`/research/${selectedResearch}`);
        handleResearch(updatedResearch.data);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar a questão. Tente novamente.',
        });
      } finally {
        handleClose();
        handleLoading(false);
      }
    },
    [addToast, handleClose, handleLoading, handleResearch, selectedResearch],
  );

  const questionTypes = [
    { value: 'radio', label: 'Única seleção' },
    { value: 'checkbox', label: 'Múltipla seleção' },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{
          text: '',
          type: '',
          research_id: researchId,
          answers: [''],
        }}
        onSubmit={handleAddQuestion}
      >
        {({ handleSubmit, values, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar questão</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Texto da questão *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o texto da questão"
                      onChange={handleChange('text')}
                      value={values.text}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Tipo de resposta *</Form.Label>
                    <Select
                      options={questionTypes}
                      placeholder="Selecione um tipo de resposta"
                      onChange={event => setFieldValue('type', event?.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <FieldArray name="answers">
                {({ push, remove }) => (
                  <>
                    <Row className="mb-3">
                      <Col>
                        <Button
                          variant="outline-primary"
                          onClick={() => push('')}
                        >
                          Adicionar resposta
                        </Button>
                      </Col>
                    </Row>
                    {values.answers.map((answer, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row key={index}>
                        <Col className="d-flex flex-row align-items-center">
                          <Form.Group
                            controlId={`formBasicText-${index}`}
                            style={{ flex: 1 }}
                          >
                            <Field
                              name={`answers.${index}`}
                              type="text"
                              placeholder="Digite uma resposta para a questão"
                              className="form-control"
                              autoComplete={false}
                            />
                          </Form.Group>
                          <Button
                            variant="danger"
                            className="ml-1"
                            style={{ marginBottom: 16 }}
                            onClick={() => remove(index)}
                          >
                            <FiMinusCircle color="#ffffff" size={18} />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </FieldArray>
              <hr />
              <Row>
                <Col>
                  <Form.Text className="text-muted">
                    Campos com * são obrigatórios.
                  </Form.Text>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddQuestionModal;
