import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/useToast';

import { PreviewImage } from './styles';

interface IPartnerCategory {
  id: string;
  title: string;
}

interface IState {
  uf: string;
  nome: string;
}

interface ICity {
  id: number;
  nome: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IProps {
  showModal: boolean;
  handleShowModal: (value: boolean) => void;
  handlePartners: (partners: []) => void;
  handleLoading: (value: boolean) => void;
}

const AddPartnerModal: React.FC<IProps> = ({
  showModal,
  handleShowModal,
  handlePartners,
  handleLoading,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  // Data states
  const [partnersCategories, setPartnersCategories] = useState<
    IPartnerCategory[]
  >([]);
  const [states, setStates] = useState<IState[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [cities, setCities] = useState<ICity[]>([]);
  const [unions, setUnions] = useState<ISyndicate[]>([]);

  // Image Previews
  const [hasAvatar, setHasAvatar] = useState(false);
  const [avatarImgLabel, setAvatarImgLabel] = useState('Selecionar a imagem');
  const [avatarImgSrc, setAvatarImgSrc] = useState('');

  const handleClose = useCallback(() => {
    handleShowModal(false);

    // Reset Modal
    setSelectedState('');
    setCities([]);
    setUnions([]);

    // Reset avatar field
    setHasAvatar(false);
    setAvatarImgLabel('Selecionar a imagem');
    setAvatarImgSrc('');
  }, [handleShowModal]);

  const handleCreateParnter = useCallback(
    async values => {
      const { avatar, ...rest } = values;

      const partnerData = new FormData();
      partnerData.append('avatar', avatar);
      partnerData.append('document', JSON.stringify(rest));

      try {
        handleLoading(true);

        await api.post('/unions-partners', partnerData);
        const updatedList = await api.get('/unions-partners');
        handlePartners(updatedList.data);

        handleClose();

        addToast({
          title: 'Sucesso',
          body: 'Novo convênio adicionado com sucesso.',
        });

        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar um novo convênio.',
        });
      }
    },
    [addToast, handleLoading, handleClose, handlePartners],
  );

  const handleAvatarUpload = useCallback(event => {
    if (event.target.files[0]) {
      setHasAvatar(true);

      const alt = event.target.files[0].name;
      const src = URL.createObjectURL(event.target.files[0]);

      setAvatarImgLabel(alt);
      setAvatarImgSrc(src);
    }
  }, []);

  useEffect(() => {
    const loadCities = async () => {
      const loadedCities = await api.get(`/ibge/cities/${selectedState}`);
      setCities(loadedCities.data);
    };

    loadCities();
  }, [selectedState]);

  useEffect(() => {
    const loadData = async () => {
      const loadedPartnersCategories = await api.get(
        '/unions-partners/categories',
      );

      const loadedStates = await api.get('/ibge/states');

      if (user.role === 'admin') {
        const loadUnions = await api.get('/unions');
        setUnions(loadUnions.data);
      }

      setPartnersCategories(loadedPartnersCategories.data);
      setStates(loadedStates.data);
    };

    loadData();
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Este campo é obrigatório'),
    address: Yup.string().required('Este campo é obrigatório'),
    address2: Yup.string().required('Este campo é obrigatório'),
    postal_code: Yup.string().required('Este campo é obrigatório'),
    city_id: Yup.number().required('Este campo é obrigatório'),
    state: Yup.string().required('Este campo é obrigatório'),
    whatsapp: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    email: Yup.string().nullable(),
    description: Yup.string().nullable(),
    category_id: Yup.string().required('Este campo é obrigatório'),
    syndicate_id: Yup.string().required('Este campo é obrigatório'),
  });

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Formik
        initialValues={{
          name: '',
          address: '',
          address2: '',
          postal_code: '',
          city_id: '',
          state: '',
          whatsapp: '',
          phone: '',
          email: '',
          avatar: '',
          description: '',
          category_id: '',
          syndicate_id: user.role === 'admin' ? '' : user.syndicate_id,
        }}
        onSubmit={handleCreateParnter}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar convênio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Nome *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome do convênio"
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-4">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Selecione a Categoria *</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange('category_id')}
                      onBlur={handleBlur('category_id')}
                      value={values.category_id}
                      isValid={touched.category_id && !errors.category_id}
                      isInvalid={!!errors.category_id}
                    >
                      <option selected>Selecione</option>
                      {partnersCategories.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Endereço *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite a rua e o número"
                      value={values.address}
                      onChange={handleChange('address')}
                      onBlur={handleBlur('address')}
                      isValid={touched.address && !errors.address}
                      isInvalid={!!errors.address}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Bairro e Complemento *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o bairro e complemento"
                      value={values.address2}
                      onChange={handleChange('address2')}
                      onBlur={handleBlur('address2')}
                      isValid={touched.address2 && !errors.address2}
                      isInvalid={!!errors.address2}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Selecione o Estado</Form.Label>
                    <Form.Control
                      as="select"
                      value={values.state}
                      onChange={item => {
                        handleChange('state')(item);
                        setSelectedState(item.target.value);
                      }}
                      onBlur={handleBlur('state')}
                      isValid={touched.state && !errors.state}
                      isInvalid={!!errors.state}
                    >
                      <option selected>Selecione</option>
                      {states.map(state => (
                        <option value={state.uf} key={state.uf}>
                          {state.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Selecione a Cidade</Form.Label>
                    <Form.Control
                      as="select"
                      value={values.city_id}
                      onChange={handleChange('city_id')}
                      onBlur={handleBlur('city_id')}
                      isValid={touched.city_id && !errors.city_id}
                      isInvalid={!!errors.city_id}
                    >
                      <option selected>Selecione</option>
                      {cities.map(city => (
                        <option value={city.id} key={city.id}>
                          {city.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>CEP *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o CEP"
                      value={values.postal_code}
                      onChange={handleChange('postal_code')}
                      onBlur={handleBlur('postal_code')}
                      isValid={touched.postal_code && !errors.postal_code}
                      isInvalid={!!errors.postal_code}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o telefone do convênio"
                      value={values.phone}
                      onChange={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={!!errors.phone}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>WhatsApp</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o whatsapp de atendimento"
                      onChange={handleChange('whatsapp')}
                      onBlur={handleBlur('whatsapp')}
                      value={values.whatsapp}
                      isValid={touched.whatsapp && !errors.whatsapp}
                      isInvalid={!!errors.whatsapp}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o e-mail de contato"
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Descrição do convênio</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={values.description}
                      onChange={handleChange('description')}
                      onBlur={handleBlur('description')}
                      isValid={touched.description && !errors.description}
                      isInvalid={!!errors.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                {hasAvatar && (
                  <Col className="col-3">
                    <PreviewImage
                      src={avatarImgSrc || values.avatar}
                      alt={avatarImgLabel}
                    />
                  </Col>
                )}
                <Col>
                  <Form.Group controlId="exampleForm.ControlFilearea1">
                    <Form.Label>Selecione o logotipo do convênio</Form.Label>
                    <Form.File
                      id="avatar"
                      accept="image/png, image/jpeg, image/jpg"
                      label={avatarImgLabel}
                      custom
                      data-browse="Selecionar"
                      isValid={touched.avatar && !errors.avatar}
                      isInvalid={!!errors.avatar}
                      onChange={(event: any) => {
                        handleAvatarUpload(event);
                        setFieldValue('avatar', event.target.files[0]);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              {user.role === 'admin' && (
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Selecione o Sindicato</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.syndicate_id}
                        onChange={handleChange('syndicate_id')}
                        onBlur={handleBlur('syndicate_id')}
                        isValid={touched.syndicate_id && !errors.syndicate_id}
                        isInvalid={!!errors.syndicate_id}
                      >
                        <option selected>Selecione</option>
                        {unions.map(syndicate => (
                          <option value={syndicate.id} key={syndicate.id}>
                            {syndicate.nome_fantasia}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPartnerModal;
