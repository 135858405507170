import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';

import api from '../../../services/api';
import { useToast } from '../../../hooks/useToast';

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  subscriptionId: string;
  refreshSubscriptions: () => void;
}

const DeleteSubscriptionModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  subscriptionId,
  refreshSubscriptions,
}) => {
  const { addToast } = useToast();
  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleDeleteSubscription = useCallback(async () => {
    try {
      handleClose();
      handleLoading(true);
      await api.delete(`/financial/subscriptions/${subscriptionId}`);

      addToast({
        title: 'Sucesso',
        body: 'Recorrência removida com sucesso.',
      });

      await refreshSubscriptions();
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu um erro ao tentar remover a recorrência. Tente novamente.',
      });
    } finally {
      handleLoading(false);
    }
  }, [
    subscriptionId,
    addToast,
    handleClose,
    handleLoading,
    refreshSubscriptions,
  ]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover recorrência</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você está certo de que deseja remover essa recorrência? Essa ação não
        poderá ser desfeita e todos os dados serão perdidos, inclusive, de
        cobranças passadas. Considere torná-la inativa.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={() => handleDeleteSubscription()}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSubscriptionModal;
