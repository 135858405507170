import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts';
import LoadingState from '../../../components/LoadingState';
import BasePage from '../../../components/BasePage';

import api from '../../../services/api';

import { Header, ChartContainer } from './styles';

interface IAnswer {
  text: string;
  value: number;
}

interface IQuestion {
  text: string;
  answers: IAnswer[];
}

interface IResearch {
  title: string;
  description: string;
  questions: IQuestion[];
}

const ShowResearch: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(true);
  const [research, setResearch] = useState<IResearch>({} as IResearch);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get(`/research/${id}`, {
        params: { results: true },
      });

      setResearch(response.data);
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading, id]);

  return (
    <BasePage title="Pesquisa">
      {loading && <LoadingState />}
      {!loading && research && (
        <Container>
          <Row>
            <Col>
              <Header>
                <Row>
                  <Col>
                    <h1>{research.title}</h1>
                    <h4>
                      {research.description || 'Nenhuma descrição informada'}
                    </h4>
                  </Col>
                </Row>
              </Header>
            </Col>
          </Row>
          <Row>
            {research.questions.map((question, index) => (
              <Col className="col-6 mb-3">
                <ChartContainer>
                  <h4>{`${index + 1}. ${question.text}`}</h4>
                  <ResponsiveContainer width="100%" height="80%">
                    <PieChart width={400} height={250}>
                      <Pie
                        data={question.answers}
                        dataKey="value"
                        nameKey="text"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      >
                        {question.answers.map((entry, answerIndex) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${answerIndex}`}
                            fill={COLORS[answerIndex % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip cursor={false} />
                      <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </BasePage>
  );
};

export default ShowResearch;
