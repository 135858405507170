import styled from 'styled-components';
import DateComponent from 'react-datepicker';

export const Title = styled.input`
  padding: 8px 16px;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #a8a8a8;
  width: 100%;
  font-size: 22px;
`;

export const Aside = styled.aside`
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;

  margin-bottom: 16px;

  h3 {
    font-size: 18px;
    border-bottom: 1px solid #a8a8a8;
    padding-bottom: 8px;
  }
`;

export const DatePicker = styled(DateComponent)`
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .status-info {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .status-select {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }
`;

export const TimestampContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .timestamp-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .form-group {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .timestamp-select {
    margin-top: 8px;
  }
`;

export const TrashLink = styled.button`
  background: transparent;
  margin: 0;
  padding: 0;
  color: red;
  text-decoration: underline;
`;

export const CategoryBox = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  max-height: 200px;

  .category-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

export const PreviewFeaturedImage = styled.img`
  margin: 8px 0;
  padding: 8px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 8px;
`;
