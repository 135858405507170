import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Link = styled(ReactLink)`
  background: #f7f7f7;
  min-height: 124px;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #006633;

  &:hover {
    background: #f38725;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;
