import styled from 'styled-components';
import { Button as ReactButton } from 'react-bootstrap';

export const ProfileContainer = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 16px;

  display: flex;
  flex-direction: column;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 32px 0;

  background: white;
  border-radius: 10px;

  h2 {
    text-align: center;
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled(ReactButton)`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 8px;
`;
