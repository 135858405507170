import React, { useCallback } from 'react';

import { Modal, Button } from 'react-bootstrap';

import api from '../../../services/api';
import { useToast } from '../../../hooks/useToast';

interface IProps {
  partnerId: string;
  showModal: boolean;
  handleShowModal: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handlePartners: (partner: []) => void;
}

const DeletePartnerModal: React.FC<IProps> = ({
  partnerId,
  handleShowModal,
  showModal,
  handleLoading,
  handlePartners,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShowModal(false);
  }, [handleShowModal]);

  const handleDeletePartner = useCallback(async () => {
    try {
      handleLoading(true);
      await api.delete(`/unions-partners/${partnerId}`);
      const updatedPartners = await api.get('/unions-partners');
      handlePartners(updatedPartners.data);
      handleClose();
      handleLoading(false);
    } catch (error) {
      addToast({
        title: 'Erro ao tentar remover',
        body: 'Ocorreu um erro ao tentar remover o convênio. Tente novamente.',
      });
    }
  }, [addToast, handleLoading, partnerId, handleClose, handlePartners]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover convênio?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você está certo de que deseja remover este convênio? Essa ação não
        poderá ser desfeita e todos os dados serão perdidos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDeletePartner}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePartnerModal;
