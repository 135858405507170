/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Pagination as ReactPagination } from 'react-bootstrap';

import { Container } from './styles';

interface IProps {
  totalItems: number;
  itemsLimit?: number;
  currentPage: number;
  pageHandler: (value: number) => number | void;
}

const Pagination: React.FC<IProps> = ({
  totalItems,
  itemsLimit,
  pageHandler,
  currentPage,
}) => {
  const [pages, setPages] = useState<number[]>([1]);
  const [limit, setLimit] = useState(25 || itemsLimit);

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / limit);

    const arrayPages: number | number[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i);
    }

    setPages(arrayPages);
  }, [limit, totalItems]);

  return (
    <Container>
      <div>
        <span>Total: </span>

        {totalItems}
      </div>
      <ReactPagination>
        <ReactPagination.First
          onClick={() => pageHandler(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {pages.map(page => (
          <ReactPagination.Item
            key={page}
            onClick={() => pageHandler(page)}
            active={page === currentPage}
          >
            {page}
          </ReactPagination.Item>
        ))}
        <ReactPagination.Last
          onClick={() => pageHandler(currentPage + 1)}
          disabled={currentPage === pages.length}
        />
      </ReactPagination>
    </Container>
  );
};

export default Pagination;
