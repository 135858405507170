import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { FiSearch, FiArchive } from 'react-icons/fi';
import Select from 'react-select';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/useToast';

import { Container, FilterLine } from './styles';
import {
  gotCovidArray,
  covidRisksArray,
  whereGotCovidArray,
  HowManyGotCovidArray,
} from '../../../pages/CovidComplaints/covidValues';

interface ISelectProps {
  value: string | number | undefined;
  label: string;
}

interface ICompany {
  id: string;
  nome_fantasia: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IComplaints {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  company: {
    id: string;
    nome_fantasia: string;
  };
  city: {
    id: string;
    name: string;
  };
  is_archived: boolean;
  created_at: Date;
  updated_at: Date;
}

interface IState {
  id: number;
  uf: string;
}

interface IProps {
  handleLoading: (value: boolean) => void;
  handleComplaints: (complaints: IComplaints[]) => void;
  currentPage: number;
  pageHandler: (value: number) => number | void;
}

const FilterBar: React.FC<IProps> = ({
  handleLoading,
  handleComplaints,
  currentPage,
  pageHandler,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [unions, setUnions] = useState<ISelectProps[]>();
  const [companies, setCompanies] = useState<ISelectProps[]>();
  const [states, setStates] = useState<ISelectProps[]>([]);
  const showArchivedComplaints = useCallback(async () => {
    try {
      handleLoading(true);
      const filteredComplaints = await api.get<IComplaints[]>('/complaints', {
        params: { is_archived: true, page: currentPage, iscovid: true },
      });
      handleComplaints(filteredComplaints.data);
      pageHandler(1);
      handleLoading(false);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
      });
    }
  }, [handleLoading, handleComplaints, addToast, currentPage, pageHandler]);

  const showAllComplaints = useCallback(async () => {
    try {
      handleLoading(true);
      const filteredComplaints = await api.get<IComplaints[]>('/complaints', {
        params: { page: currentPage },
      });
      handleComplaints(filteredComplaints.data);
      pageHandler(1);
      handleLoading(false);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
      });
    }
  }, [addToast, handleComplaints, handleLoading, currentPage, pageHandler]);

  const filterByMe = useCallback(async () => {
    try {
      handleLoading(true);
      const filteredComplaints = await api.get<IComplaints[]>('/complaints', {
        params: { assignedUsers: user.id, iscovid: true },
      });
      handleComplaints(filteredComplaints.data);
      handleLoading(false);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
      });
    }
  }, [addToast, user, handleComplaints, handleLoading]);

  const filterComplaints = useCallback(
    async values => {
      try {
        handleLoading(true);
        const filteredComplaints = await api.get<IComplaints[]>('/complaints', {
          params: { ...values, page: currentPage, iscovid: true },
        });

        handleComplaints(filteredComplaints.data);
        pageHandler(1);
        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
        });
      }
    },
    [addToast, handleLoading, handleComplaints, currentPage, pageHandler],
  );

  const priorityOptions = [
    { value: '0', label: 'Não urgente' },
    { value: '1', label: 'Pouco urgente' },
    { value: '2', label: 'Normal' },
    { value: '3', label: 'Urgente' },
    { value: '4', label: 'Muito urgente' },
  ];

  const statusOptions = [
    { value: '1', label: 'Recebido' },
    { value: '2', label: 'Em análise' },
    { value: '3', label: 'Finalizado' },
  ];

  const loadCompanies = useCallback(async () => {
    try {
      const loadedCompanies = await api.get<ICompany[]>('/companies');

      const parsedCompanies = loadedCompanies.data.map(company => {
        return {
          value: company.id,
          label: company.nome_fantasia,
        };
      });

      setCompanies(parsedCompanies);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
      });
    }
  }, [addToast]);

  const loadUnions = useCallback(async () => {
    try {
      const loadedUnions = await api.get<ISyndicate[]>('/unions');

      const parsedUnions = loadedUnions.data.map(syndicate => {
        return {
          value: syndicate.id,
          label: syndicate.nome_fantasia,
        };
      });

      setUnions(parsedUnions);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar filtrar as denúncias.',
      });
    }
  }, [addToast]);

  const loadStates = useCallback(async () => {
    try {
      const loadedStates = await api.get<IState[]>('/ibge/states');

      const parsedStates = await Promise.all(
        loadedStates.data.map(state => {
          return {
            label: state.uf,
            value: state.uf,
          };
        }),
      );

      setStates(parsedStates);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar carregar os Estados.',
      });
    }
  }, [addToast]);

  useEffect(() => {
    if (user.role === 'admin') {
      loadUnions();
      loadStates();
    }

    loadCompanies();
  }, [user, loadUnions, loadCompanies, loadStates]);

  return (
    <Row className="mb-3">
      <Col className="col-3 d-flex align-items-center">
        <Button onClick={showAllComplaints}>Todas</Button>
        <Button onClick={filterByMe} className="ml-1">
          Meus protocolos
        </Button>
        <Button
          onClick={showArchivedComplaints}
          className="ml-1 d-flex align-items-center"
        >
          <FiArchive color="#ffffff" size={20} className="mr-1" />
          Arquivados
        </Button>
      </Col>
      <Col>
        <Container>
          <Formik
            initialValues={{
              protocol: '',
              syndicate_id: '',
              company_id: '',
              priority: '',
              status: '',
              state: '',
              got_covid: '',
              where_got_covid: '',
              covid_risk: '',
              how_many_got_covid: '',
              got_covid_more: '',
            }}
            onSubmit={filterComplaints}
          >
            {({ handleSubmit, setFieldValue, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <FilterLine>
                  <Row className="margin-y">
                    <Col>
                      <Select
                        options={gotCovidArray}
                        isClearable
                        placeholder="Teve Covid?"
                        onChange={selectedGotCovid => {
                          setFieldValue('got_covid', selectedGotCovid?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={gotCovidArray}
                        isClearable
                        placeholder="Teve Covid mais vezes?"
                        onChange={selectedGotCovidMore => {
                          setFieldValue(
                            'got_covid_more',
                            selectedGotCovidMore?.value,
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={HowManyGotCovidArray}
                        isClearable
                        placeholder="Colegas que tiveram covid."
                        onChange={selectManyGotCovid => {
                          setFieldValue(
                            'how_many_got_covid',
                            selectManyGotCovid?.value,
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={whereGotCovidArray}
                        isClearable
                        placeholder="Onde pegou Covid."
                        onChange={selectedWhereGotCovid => {
                          setFieldValue(
                            'where_got_covid',
                            selectedWhereGotCovid?.value?.toString(),
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={covidRisksArray}
                        isClearable
                        placeholder="Riscos de covid onde trabalha."
                        onChange={selectCovidRisk => {
                          setFieldValue(
                            'covid_risk',
                            selectCovidRisk?.value?.toString(),
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="margin-y">
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Digite o protocolo"
                          value={values.protocol}
                          onChange={handleChange('protocol')}
                        />
                      </Form.Group>
                    </Col>
                    {user.role === 'admin' && (
                      <Col>
                        <Select
                          options={unions}
                          isClearable
                          placeholder="Sindicato"
                          onChange={selectedSyndicate => {
                            setFieldValue(
                              'syndicate_id',
                              selectedSyndicate?.value,
                            );
                          }}
                        />
                      </Col>
                    )}
                    <Col>
                      <Select
                        options={companies}
                        isClearable
                        placeholder="Empresa"
                        onChange={selectedCompany => {
                          setFieldValue('company_id', selectedCompany?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={priorityOptions}
                        isClearable
                        placeholder="Prioridade"
                        onChange={selectedPriority => {
                          setFieldValue('priority', selectedPriority?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={statusOptions}
                        isClearable
                        placeholder="Status"
                        onChange={selectedStatus => {
                          setFieldValue('status', selectedStatus?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={statusOptions}
                        isClearable
                        placeholder="Status"
                        onChange={selectedStatus => {
                          setFieldValue('status', selectedStatus?.value);
                        }}
                      />
                    </Col>
                    {user.role === 'admin' && (
                      <Col>
                        <Select
                          options={states}
                          isClearable
                          placeholder="Estado"
                          onChange={state => {
                            setFieldValue('state', state?.value);
                          }}
                        />
                      </Col>
                    )}
                    <Col className="col-1 d-flex justify-content-center">
                      <Button variant="primary" type="submit">
                        <FiSearch size={20} color="#ffffff" />
                      </Button>
                    </Col>
                  </Row>
                </FilterLine>
              </Form>
            )}
          </Formik>
        </Container>
      </Col>
    </Row>
  );
};

export default FilterBar;
