import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Container, Data, Text } from './styles';

interface IProps {
  data: number | string | undefined;
  text: string;
}

const StasticCard: React.FC<IProps> = ({ data, text }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Data>{data === undefined ? '-' : data}</Data>
          <Text>{text}</Text>
        </Col>
      </Row>
    </Container>
  );
};

export default StasticCard;
