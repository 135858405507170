import styled from 'styled-components';
import { NavLink as ReactNavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 24px;
`;

export const NavLink = styled(ReactNavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  margin: 0 10px 16px 10px;
  text-align: center;
  color: #006633;
  font-size: 0.8rem;
  line-height: 120%;

  padding: 16px;

  &:hover,
  &.active {
    background: #f38725;
    color: white;

    svg {
      color: white;
    }
  }

  svg {
    margin-bottom: 8px;
  }
`;

export const CovidIcon = styled.img`
  height: 24px;
  max-width: 100%;
  margin-bottom: 5px;
`;
