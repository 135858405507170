import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { format } from 'date-fns';
import { FiCheckCircle, FiXCircle, FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';
import BasePage from '../../../components/BasePage';
import LoadingState from '../../../components/LoadingState';
import DeleteWorkerModal from './DeleteWorkerModal';
import EditWorkerModal from './EditWorkerModal';
import ForwardWorkerModal from './ForwardWorkerModal';
import api from '../../../services/api';

import { DataInfoContainer } from './styles';

interface IWorker {
  id: string;
  cpf: string;
  first_name: string;
  last_name: string;
  rg?: string;
  activity_profile?: 'rural' | 'industrial' | 'market' | 'public-service';
  is_peasant?: boolean;
  is_outsourced?: boolean;
  worker_function?: string;
  genre: 'male' | 'female' | 'other';
  phone: string;
  situation?: 'temporary' | 'permanent';
  email?: string;
  address?: string;
  city_id?: number;
  state?: string;
  postal_code?: string;
  is_temporary_address?: boolean;
  origin_address?: string;
  origin_city_id?: number;
  origin_state?: string;
  origin_postal_code?: string;
  salary_range?: number;
  is_unionized?: boolean;
  is_syndicate_approved: boolean | null;
  syndicate_approved_at: Date;
  syndicate_id?: string;
  city: {
    nome: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
  is_discount_agreed: boolean;
  is_lgpd_agreed: boolean;
  is_privacy_terms_agreed: boolean;
  created_at: Date;
}

interface IBGECities {
  id: number;
  nome: string;
}

interface IBGEStates {
  id: number;
  nome: string;
  uf: string;
}

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const printRef = useRef<HTMLDivElement>(null);

  // Loading Data
  const [loading, setLoading] = useState(true);
  const [worker, setWorker] = useState<IWorker>({} as IWorker);
  const [cities, setCities] = useState<IBGECities[]>([]);
  const [states, setStates] = useState<IBGEStates[]>([]);

  // Modal
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showForward, setShowForward] = useState(false);

  const checkWorkerActivityProfile = useCallback(
    (value: string | undefined) => {
      switch (value) {
        case 'rural':
          return 'Rural';
        case 'industrial':
          return 'Industrial';
        case 'market':
          return 'Comércio';
        case 'public-service':
          return 'Serviço Público';
        default:
          return 'Não definido';
      }
    },
    [],
  );

  const pageStyle = `
  @page {
    margin: 64px;
    margin-top: 128px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Ficha - ${worker.first_name} ${worker.last_name}`,
    pageStyle: () => pageStyle,
  });

  const handleWorkerApproval = useCallback(
    async value => {
      if (value === 'approved') {
        await api
          .put(`/workers/${id}`, {
            is_syndicate_approved: true,
            is_unionized: true,
          })
          .then(response => {
            setWorker(response.data);
          });
      } else {
        await api
          .put(`/workers/${id}`, {
            is_syndicate_approved: null,
            is_unionized: false,
          })
          .then(response => {
            setWorker(response.data);
          });
      }
    },
    [id],
  );

  useEffect(() => {
    const loadData = async () => {
      const loadedWorker = await api.get(`/workers/${id}`);
      const loadedStates = await api.get(`/ibge/states`);
      const loadedCities = await api.get(
        `/ibge/cities/${loadedWorker.data.state}`,
      );

      setWorker(loadedWorker.data);
      setStates(loadedStates.data);
      setCities(loadedCities.data);
      setLoading(false);
    };

    loadData();
  }, [id]);

  return (
    <BasePage title="Trabalhador">
      {loading && <LoadingState />}
      {!loading && worker && (
        <>
          <Container ref={printRef}>
            <Row className="mb-3">
              <Col>
                {worker.is_syndicate_approved === null && (
                  <Alert variant="primary">
                    <p style={{ margin: 0 }}>
                      Trabalhador não está associado a nenhum sindicato.
                    </p>
                  </Alert>
                )}
                {worker.is_syndicate_approved === true && (
                  <Alert variant="success">
                    <p style={{ margin: 0 }}>
                      {`Trabalhador associado ao ${worker?.syndicate?.nome_fantasia}.`}
                    </p>
                  </Alert>
                )}
                {worker.is_syndicate_approved === false && (
                  <Alert variant="warning">
                    <Row>
                      <Col className="d-flex align-items-center">
                        <p style={{ margin: 0 }}>
                          {`Trabalhador aguarda aprovação para sindicalizar-se ao ${worker?.syndicate?.nome_fantasia}.`}
                        </p>
                      </Col>
                      <Col className="col-5 d-flex justify-content-end">
                        <Button
                          variant="success"
                          onClick={() => handleWorkerApproval('approved')}
                        >
                          Aprovar
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleWorkerApproval('rejected')}
                          className="ml-1"
                        >
                          Rejeitar
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => setShowForward(true)}
                          className="ml-1"
                        >
                          Encaminhar
                        </Button>
                      </Col>
                    </Row>
                  </Alert>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex">
                <h2 style={{ margin: 0 }}>
                  {`${worker.first_name} ${worker.last_name}`}
                </h2>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Button
                  variant="primary"
                  onClick={handlePrint}
                  className="d-print-none"
                >
                  <FiPrinter size={20} color="#ffffff" />
                </Button>
                <Button
                  variant="primary"
                  className="ml-1 d-print-none"
                  onClick={() => setShowEdit(true)}
                >
                  Editar informações
                </Button>
                <Button
                  variant="danger"
                  className="ml-1 d-print-none"
                  onClick={() => setShowDelete(true)}
                >
                  Apagar trabalhador
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Form.Group controlId="formCPF">
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CPF"
                    value={worker.cpf}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formRG">
                  <Form.Label>RG</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="RG"
                    value={worker.rg}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formGenero">
                  <Form.Label>Gênero</Form.Label>
                  {worker.genre === 'other' && (
                    <Form.Control
                      type="text"
                      placeholder="Gênero"
                      value="Outro"
                      readOnly
                    />
                  )}
                  {worker.genre !== 'other' && (
                    <Form.Control
                      type="text"
                      placeholder="Gênero"
                      value={worker.genre === 'male' ? 'Masculino' : 'Feminino'}
                      readOnly
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formTelefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Telefone"
                    value={worker.phone}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={worker.email || 'E-mail não informado'}
                    value={worker.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Form.Group controlId="formAtividade">
                  <Form.Label>Perfil de atividade</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Perfil de atividade"
                    value={checkWorkerActivityProfile(worker.activity_profile)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                {worker.activity_profile === 'rural' && (
                  <>
                    <Form.Label>Safrista</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label="Sim"
                        type="radio"
                        name="is_peasant"
                        value="1"
                        disabled
                        checked={worker.is_peasant === true}
                      />
                      <Form.Check
                        inline
                        label="Não"
                        type="radio"
                        name="is_peasant"
                        value="0"
                        disabled
                        checked={worker.is_peasant === false}
                      />
                    </div>
                  </>
                )}
                {worker.activity_profile === 'industrial' && (
                  <>
                    <Form.Label>Terceirizado</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label="Sim"
                        type="radio"
                        name="is_outsourced"
                        value="1"
                        disabled
                        checked={worker.is_outsourced === true}
                      />
                      <Form.Check
                        inline
                        label="Não"
                        type="radio"
                        name="is_outsourced"
                        value="0"
                        disabled
                        checked={worker.is_outsourced === false}
                      />
                    </div>
                  </>
                )}
              </Col>
              <Col>
                <Form.Group controlId="formSituacao">
                  <Form.Label>Situação</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={worker.situation || 'Não informado'}
                    value={
                      worker.situation === 'permanent'
                        ? 'Permanente'
                        : 'Temporário'
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formFuncao">
                  <Form.Label>Função do trabalhador</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={worker.worker_function || 'Não informado'}
                    value={worker.worker_function}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="salary_range">
                  <Form.Label>Faixa salarial</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={worker.salary_range}
                    disabled
                  >
                    <option>Selecione uma opção</option>
                    <option value="1">
                      Menos que 1 Salário Minímo (R$1.110,00)
                    </option>
                    <option value="2">Até 1 Salário Minímo (R$1.100,00)</option>
                    <option value="3">
                      De 1 a 2 Salários Minímo (R$1.100,00 a R$2.200,00)
                    </option>
                    <option value="4">
                      De 2 a 4 Salários Minímo (R$2.200,00 a R$4.400,00)
                    </option>
                    <option value="5">
                      Maior que 4 Salários Minímo (R$4.400,00)
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="col-2">
                <Form.Group controlId="cep">
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={worker.postal_code || 'Não informado'}
                    value={worker.postal_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group controlId="address">
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={worker.address || 'Não informado'}
                    value={worker.address}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="state">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    placeholder={worker.state || 'Não informado'}
                    value={worker.state}
                    disabled
                  >
                    <option value="0">Selecione uma opção</option>
                    {states?.map(state => (
                      <option key={state.id} value={state.uf}>
                        {state.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="city">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={worker.city_id}
                    disabled
                  >
                    <option value="0">Selecione uma opção</option>
                    {cities.map(city => (
                      <option value={city.id}>{city.nome}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Label>É um endereço temporário?</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Sim"
                    type="radio"
                    name="is_temporary_address"
                    value="1"
                    disabled
                    checked={worker.is_temporary_address === true}
                  />
                  <Form.Check
                    inline
                    label="Não"
                    type="radio"
                    name="is_temporary_address"
                    value="0"
                    disabled
                    checked={worker.is_temporary_address === false}
                  />
                </div>
              </Col>
            </Row>
            {worker.is_temporary_address && (
              <Row>
                <Col className="col-2">
                  <Form.Group controlId="cep">
                    <Form.Label>CEP de origem</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={worker.origin_postal_code || 'Não informado'}
                      value={worker.origin_postal_code}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col className="col-4">
                  <Form.Group controlId="address">
                    <Form.Label>Endereço de origem</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={worker.origin_address || 'Não informado'}
                      value={worker.origin_address}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="state">
                    <Form.Label>Estado de origem</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      placeholder={worker.origin_state || 'Não informado'}
                      value={worker.origin_state}
                      disabled
                    >
                      <option value="0">Selecione uma opção</option>
                      {states?.map(state => (
                        <option key={state.id} value={state.uf}>
                          {state.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="city">
                    <Form.Label>Cidade de origem</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={worker.origin_city_id}
                      disabled
                    >
                      <option value="0">Selecione uma opção</option>
                      {cities.map(city => (
                        <option key={city.id} value={city.id}>
                          {city.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <hr />
            <Row>
              <Col>
                <Form.Group controlId="formCadastro">
                  <Form.Label>Cadastrado desde</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cadastrado desde"
                    value={format(
                      new Date(worker.created_at),
                      "dd/MM/yyyy 'às' HH:mm",
                    )}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Compartilhamento de dados com sindicato:
                  </Form.Label>
                  {worker.is_lgpd_agreed ? (
                    <DataInfoContainer>
                      <FiCheckCircle size={24} color="green" />
                      <span>Autorizou</span>
                    </DataInfoContainer>
                  ) : (
                    <DataInfoContainer>
                      <FiXCircle size={24} color="red" />
                      <span>Não autorizou</span>
                    </DataInfoContainer>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Desconto de 1% em folha:</Form.Label>
                  {worker.is_discount_agreed ? (
                    <DataInfoContainer>
                      <FiCheckCircle size={24} color="green" />
                      <span>Autorizou</span>
                    </DataInfoContainer>
                  ) : (
                    <DataInfoContainer>
                      <FiXCircle size={24} color="red" />
                      <span>Não autorizou</span>
                    </DataInfoContainer>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {worker.is_syndicate_approved === true && (
              <Row>
                <Col>
                  <Form.Group controlId="formSindicalizado">
                    <Form.Label>Sindicalizado desde</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Sindicalizado desde"
                      value={format(
                        new Date(worker.syndicate_approved_at),
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Container>

          <DeleteWorkerModal
            workerId={id}
            handleShowModal={setShowDelete}
            showModal={showDelete}
          />

          <EditWorkerModal
            handleWorker={setWorker}
            worker={worker}
            handleShowModal={setShowEdit}
            showModal={showEdit}
          />

          <ForwardWorkerModal
            worker={worker}
            show={showForward}
            handleShow={setShowForward}
          />
        </>
      )}
    </BasePage>
  );
};

export default Profile;
