import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
}

interface IResearch {
  id: string;
}

interface IProps {
  show: boolean;
  selectedResearch: string;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleResearches: (researches: IResearch[]) => void;
}

const DeleteResearchModal: React.FC<IProps> = ({
  selectedResearch,
  show,
  handleShow,
  handleLoading,
  handleResearches,
}) => {
  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleDeleteResearch = useCallback(async () => {
    try {
      handleLoading(true);

      await api.delete(`/research/${selectedResearch}`);

      addToast({
        title: 'Sucesso',
        body: 'A pesquisa foi removida com sucesso.',
      });

      const loadedResearches = await api.get<IResearch[]>('/research');

      handleResearches(loadedResearches.data);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao remover a pesquisa.',
      });
    } finally {
      handleClose();
      handleLoading(false);
    }
  }, [
    addToast,
    handleLoading,
    selectedResearch,
    handleResearches,
    handleClose,
  ]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remover pesquisa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Você está certo de que deseja remover a pesquisa? Essa ação não poderá
          ser desfeita e todos os dados serão perdidos.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={() => handleDeleteResearch()}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteResearchModal;
