/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import BasePage from '../../../components/BasePage';
import Pagination from '../../../components/Pagination';
import LoadingState from '../../../components/LoadingState';

import AddFowardModal from './AddFowardModal';
import DeleteFowardModal from './DeleteFowardModal';
import EditFowardModal from './EditFowardModal';

import api from '../../../services/api';

interface ISelectRedirect {
  action: 'edit' | 'remove';
  id: string;
}

interface IFoward {
  id: string;
  name: string;
  filters: {
    state?: string;
    type?: string;
    syndicate_id?: string;
    company_id?: string;
    city_id?: string;
  };
  foward_syndicate_id: string;
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

const FowardComplaint: React.FC = () => {
  const [page, setPages] = useState(1);
  const [totalRedirections, setTotalRedirections] = useState(1);

  const [loading, setLoading] = useState(true);
  const [redirections, setRedirections] = useState<IFoward[]>([]);
  const [selected, setSelected] = useState<ISelectRedirect>(
    {} as ISelectRedirect,
  );

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const listFowards = useCallback(async () => {
    const loadedFowards = await api.get('/complaints/foward');
    return setRedirections(loadedFowards.data);
  }, []);

  useEffect(() => {
    if (selected.action === 'edit') {
      setShowEditModal(true);
    }

    if (selected.action === 'remove') {
      setShowDeleteModal(true);
    }
  }, [selected]);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      await listFowards();
      setLoading(false);
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [listFowards]);

  return (
    <BasePage title="Redirecionamento de denúncias">
      {loading && <LoadingState />}
      {!loading && redirections && (
        <Container fluid>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button variant="success" onClick={() => setShowAddModal(true)}>
                Adicionar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Redirecionar para</th>
                    <th style={{ width: '15%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {redirections.map(foward => (
                    <tr>
                      <td>{foward.name}</td>
                      <td>{foward.syndicate.nome_fantasia}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="mr-1"
                          onClick={() => {
                            return setSelected({
                              action: 'edit',
                              id: foward.id,
                            });
                          }}
                        >
                          <FiEdit color="#ffffff" size={20} />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => {
                            return setSelected({
                              action: 'remove',
                              id: foward.id,
                            });
                          }}
                        >
                          <FiTrash2 color="#ffffff" size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Pagination
                currentPage={page}
                pageHandler={setPages}
                totalItems={totalRedirections}
              />
            </Col>
          </Row>
        </Container>
      )}

      <AddFowardModal
        handleShow={setShowAddModal}
        show={showAddModal}
        handleLoading={setLoading}
        refreshFowards={listFowards}
      />

      <EditFowardModal
        show={showEditModal}
        handleShow={setShowEditModal}
        handleLoading={setLoading}
        refreshFowards={listFowards}
        selected={selected.id}
      />

      <DeleteFowardModal
        show={showDeleteModal}
        handleShow={setShowDeleteModal}
        selected={selected.id}
        handleLoading={setLoading}
        refreshFowards={listFowards}
      />
    </BasePage>
  );
};

export default FowardComplaint;
