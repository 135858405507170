/* eslint-disable react/require-default-props */
/* eslint-disable import/no-duplicates */
import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/auth';
import Datepicker from '../../../components/Datepicker';
import { useToast } from '../../../hooks/useToast';
import LoadingState from '../../../components/LoadingState';

import api from '../../../services/api';

registerLocale('ptBR', ptBR);

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IWorker {
  id: string;
  first_name: string;
  last_name: string;
}

interface ISelect {
  [key: string]: string;
}

interface ISubscription {
  id: string;
  syndicate_id: string;
  worker_id: string;
  base_salary: number;
  discount_percentage: number;
  discount_value: number;
  recurring_frequency: string;
  is_active: boolean;
  invoice_date: Date;
  next_invoice_date: Date;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  updateInvoices?: () => Promise<void>;
  handleLoading: (value: boolean) => void;
  refreshSubscriptions?: () => Promise<void>;
  selectedSubscription: string;
  cleanSelected: () => void;
}

const EditSubscriptionModal: React.FC<IProps> = ({
  show,
  handleShow,
  updateInvoices = () => undefined,
  handleLoading,
  refreshSubscriptions = () => undefined,
  selectedSubscription,
  cleanSelected,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [subscription, setSubscription] = useState<ISubscription>(
    {} as ISubscription,
  );
  const [workers, setWorkers] = useState<ISelect[]>();
  const [unions, setUnions] = useState<ISelect[]>();

  const calculateDiscountValue = useCallback(
    (
      salary: string,
      discount: string,
      cb: (field: string, value: any) => void,
    ) => {
      const base = salary.replace('.', '').replace('R$', '');
      const percentage = discount.replace('%', '');

      const value = (Number(base) * Number(percentage)) / 100;

      setDiscountValue(value);

      cb('discount_value', value);
    },
    [],
  );

  const handleClose = useCallback(() => {
    handleShow(false);
    setUnions([]);
    setWorkers([]);
    setLoading(true);
    setSubscription({} as ISubscription);
    setDiscountValue(0);
    cleanSelected();
  }, [handleShow, cleanSelected]);

  const parseWorkers = useCallback((loadedWorkers: IWorker[]) => {
    const parsedWorkers = loadedWorkers.map(worker => {
      return {
        label: `${worker.first_name} ${worker.last_name}`,
        value: worker.id,
      };
    });

    setWorkers(parsedWorkers);
  }, []);

  const updateWorkers = useCallback(
    async (syndicateId: string) => {
      const loadedWorkers = await api.get(
        `/workers/list-by-sindicate/${syndicateId}`,
      );
      parseWorkers(loadedWorkers.data);
    },
    [parseWorkers],
  );

  const frequencyOptions = [
    { value: 'monthly', label: 'Mensal' },
    { value: 'bimonthly', label: 'Bimestral' },
    { value: 'quarterly', label: 'Trimestral' },
    { value: 'semester', label: 'Semestral' },
    { value: 'yearly', label: 'Anual' },
  ];

  const handleUpdateSubscriptions = useCallback(
    async ({
      base_salary: base,
      discount_percentage: percentage,
      invoice_date: date,
      ...rest
    }) => {
      try {
        handleClose();
        handleLoading(true);
        const base_salary: number = parseFloat(
          base.replace('R$ ', '').replace('.', ''),
        );

        const discount_percentage: number =
          parseFloat(String(percentage).replace('%', '')) / 100;
        const invoice_date = new Date(date);

        await api.patch(`/financial/subscriptions/${selectedSubscription}`, {
          base_salary,
          discount_percentage,
          invoice_date,
          ...rest,
        });

        addToast({
          title: 'Sucesso',
          body: 'Recorrência atualizada com sucesso.',
        });

        if (rest.generate_first_invoice) {
          await updateInvoices();
        }

        await refreshSubscriptions();

        setLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar atualizar a contribuição recorrente. Tente novamente.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [
      addToast,
      updateInvoices,
      handleClose,
      handleLoading,
      refreshSubscriptions,
      selectedSubscription,
    ],
  );

  useEffect(() => {
    const loadData = async () => {
      if (user.role === 'admin') {
        // load unions
        const loadedUnions = await api.get<ISyndicate[]>('/unions');
        setUnions(
          loadedUnions.data.map(syndicate => {
            return {
              label: syndicate.nome_fantasia,
              value: syndicate.id,
            };
          }),
        );
      }

      const { data } = await api.get<ISubscription>(
        `/financial/subscriptions/${selectedSubscription}`,
      );

      setSubscription(data);
      setDiscountValue(data.discount_value);

      const loadedWorkers = await api.get(
        `/workers/list-by-sindicate/${data.syndicate_id}`,
      );
      parseWorkers(loadedWorkers.data);

      setLoading(false);
    };

    if (loading && selectedSubscription) {
      loadData();
    }
  }, [loading, user, parseWorkers, selectedSubscription]);

  const validationSchema = Yup.object().shape({
    syndicate_id: Yup.string().min(3).required('O sindicato é obrigatório'),
    worker_id: Yup.string().min(3).required('O trabalhador é obrigatório'),
    base_salary: Yup.string().required('É necessário indicar um salário base'),
    discount_percentage: Yup.string().required(
      'É necessário indicar uma porcentagem de desconto',
    ),
    discount_value: Yup.string().required('O desconto é obritório'),
    recurring_frequency: Yup.string().required(
      'É necessário indicar um período de recorrência',
    ),
    invoice_date: Yup.date().required(
      'É necessário indicar a data para a contribuição',
    ),
    is_active: Yup.boolean().required(
      'É obrigatório indicar o status da recorrência',
    ),
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      {loading && <LoadingState />}
      {!loading && subscription.worker_id && (
        <Formik
          initialValues={{
            syndicate_id:
              user.role === 'admin'
                ? subscription.syndicate_id
                : user.syndicate_id,
            worker_id: subscription.worker_id,
            base_salary: subscription.base_salary,
            discount_percentage: subscription.discount_percentage * 100,
            discount_value: subscription.discount_value,
            recurring_frequency: subscription.recurring_frequency,
            invoice_date: subscription.invoice_date,
            is_active: subscription.is_active,
          }}
          onSubmit={handleUpdateSubscriptions}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar contribuição recorrente</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {user.role === 'admin' && (
                  <Row>
                    <Col>
                      <Form.Group controlId="formUnionId">
                        <Form.Label>Sindicato</Form.Label>
                        <Form.Control
                          as="div"
                          custom
                          isValid={touched.syndicate_id && !errors.syndicate_id}
                          isInvalid={!!errors.syndicate_id}
                        >
                          <Select
                            options={unions}
                            placeholder="Selecione o sindicato"
                            onChange={event => {
                              if (event?.value) {
                                setFieldValue('syndicate_id', event?.value);
                                return updateWorkers(event!.value);
                              }
                              return setFieldValue('syndicate_id', '');
                            }}
                            isClearable
                            name="syndicate_id"
                            onBlur={handleBlur}
                            value={unions?.filter(
                              syndicate =>
                                syndicate.value === values.syndicate_id,
                            )}
                            isDisabled
                          />
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <ErrorMessage name="syndicate_id" />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group controlId="formWorkerId">
                      <Form.Label>Trabalhador</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isValid={touched.worker_id && !errors.worker_id}
                        isInvalid={!!errors.worker_id}
                      >
                        <Select
                          options={workers}
                          placeholder="Selecione o trabalhador"
                          onChange={event => {
                            if (event) {
                              return setFieldValue('worker_id', event?.value);
                            }
                            return setFieldValue('worker_id', '');
                          }}
                          value={workers?.filter(
                            worker => worker.value === values.worker_id,
                          )}
                          isClearable
                          name="worker_id"
                          onBlur={handleBlur}
                          isDisabled
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="worker_id" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formBaseSalary">
                      <Form.Label>Salário base</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isValid={touched.base_salary && !errors.base_salary}
                        isInvalid={!!errors.base_salary}
                        onBlur={handleBlur}
                      >
                        <NumberFormat
                          prefix={`R$ `}
                          thousandSeparator="."
                          decimalSeparator=","
                          allowLeadingZeros
                          placeholder="Digite o salário base do trabalhador"
                          className="form-control"
                          value={values.base_salary}
                          name="base_salary"
                          onChange={event => {
                            handleChange(event);
                            calculateDiscountValue(
                              event.target.value,
                              `${values.discount_percentage}`,
                              setFieldValue,
                            );
                          }}
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="base_salary" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicInvoicePercentage">
                      <Form.Label>Porcentagem de contribuição</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isValid={
                          touched.discount_percentage &&
                          !errors.discount_percentage
                        }
                        isInvalid={!!errors.discount_percentage}
                      >
                        <NumberFormat
                          suffix="%"
                          max={100}
                          maxLength={3}
                          placeholder="Digite a porgentagem"
                          value={values.discount_percentage}
                          className="form-control"
                          name="discount_percentage"
                          onChange={event => {
                            handleChange(event);
                            calculateDiscountValue(
                              `${values.base_salary}`,
                              event.target.value,
                              setFieldValue,
                            );
                          }}
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="discount_percentage" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasiInvoiceValue">
                      <Form.Label>Valor</Form.Label>
                      <NumberFormat
                        prefix={`R$ `}
                        thousandSeparator="."
                        decimalSeparator=","
                        allowLeadingZeros
                        placeholder="Digite o salário base do trabalhador"
                        className="form-control"
                        value={discountValue}
                        name="discount_value"
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="discount_value" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicInvoiceDate">
                      <Form.Label>Data da contribuição</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isValid={touched.invoice_date && !errors.invoice_date}
                        isInvalid={!!errors.invoice_date}
                      >
                        <Datepicker
                          locale="pt-BR"
                          name="invoice_date"
                          onChange={date => setFieldValue('invoice_date', date)}
                          placeholderText="Selecione a data de contribuição"
                          value={format(
                            new Date(values.invoice_date),
                            'dd/MM/yyyy',
                          ).toString()}
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="invoice_date" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Frequência</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isValid={
                          touched.recurring_frequency &&
                          !errors.recurring_frequency
                        }
                        isInvalid={!!errors.recurring_frequency}
                      >
                        <Select
                          options={frequencyOptions}
                          placeholder="Selecione o período de recorrência"
                          defaultValue={frequencyOptions.filter(
                            frequency =>
                              frequency.value === values.recurring_frequency,
                          )}
                          onChange={event => {
                            setFieldValue('recurring_frequency', event?.value);
                          }}
                          name="recurring_frequency"
                          onBlur={handleBlur}
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="recurring_frequency" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="subscriptionStatus">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        onChange={event => {
                          setFieldValue(
                            'is_active',
                            event.target.value === 'true',
                          );
                        }}
                        isValid={touched.is_active && !errors.is_active}
                        isInvalid={!!errors.is_active}
                        onBlur={handleBlur}
                      >
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="is_active" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Salvar
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditSubscriptionModal;
