import styled from 'styled-components';

export const Container = styled.div``;

export const Sidebar = styled.aside`
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;

  h3 {
    font-size: 18px;
    border-bottom: 1px solid #a8a8a8;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
`;
