/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import BasePage from '../../components/BasePage';
import { useToast } from '../../hooks/useToast';
import LoadingState from '../../components/LoadingState';
import FilterBar from '../../components/News/FilterBar';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

interface ICategories {
  id: string;
  title: string;
}

interface IPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  slug: string;
  status: string;
  type: string;
  author_id: string;
  featured_image: string;
  publishedAt: Date;
  categories: ICategories[];
  author: {
    first_name: string;
    last_name: string;
  };
}

const News: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState<IPost[]>();
  const [totalPosts, setTotalPosts] = useState(0);

  const { addToast } = useToast();

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/news/${id}`);

        const refreshedPosts = await api.get<IPost[]>(
          `/admin/posts?page=${currentPage}`,
        );
        setPosts(refreshedPosts.data);

        addToast({
          title: 'Sucesso',
          body: 'Notícia removida com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar remover a notícia.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    document.title = 'Notícias - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const response = await api.get<IPost[]>(
        `/admin/posts?page=${currentPage}`,
      );
      setPosts(response.data);
      setTotalPosts(response.headers['x-total-posts']);
      setLoading(false);
      setLoadingPosts(false);
    };

    loadData();
  }, [loading, currentPage]);

  return (
    <BasePage title="Notícias">
      {loading && <LoadingState />}
      {loading === false && (
        <>
          <Row className="mb-3">
            <Col className="col-11">
              <FilterBar
                handlePosts={setPosts}
                handleLoading={setLoadingPosts}
                pageHandler={setCurrentPage}
                currentPage={currentPage}
              />
            </Col>
            <Col
              className="col-1"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Link to="/news/create" className="btn btn-success">
                Adicionar
              </Link>
            </Col>
          </Row>
          <Row>
            {loadingPosts && <LoadingState />}
            {loadingPosts === false && (
              <Col>
                <Row>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Título</th>
                        <th style={{ width: '15%' }}>Autor</th>
                        <th>Categorias</th>
                        <th style={{ width: '10%' }}>Status</th>
                        <th style={{ width: '13%' }}>Data de publicação</th>
                        <th style={{ width: '7%' }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts?.map(post => (
                        <tr key={post.id}>
                          {/* <td>1</td> */}
                          <td>{post.title}</td>
                          <td>{`${post.author.first_name} ${post.author.last_name}`}</td>
                          <td>
                            {post.categories
                              .map(category => category.title)
                              .join(', ')}
                          </td>
                          <td>
                            {post.status === 'draft' && 'Rascunho'}
                            {post.status === 'published' && 'Publicado'}
                            {post.status === 'revision' && 'Revisão pendente'}
                          </td>
                          <td>
                            {format(
                              new Date(post.publishedAt),
                              "dd/MM/yyyy ' às ' HH:mm",
                            )}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => history.push(`/news/${post.id}`)}
                            >
                              <FiEdit size={20} color="#ffffff" />
                            </Button>
                            <Button
                              variant="danger"
                              className="ml-1"
                              size="sm"
                              onClick={() => handleDelete(post.id)}
                            >
                              <FiTrash2 size={20} color="#ffffff" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
                <Pagination
                  pageHandler={setCurrentPage}
                  currentPage={currentPage}
                  totalItems={totalPosts}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </BasePage>
  );
};

export default News;
