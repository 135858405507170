import styled from 'styled-components';
import { NavLink as ReactNavLink } from 'react-router-dom';

export const Container = styled.aside`
  background: #f7f7f7;
  position: sticky;
  top: 0;
  width: 140px;
  height: 100vh;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
`;

export const Logo = styled.img`
  padding: 24px 16px;
  max-width: 100%;
  margin-bottom: 32px;
  border-bottom: 3px solid white;
`;

export const NavLink = styled(ReactNavLink)`
  margin: 0 20px 0 20px;
`;
