import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { FiSearch } from 'react-icons/fi';
import Select from 'react-select';

import { Container } from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/useToast';
import api from '../../../services/api';

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface ICategory {
  id: string;
  title: string;
}

interface ISelectProps {
  value: string | undefined;
  label: string;
}

interface IPartner {
  id: string;
  name: string;
  description: string;
  address: string;
  address2: string;
  postal_code: string;
  city: {
    nome: string;
    uf: string;
  };
  state: string;
  whatsapp: string;
  phone: string;
  email: string;
  avatar: string;
  category: {
    id: string;
    title: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

interface IProps {
  handlePartners: (partners: IPartner[]) => void;
  currentPage: number;
  pageHandler: (value: number) => void;
  loadingHandler: (value: boolean) => void;
}

const FilterBar: React.FC<IProps> = ({
  handlePartners,
  currentPage,
  pageHandler,
  loadingHandler,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [unions, setUnions] = useState<ISelectProps[]>([]);
  const [categories, setCategories] = useState<ISelectProps[]>([]);

  const filterPartners = useCallback(
    async values => {
      try {
        loadingHandler(true);
        const filteredPartners = await api.get('/unions-partners', {
          params: { ...values, page: currentPage },
        });
        handlePartners(filteredPartners.data);
        pageHandler(1);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar os convênios.',
        });
      } finally {
        loadingHandler(false);
      }
    },
    [addToast, currentPage, pageHandler, loadingHandler, handlePartners],
  );

  useEffect(() => {
    const loadData = async () => {
      const loadedCategories = await api.get<ICategory[]>(
        '/unions-partners/categories',
      );
      const categoriesArray = loadedCategories.data.map(category => {
        return {
          value: category.id,
          label: category.title,
        };
      });
      setCategories(categoriesArray);

      if (user.role === 'admin') {
        const loadedUnions = await api.get<ISyndicate[]>('/unions');

        const unionsArray = loadedUnions.data.map(syndicate => {
          return {
            value: syndicate.id,
            label: syndicate.nome_fantasia,
          };
        });

        setUnions(unionsArray);
      }
    };

    loadData();
  }, [user]);

  return (
    <Container>
      <Formik
        initialValues={{
          name: '',
          category_id: '',
          syndicate_id: '',
        }}
        onSubmit={filterPartners}
      >
        {({ handleSubmit, values, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="findByName">
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="findByCategory">
                  <Select
                    options={categories}
                    placeholder="Selecione a categoria"
                    onChange={selectedCategory => {
                      setFieldValue('category_id', selectedCategory?.value);
                    }}
                    isClearable
                  />
                </Form.Group>
              </Col>
              {user.role === 'admin' && (
                <Col>
                  <Form.Group controlId="findBySyndicate">
                    <Select
                      options={unions}
                      placeholder="Selecione o sindicato"
                      onChange={selectedSyndicate => {
                        setFieldValue('syndicate_id', selectedSyndicate?.value);
                      }}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              )}
              <Col className="col-1 d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  <FiSearch size={24} color="#ffffff" />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
