/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import BasePage from '../../../components/BasePage';
import LoadingState from '../../../components/LoadingState';
import Pagination from '../../../components/Pagination';

import CreateSegmentModal from './CreateSegmentModal';
import EditSegmentModal from './EditSegmentModal';
import DeleteSegmentModal from './DeleteSegmentModal';
import TotalWorkers from './TotalWorkers';

import api from '../../../services/api';

interface ISegment {
  id: string;
  name: string;
  syndicate_id: string;
  is_admin: boolean;
  filters: {
    [key: string]: any;
  };
}

const WorkersSegments: React.FC = () => {
  const [pages, setPages] = useState(1);
  const [totalItens, setTotalItens] = useState(1);

  const [loading, setLoading] = useState(true);
  const [segments, setSegments] = useState<ISegment[]>([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedSegment, setSelectedSegment] = useState<string>('');

  const defineSegment = async (id: string) => {
    return setSelectedSegment(id);
  };

  const editSegmentModal = useCallback(async (id: string) => {
    await defineSegment(id);
    return setShowEditModal(true);
  }, []);

  const deleteSegmentModal = useCallback(
    (id: string) => {
      setSelectedSegment(id);
      setShowDeleteModal(true);
    },
    [setShowDeleteModal],
  );

  useEffect(() => {
    document.title = `Segmentos de Trabalhadores - ${process.env.REACT_APP_NAME}`;

    const loadData = async () => {
      const loadedSegments = await api.get('/workers-segments');

      setSegments(loadedSegments.data);
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <BasePage title="Segmentos de Trabalhadores">
      {loading && <LoadingState />}
      {!loading && (
        <>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="success"
                onClick={() => setShowCreateModal(true)}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '65%' }}>Nome</th>
                    <th>Qtde.</th>
                    {/* <th>Administrativo</th> */}
                    <th style={{ width: '13%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {segments.map(segment => (
                    <tr key={segment.id}>
                      <td>{segment.name}</td>
                      <td>
                        <TotalWorkers segmentId={segment.id} />
                      </td>
                      {/* <td>{segment.is_admin ? 'Sim' : 'Não'}</td> */}
                      <td>
                        <Button
                          className="mr-1"
                          onClick={() => editSegmentModal(segment.id)}
                        >
                          <FiEdit color="#ffffff" size={20} />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => deleteSegmentModal(segment.id)}
                        >
                          <FiTrash2 color="#ffffff" size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={pages}
                pageHandler={setPages}
                totalItems={totalItens}
              />
            </Col>
          </Row>
          <CreateSegmentModal
            show={showCreateModal}
            handleShowModal={setShowCreateModal}
            handleSegments={setSegments}
            handleLoading={setLoading}
          />
          <EditSegmentModal
            segment_id={selectedSegment}
            show={showEditModal}
            handleShowModal={setShowEditModal}
            handleSegments={setSegments}
            handleLoading={setLoading}
            handleSelectedSegment={setSelectedSegment}
          />
          <DeleteSegmentModal
            segment_id={selectedSegment}
            show={showDeleteModal}
            handleShow={setShowDeleteModal}
            handleLoading={setLoading}
            handleSegments={setSegments}
          />
        </>
      )}
    </BasePage>
  );
};

export default WorkersSegments;
