import React, { useEffect, useState, useCallback } from 'react';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Modal, Col, Row, Form, Container, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import BasePage from '../../../components/BasePage';
import FilterBar from '../../../components/Config/Unions/FilterBar';
import Pagination from '../../../components/Pagination';
import LoadingState from '../../../components/LoadingState';
import { useToast } from '../../../hooks/useToast';

import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import EditSyndicateModal from './EditSyndicateModal';

import { Button, ButtonContainer, PreviewImage } from './styles';

interface IState {
  uf: string;
  nome: string;
}

interface ICity {
  id: number;
  nome: string;
}

interface ISyndicateInfo {
  id: string;
  nome_fantasia: string;
  razao_social: string;
  cnpj: string;
  email: string;
  type: string;
  phone: string;
  address: string;
  address2: string;
  city_id: number;
  state: string;
  postal_code: string;
  avatar: string;
  discount_percentage: number;
}

const Unions: React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUnions, setLoadingUnions] = useState(true);
  const [unions, setUnions] = useState<ISyndicateInfo[]>([]);
  const [totalUnions, setTotalUnions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { user } = useAuth();
  const { addToast } = useToast();

  const [syndicateInfo, setSyndicateInfo] = useState<ISyndicateInfo>();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  // Image upload
  const [hasFeaturedImg, setHasFeaturedImg] = useState(false);
  const [featuredImgLabel, setFeaturedImgLabel] = useState('Selecionar imagem');
  const [featuredImgSrc, setFeaturedImgSrc] = useState('');

  const resetImageUpload = useCallback(() => {
    setHasFeaturedImg(false);
    setFeaturedImgLabel('Selecionar imagem');
    setFeaturedImgSrc('');
  }, []);

  const handleImageUpload = useCallback(event => {
    if (event.target.files[0]) {
      setHasFeaturedImg(true);

      const alt = event.target.files[0].name;
      const src = URL.createObjectURL(event.target.files[0]);

      setFeaturedImgLabel(alt);
      setFeaturedImgSrc(src);
    }
  }, []);

  // Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [syndicateID, setSyndicateID] = useState('');

  const handleShowAdd = useCallback(async () => {
    setShowAddModal(true);
  }, []);

  const handleLoadCities = useCallback(async (uf: string) => {
    await api
      .get(`/ibge/cities/${uf}`)
      .then(response => setCities(response.data));
  }, []);

  const handleShowDelete = useCallback((id: string) => {
    setShowDeleteModal(true);
    setSyndicateID(id);
  }, []);

  const handleClose = useCallback(() => {
    resetImageUpload();

    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowAddModal(false);
  }, [resetImageUpload]);

  const handleAddSyndicate = useCallback(
    async ({ avatar, discount_percentage: discountPercentage, ...rest }) => {
      const discount_percentage: number =
        parseFloat(discountPercentage.replace('%', '')) / 100;

      const syndicateData = new FormData();
      syndicateData.append('avatar', avatar);
      syndicateData.append(
        'document',
        JSON.stringify({ ...rest, discount_percentage }),
      );

      try {
        await api.post('/unions', syndicateData);
        await api.get('/unions').then(response => setUnions(response.data));
        setShowAddModal(false);

        addToast({
          title: 'Sucesso',
          body: 'Novo sindicato adicionado com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar um novo sindicato.',
        });
      }
    },
    [addToast],
  );

  const handleDeleteSyndicate = useCallback(async () => {
    await api.delete(`/unions/${syndicateID}`);
    await api.get('/unions').then(response => setUnions(response.data));
    setShowDeleteModal(false);
  }, [syndicateID]);

  const handleEditShowSyndicate = useCallback(async (id: string) => {
    setSyndicateID(id);
    setShowEditModal(true);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const unionsResponse = await api.get('/unions', {
        params: { page: currentPage },
      });
      const statesResponse = await api.get('/ibge/states');

      setUnions(unionsResponse.data);
      setTotalUnions(unionsResponse.headers['x-total-unions']);
      setStates(statesResponse.data);

      setLoadingData(false);
      setLoadingUnions(false);
    };

    loadData();
  }, [loadingData, currentPage]);

  const validationSchema = Yup.object().shape({
    nome_fantasia: Yup.string().required('O campo nome é obrigatório.'),
    razao_social: Yup.string(),
    cnpj: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email(),
    address: Yup.string(),
    address2: Yup.string(),
    city_id: Yup.number(),
    state: Yup.string().required(),
    postal_code: Yup.string(),
    type: Yup.string().required('O campo tipo é obrigatório.'),
    discount_percentage: Yup.string().required('O campo é obrigatório'),
  });

  return (
    <BasePage title="Sindicatos">
      {loadingData && <LoadingState />}
      {loadingData === false && (
        <>
          <Row className="mb-3">
            <Col>
              <FilterBar
                pageHandler={setCurrentPage}
                currentPage={currentPage}
                handleLoading={setLoadingUnions}
                handleUnions={setUnions}
              />
            </Col>
            <Col className="d-flex align-items-center col-2">
              <Button
                variant="success"
                className="ml-auto"
                onClick={() => handleShowAdd()}
              >
                Adicionar sindicato
              </Button>
            </Col>
          </Row>
          <Row>
            {loadingUnions && <LoadingState />}
            {loadingUnions === false && (
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Nome</th>
                      <th style={{ width: '20%' }}>E-mail</th>
                      <th style={{ width: '15%' }}>Telefone</th>
                      <th style={{ width: '15%' }}>Tipo</th>
                      <th style={{ width: '10%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unions.map(syndicate => (
                      <tr key={syndicate.id}>
                        {/* <td>1</td> */}
                        <td>{syndicate.nome_fantasia}</td>
                        <td>{syndicate.email}</td>
                        <td>{syndicate.phone}</td>
                        <td>
                          {syndicate.type === 'industrial' && 'Industrial'}
                          {syndicate.type === 'rural' && 'Rural'}
                          {syndicate.type === 'market' && 'Comércio'}
                          {syndicate.type === 'public-service' &&
                            'Serviço Público'}
                        </td>
                        <td>
                          <ButtonContainer>
                            <Button
                              variant="primary"
                              onClick={() => {
                                handleEditShowSyndicate(syndicate.id);
                              }}
                            >
                              <FiEdit size={20} />
                              Editar
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleShowDelete(syndicate.id)}
                            >
                              <FiTrash2 size={20} />
                            </Button>
                          </ButtonContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pageHandler={setCurrentPage}
                  currentPage={currentPage}
                  totalItems={totalUnions}
                />
              </Col>
            )}
          </Row>

          {/* Delete syndicate modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => handleClose()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Atenção</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Você cofirma que quer excluir este sindicato? Essa ação não
                poderá ser desfeita e todos os dados serão perdidos.
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={() => handleDeleteSyndicate()}>
                Excluir
              </Button>
              <Button variant="secondary" onClick={() => handleClose()}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Create syndicate modal */}
          <Modal
            show={showAddModal}
            onHide={() => handleClose()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            dialogClassName="modal-70w"
          >
            <Formik
              initialValues={{
                nome_fantasia: undefined,
                razao_social: undefined,
                cnpj: undefined,
                phone: undefined,
                email: undefined,
                type: undefined,
                address: undefined,
                address2: undefined,
                city_id: undefined,
                state: undefined,
                postal_code: undefined,
                avatar: undefined,
                discount_percentage: undefined,
              }}
              validationSchema={validationSchema}
              onSubmit={values => handleAddSyndicate(values)}
            >
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                touched,
                errors,
                setFieldValue,
              }) => (
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar Sindicato</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Form onSubmit={() => handleSubmit()}>
                      <Container>
                        <Row>
                          <Col>
                            <Form.Group controlId="first_name">
                              <Form.Label>Nome do Sindicato *</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Nome do sindicato"
                                value={values.nome_fantasia}
                                onChange={handleChange('nome_fantasia')}
                                onBlur={handleBlur('nome_fantasia')}
                                isValid={
                                  touched.nome_fantasia && !errors.nome_fantasia
                                }
                                isInvalid={!!errors.nome_fantasia}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="razao_social">
                              <Form.Label>Razão Social</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Razão social do sindicato"
                                value={values.razao_social}
                                onChange={handleChange('razao_social')}
                                onBlur={handleBlur('razao_social')}
                                isValid={
                                  touched.razao_social && !errors.razao_social
                                }
                                isInvalid={!!errors.razao_social}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="cnpj">
                              <Form.Label>CNPJ</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="CNPJ do sindicato"
                                value={values.cnpj}
                                onChange={handleChange('cnpj')}
                                onBlur={handleBlur('cnpj')}
                                isValid={touched.cnpj && !errors.cnpj}
                                isInvalid={!!errors.cnpj}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <Form.Group controlId="phone">
                              <Form.Label>Telefone</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Telefone do sindicato"
                                value={values.phone}
                                onChange={handleChange('phone')}
                                onBlur={handleBlur('phone')}
                                isValid={touched.phone && !errors.phone}
                                isInvalid={!!errors.phone}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="email">
                              <Form.Label>E-mail</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="E-mail do sindicato"
                                value={values.email}
                                onChange={handleChange('email')}
                                onBlur={handleBlur('email')}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <Form.Group controlId="postal_code">
                              <Form.Label>CEP</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="CEP do sindicato"
                                value={values.postal_code}
                                onChange={handleChange('postal_code')}
                                onBlur={handleBlur('postal_code')}
                                isValid={
                                  touched.postal_code && !errors.postal_code
                                }
                                isInvalid={!!errors.postal_code}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="address">
                              <Form.Label>Endereço</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Endereço do sindicato"
                                value={values.address}
                                onChange={handleChange('address')}
                                onBlur={handleBlur('address')}
                                isValid={touched.address && !errors.address}
                                isInvalid={!!errors.address}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="address2">
                              <Form.Label>Complemento</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Complemento do endereço"
                                value={values.address2}
                                onChange={handleChange('address2')}
                                onBlur={handleBlur('address2')}
                                isValid={touched.address2 && !errors.address2}
                                isInvalid={!!errors.address2}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="state">
                              <Form.Label>Estado</Form.Label>
                              <Form.Control
                                as="select"
                                value={values.state}
                                onChange={item => {
                                  handleChange('state')(item);
                                  handleLoadCities(item.target.value);
                                }}
                                onBlur={handleBlur('state')}
                                isValid={touched.state && !errors.state}
                                isInvalid={!!errors.state}
                              >
                                <option value="0">Selecione um Estado</option>
                                {states.map(state => (
                                  <option key={state.uf} value={state.uf}>
                                    {state.nome}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="city">
                              <Form.Label>Cidade</Form.Label>
                              <Form.Control
                                as="select"
                                value={values.city_id}
                                onChange={handleChange('city_id')}
                                onBlur={handleBlur('city_id')}
                                isValid={touched.city_id && !errors.city_id}
                                isInvalid={!!errors.city_id}
                              >
                                <option value="0" disabled>
                                  Selecione uma cidade
                                </option>
                                {cities.map(city => (
                                  <option key={city.id} value={city.id}>
                                    {city.nome}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="type">
                              <Form.Label>Tipo *</Form.Label>
                              <Form.Control
                                as="select"
                                value={values.type}
                                onChange={handleChange('type')}
                                onBlur={handleBlur('type')}
                                isValid={touched.type && !errors.type}
                                isInvalid={!!errors.type}
                              >
                                <option value="0">Selecione um tipo</option>
                                <option value="rural">Rural</option>
                                <option value="industrial">Industrial</option>
                                <option value="market">Comércio</option>
                                <option value="public-service">
                                  Serviço Público
                                </option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="type">
                              <Form.Label>Porcentagem de desconto</Form.Label>
                              <Form.Control
                                as="div"
                                custom
                                isValid={
                                  touched.discount_percentage &&
                                  !errors.discount_percentage
                                }
                                isInvalid={!!errors.discount_percentage}
                              >
                                <NumberFormat
                                  suffix="%"
                                  max={100}
                                  maxLength={3}
                                  placeholder="Digite a porgentagem"
                                  value={values.discount_percentage}
                                  className="form-control"
                                  name="discount_percentage"
                                  onChange={event => {
                                    handleChange(event);
                                  }}
                                />
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.discount_percentage}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          {hasFeaturedImg && (
                            <Col className="col-3">
                              <PreviewImage
                                src={featuredImgSrc || values.avatar}
                                alt={featuredImgLabel}
                              />
                            </Col>
                          )}
                          <Col>
                            <Form.Group>
                              <Form.Label>Logotipo do sindicato</Form.Label>
                              <Form.File
                                id="avatar"
                                accept="image/png, image/jpeg, image/jpg"
                                label={featuredImgLabel}
                                data-browse="Selecionar"
                                custom
                                onChange={(event: any) => {
                                  handleImageUpload(event);
                                  setFieldValue(
                                    'avatar',
                                    event.target.files[0],
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Salvar
                    </Button>
                    <Button variant="secondary" onClick={() => handleClose()}>
                      Cancelar
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Modal>

          <EditSyndicateModal
            handleShow={setShowEditModal}
            handleUnions={setUnions}
            show={showEditModal}
            syndicateId={syndicateID}
            states={states}
          />
        </>
      )}
    </BasePage>
  );
};

export default Unions;
