/* eslint-disable react/require-default-props */
/* eslint-disable import/no-duplicates */
import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import { addDays } from 'date-fns';
import NumberFormat from 'react-number-format';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useAuth } from '../../../hooks/auth';
import Datepicker from '../../../components/Datepicker';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

registerLocale('ptBR', ptBR);

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IWorker {
  id: string;
  first_name: string;
  last_name: string;
}

interface ISelect {
  [key: string]: string;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleRefreshInvoices?: () => void;
}

const AddInvoiceModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  handleRefreshInvoices = () => undefined,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [unions, setUnions] = useState<ISelect[]>([]);
  const [workers, setWorkers] = useState<ISelect[]>([]);

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const parseWorkers = useCallback((loadedWorkers: IWorker[]) => {
    const parsedWorkers = loadedWorkers.map(worker => {
      return {
        label: `${worker.first_name} ${worker.last_name}`,
        value: worker.id,
      };
    });

    setWorkers(parsedWorkers);
  }, []);

  const updateWorkers = useCallback(
    async (syndicateId: string) => {
      const loadedWorkers = await api.get(
        `/workers/list-by-sindicate/${syndicateId}`,
      );
      parseWorkers(loadedWorkers.data);
    },
    [parseWorkers],
  );

  const handleCreateInvoice = useCallback(
    async ({ invoice_value, ...rest }) => {
      try {
        handleClose();
        handleLoading(true);

        const value: number = parseFloat(
          invoice_value.replace('R$ ', '').replace('.', ''),
        );

        await api.post('/financial/invoices', {
          ...rest,
          value,
        });

        addToast({
          title: 'Sucesso',
          body: 'A fatura foi adicionada com sucesso.',
        });

        handleRefreshInvoices();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar criar a contribuição recorrente. Tente novamente.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [addToast, handleLoading, handleClose, handleRefreshInvoices],
  );

  useEffect(() => {
    const loadData = async () => {
      if (user.role === 'admin') {
        const loadedUnions = await api.get<ISyndicate[]>('/unions');
        setUnions(
          loadedUnions.data.map(syndicate => {
            return {
              label: syndicate.nome_fantasia,
              value: syndicate.id,
            };
          }),
        );

        return;
      }

      const loadedWorkers = await api.get(
        `/workers/list-by-sindicate/${user.syndicate_id}`,
      );
      parseWorkers(loadedWorkers.data);
    };

    if (loading) {
      loadData();
    }

    return () => {
      setLoading(true);
      setUnions([]);
      setWorkers([]);
    };
  }, [loading, user, parseWorkers]);

  const validationSchema = Yup.object().shape({
    syndicate_id: Yup.string().required('Campo obrigatório'),
    worker_id: Yup.string().required('Campo obrigatório'),
    invoice_value: Yup.string().required('Campo obrigatório'),
    invoice_date: Yup.date().required('Campo obrigatório'),
    due_date: Yup.date().required('Campo obrigatório'),
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{
          syndicate_id: user.role === 'admin' ? '' : user.syndicate_id,
          worker_id: '',
          invoice_value: '',
          invoice_date: new Date(Date.now()),
          due_date: addDays(new Date(), 7),
        }}
        onSubmit={handleCreateInvoice}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar fatura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {user.role === 'admin' && (
                <Row>
                  <Col>
                    <Form.Group controlId="formSyndicate">
                      <Form.Label>Sindicato</Form.Label>
                      <Form.Control
                        as="div"
                        custom
                        isInvalid={!!errors.syndicate_id}
                        isValid={touched.syndicate_id && !errors.syndicate_id}
                      >
                        <Select
                          options={unions}
                          isClearable
                          placeholder="Selecione o sindicato"
                          name="syndicate_id"
                          onBlur={handleBlur}
                          onChange={event => {
                            if (event) {
                              setFieldValue('syndicate_id', event?.value);
                              return updateWorkers(event?.value);
                            }
                            return setFieldValue('syndicate_id', '');
                          }}
                        />
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="syndicate_id" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Group controlId="formWorkerId">
                    <Form.Label>Trabalhador</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.worker_id && !errors.worker_id}
                      isInvalid={!!errors.worker_id}
                    >
                      <Select
                        options={workers}
                        isClearable
                        placeholder="Selecione o trabalhador"
                        name="worker_id"
                        onChange={event => {
                          return event
                            ? setFieldValue('worker_id', event?.value)
                            : setFieldValue('worker_id', '');
                        }}
                        onBlur={handleBlur}
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="worker_id" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formInvoicevlaue">
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.invoice_value && !errors.invoice_value}
                      isInvalid={!!errors.invoice_value}
                    >
                      <NumberFormat
                        prefix={`R$ `}
                        thousandSeparator="."
                        decimalSeparator=","
                        allowLeadingZeros
                        placeholder="Digite o valor da fatura"
                        className="form-control"
                        value={values.invoice_value}
                        name="invoice_value"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="invoice_value" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formInvoiceDate">
                    <Form.Label>Data da fatura</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.invoice_date && !errors.invoice_date}
                      isInvalid={!!errors.invoice_date}
                    >
                      <Datepicker
                        locale="pt-BR"
                        name="invoice_date"
                        value={format(
                          new Date(values.invoice_date),
                          'dd/MM/yyyy',
                        )}
                        onChange={date => setFieldValue('invoice_date', date)}
                        placeholderText="Selecione a data de contribuição"
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="invoice_date" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formInvoiceDate">
                    <Form.Label>Data de vencimento</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.due_date && !errors.due_date}
                      isInvalid={!!errors.due_date}
                    >
                      <Datepicker
                        locale="pt-BR"
                        name="due_date"
                        value={format(new Date(values.due_date), 'dd/MM/yyyy')}
                        onChange={date => setFieldValue('due_date', date)}
                        placeholderText="Selecione a data de contribuição"
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="due_date" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddInvoiceModal;
