import styled from 'styled-components';

export const Container = styled.div``;

export const Sidebar = styled.aside`
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;

  h3 {
    font-size: 18px;
    border-bottom: 1px solid #a8a8a8;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
`;

export const LogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const LogContainer = styled.div`
  background: white;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;

  .message,
  .feedback {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .timestamp {
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
  }
`;
