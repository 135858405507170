/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';
import FilterBar from '../../components/UnionsPartners/FilterBar';
import Pagination from '../../components/Pagination';

import AddPartnerModal from './AddPartnerModal';
import EditPartnerModal from './EditPartnerModal';
import DeletePartnerModal from './DeletePartnerModal';

import api from '../../services/api';

interface IPartner {
  id: string;
  name: string;
  description: string;
  address: string;
  address2: string;
  postal_code: string;
  city: {
    nome: string;
    uf: string;
  };
  state: string;
  whatsapp: string;
  phone: string;
  email: string;
  avatar: string;
  category: {
    id: string;
    title: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

const UnionsPartners: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPartners, setTotalPartners] = useState(0);

  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partnerId, setPartnerId] = useState('');

  const [showAddModal, setshowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    document.title = 'Convênios - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const loadedPartners = await api.get('/unions-partners');
      setTotalPartners(loadedPartners.headers['x-total-partners']);
      setPartners(loadedPartners.data);

      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <BasePage title="Convênios">
      <Container fluid>
        <Row className="mb-3 d-flex align-items-center">
          <Col className="col-10">
            <FilterBar
              pageHandler={setCurrentPage}
              currentPage={currentPage}
              handlePartners={setPartners}
              loadingHandler={setLoading}
            />
          </Col>
          <Col className="col-2">
            <Button
              variant="success"
              className="d-flex ml-auto"
              onClick={() => setshowAddModal(true)}
            >
              Adicionar convênio
            </Button>
          </Col>
        </Row>
        <Row>
          {loading && <LoadingState />}
          {!loading && (
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th style={{ width: '25%' }}>Nome</th>
                    <th style={{ width: '30%' }}>Descrição</th>
                    <th style={{ width: '13%' }}>Categoria</th>
                    <th>Endereço</th>
                    <th style={{ width: '8%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {partners.map(partner => (
                    <tr key={partner.id}>
                      {/* <td>1</td> */}
                      <td>{partner.name}</td>
                      <td>{partner.description}</td>
                      <td>{partner.category.title}</td>
                      <td>
                        {`${partner.address}, ${partner.address2} - ${partner.city.nome}/${partner.city.uf} - ${partner.postal_code}`}
                      </td>
                      <td>
                        <div>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setPartnerId(partner.id);
                              setShowEditModal(true);
                            }}
                          >
                            <FiEdit size={20} color="#fff" />
                          </Button>
                          <Button
                            variant="danger"
                            className="ml-1"
                            onClick={() => {
                              setPartnerId(partner.id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <FiTrash2 size={20} color="#fff" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Pagination
              currentPage={currentPage}
              pageHandler={setCurrentPage}
              totalItems={totalPartners}
            />
          </Col>
        </Row>

        <AddPartnerModal
          handleShowModal={setshowAddModal}
          showModal={showAddModal}
          handlePartners={setPartners}
          handleLoading={setLoading}
        />

        <EditPartnerModal
          handleShowModal={setShowEditModal}
          partnerId={partnerId}
          showModal={showEditModal}
          handlePartners={setPartners}
          handleLoading={setLoading}
        />

        <DeletePartnerModal
          handleShowModal={setShowDeleteModal}
          partnerId={partnerId}
          showModal={showDeleteModal}
          handlePartners={setPartners}
          handleLoading={setLoading}
        />
      </Container>
    </BasePage>
  );
};

export default UnionsPartners;
