import React, { useCallback } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { FiSearch } from 'react-icons/fi';

import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

import { Container } from './styles';

interface ICity {
  id: string;
  name: string;
}

interface ICompany {
  id: string;
  nome_fantasia: string;
  cities: ICity[];
}

interface IProps {
  handleCompanies: (companies: ICompany[]) => void;
  handleLoading: (value: boolean) => void;
  currentPage: number;
  pageHandler: (value: number) => void;
}

const FilterBar: React.FC<IProps> = ({
  handleCompanies,
  handleLoading,
  currentPage,
  pageHandler,
}) => {
  const { addToast } = useToast();

  const filterCompanies = useCallback(
    async values => {
      try {
        handleLoading(true);
        const response = await api.get('/companies', {
          params: { ...values, page: currentPage },
        });

        handleCompanies(response.data);
        pageHandler(1);
        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar as empresas.',
        });
      }
    },
    [addToast, handleCompanies, pageHandler, handleLoading, currentPage],
  );

  return (
    <Container>
      <Formik
        initialValues={{
          nome_fantasia: '',
        }}
        onSubmit={filterCompanies}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="SearchByName">
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    onChange={handleChange('nome_fantasia')}
                    value={values.nome_fantasia}
                  />
                </Form.Group>
              </Col>
              <Col className="col-1 d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  <FiSearch color="#ffffff" size={20} />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
