import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import LoadingState from '../../../../components/LoadingState';

import { useToast } from '../../../../hooks/useToast';
import api from '../../../../services/api';

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IIBGECity {
  id: number;
  nome: string;
}

interface IIBGEState {
  id: number;
  uf: string;
}

interface ISegment {
  id: string;
  name: string;
  syndicate_id: string;
  is_admin: boolean;
  filters: {
    [key: string]: any;
  };
}

interface IProps {
  show: boolean;
  segment_id: string;
  handleShowModal: (value: boolean) => void;
  handleSegments: (segments: ISegment[]) => void;
  handleLoading: (value: boolean) => void;
  handleSelectedSegment: (id: string) => void;
}

const EditSegmentModal: React.FC<IProps> = ({
  show,
  segment_id,
  handleShowModal,
  handleSegments,
  handleLoading,
  handleSelectedSegment,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);

  const [segment, setSegment] = useState<ISegment>({} as ISegment);
  const [unions, setUnions] = useState<ISyndicate[]>([]);
  const [cities, setCities] = useState<IIBGECity[]>([]);
  const [originCities, setOriginCities] = useState<IIBGECity[]>([]);
  const [states, setStates] = useState<IIBGEState[]>([]);
  const [originStates, setOriginStates] = useState<IIBGEState[]>([]);
  const [showOriginAddress, setShowOriginAddress] = useState<
    boolean | undefined
  >(false);

  const handleClose = useCallback(() => {
    handleShowModal(false);
    setLoading(true);
    setSegment({} as ISegment);
    handleSelectedSegment('');
  }, [handleShowModal, handleSelectedSegment]);

  const handleEditSegment = useCallback(
    async values => {
      const { name, is_admin, ...rest } = values;
      try {
        handleLoading(true);
        handleClose();
        await api.patch(`/workers-segments/${segment_id}`, {
          name,
          is_admin,
          filters: rest,
        });

        const updatedSegments = await api.get('/workers-segments');
        handleSegments(updatedSegments.data);

        addToast({
          title: 'Sucesso',
          body: 'O segmento foi atualizado com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao atualizar o segmento.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [handleLoading, handleSegments, handleClose, addToast, segment_id],
  );

  const loadCitiesByUf = useCallback(async (uf: string) => {
    const loadedCities = await api.get(`ibge/cities/${uf}`);
    setCities(loadedCities.data);
  }, []);

  const loadOrigincitiesByuf = useCallback(async (uf: string) => {
    const loadedCities = await api.get(`ibge/cities/${uf}`);
    setOriginCities(loadedCities.data);
  }, []);

  const loadData = useCallback(async (id: string) => {
    if (id) {
      const segmentInfo = await api.get<ISegment>(`/workers-segments/${id}`);

      const loadedUnions = await api.get('/unions');
      const loadedStates = await api.get('/ibge/states');

      setUnions(loadedUnions.data);
      setStates(loadedStates.data);
      setOriginStates(loadedStates.data);
      setSegment(segmentInfo.data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData(segment_id);
  }, [loading, segment_id, loadData]);

  return (
    <>
      {loading && !segment && <LoadingState />}
      {!loading && segment && (
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Formik
            initialValues={{
              name: segment.name,
              is_admin: segment.is_admin,
              activity_profile: segment.filters?.activity_profile,
              is_peasant: segment.filters?.is_peasant,
              is_outsourced: segment.filters?.is_outsourced,
              worker_function: segment.filters?.worker_function,
              genre: segment.filters?.genre,
              situation: segment.filters?.situation,
              address: segment.filters?.address,
              city_id: segment.filters?.city_id,
              state: segment.filters?.state,
              postal_code: segment.filters?.postal_code,
              is_temporary_address: segment.filters?.is_temporary_address,
              origin_address: segment.filters?.origin_address,
              origin_city_id: segment.filters?.origin_city_id,
              origin_state: segment.filters?.origin_state,
              origin_postal_code: segment.filters?.origin_postal_code,
              salary_range: segment.filters?.salary_range,
              is_unionized: segment.filters?.is_unionized,
              syndicate_id: segment.filters?.syndicate_id,
              is_syndicate_approved: segment.filters?.is_syndicate_approved,
              is_discount_agreed: segment.filters?.is_discount_agreed,
              is_lgpd_agreed: segment.filters?.is_lgpd_agreed,
              is_privacy_terms_agreed: segment.filters?.is_privacy_terms_agreed,
            }}
            onSubmit={handleEditSegment}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Editar segmento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nome do segmento</Form.Label>
                        <Form.Control
                          placeholder="Escreva o nome do segmento"
                          onChange={handleChange('name')}
                          value={values.name}
                          onBlur={handleBlur('name')}
                          isValid={touched.name && !errors.name}
                          isInvalid={!!errors.name}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col className="col-4">
                      <fieldset>
                        <Form.Group controlId="is_admin">
                          <Form.Label>Segmento administrativo?</Form.Label>
                          <div key="inline-radio" className="mb-3">
                            <Form.Check
                              inline
                              label="Sim"
                              type="radio"
                              id="is_admin_TRUE"
                              name="is_admin"
                              value="true"
                              checked={!!values.is_admin === true}
                              onChange={() => {
                                setFieldValue('is_admin', 'true');
                              }}
                            />
                            <Form.Check
                              inline
                              label="Não"
                              type="radio"
                              id="is_admin_FALSE"
                              name="is_admin"
                              value="false"
                              checked={!!values.is_admin === false}
                              onChange={() => {
                                setFieldValue('is_admin', 'false');
                              }}
                            />
                          </div>
                        </Form.Group>
                      </fieldset>
                    </Col> */}
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group controlId="activity_profile">
                        <Form.Label>Perfil de atividade</Form.Label>
                        <Form.Control
                          onChange={event => {
                            setFieldValue(
                              'activity_profile',
                              event.target.value || '',
                            );
                          }}
                          value={values.activity_profile}
                          onBlur={handleBlur('activity_profile')}
                          isValid={
                            touched.activity_profile && !errors.activity_profile
                          }
                          isInvalid={!!errors.activity_profile}
                          as="select"
                          custom
                        >
                          <option value="">Indiferente</option>
                          <option value="industrial">Industrial</option>
                          <option value="rural">Rural</option>
                          <option value="market">Comércio</option>
                          <option value="public-service">
                            Serviço Público
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_outsourced">
                        <Form.Label>É terceirizado?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_outsourced',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_outsourced}
                          onBlur={handleBlur('is_outsourced')}
                          isValid={
                            touched.is_outsourced && !errors.is_outsourced
                          }
                          isInvalid={!!errors.is_outsourced}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_peasant">
                        <Form.Label>É safrista?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_peasant',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_peasant}
                          onBlur={handleBlur('is_peasant')}
                          isValid={touched.is_peasant && !errors.is_peasant}
                          isInvalid={!!errors.is_peasant}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="worker_function">
                        <Form.Label>Função do trabalhador</Form.Label>
                        <Form.Control
                          placeholder="Escreva a função do trabalhador"
                          onChange={handleChange('worker_function')}
                          value={values.worker_function}
                          onBlur={handleBlur('worker_function')}
                          isValid={
                            touched.worker_function && !errors.worker_function
                          }
                          isInvalid={!!errors.worker_function}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="genre">
                        <Form.Label>Gênero</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue('genre', event.target.value || '');
                          }}
                          value={values.genre}
                          onBlur={handleBlur('genre')}
                          isValid={touched.genre && !errors.genre}
                          isInvalid={!!errors.genre}
                        >
                          <option value="">Indiferente</option>
                          <option value="male">Masculino</option>
                          <option value="female">Feminino</option>
                          <option value="other">Outro</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="situation">
                        <Form.Label>Situação do trabalhador</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'situation',
                              event.target.value || '',
                            );
                          }}
                          value={values.situation}
                          onBlur={handleBlur('situation')}
                          isValid={touched.situation && !errors.situation}
                          isInvalid={!!errors.situation}
                        >
                          <option value="">Indiferente</option>
                          <option value="male">Temporário</option>
                          <option value="female">Permanente</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="address">
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                          placeholder="Digite o endereço do trabalhador"
                          onChange={handleChange('address')}
                          value={values.address}
                          onBlur={handleBlur('address')}
                          isValid={touched.address && !errors.address}
                          isInvalid={!!errors.address}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="state">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            loadCitiesByUf(event.target.value);
                            setFieldValue('state', event.target.value || '');
                          }}
                          value={values.state}
                          onBlur={handleBlur('state')}
                          isValid={touched.state && !errors.state}
                          isInvalid={!!errors.state}
                        >
                          <option value="">Indiferente</option>
                          {states.map(state => (
                            <option value={state.uf} key={state.id}>
                              {state.uf}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="city_id">
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue('city_id', event.target.value || '');
                          }}
                          value={values.city_id}
                          onBlur={handleBlur('city_id')}
                          isValid={touched.city_id && !errors.city_id}
                          isInvalid={!!errors.city_id}
                        >
                          <option value="">Indiferente</option>
                          {cities.map(city => (
                            <option value={city.id} key={city.id}>
                              {city.nome}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="postal_code">
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                          placeholder="Digite o CEP"
                          onChange={handleChange('postal_code')}
                          value={values.postal_code}
                          onBlur={handleBlur('postal_code')}
                          isValid={touched.postal_code && !errors.postal_code}
                          isInvalid={!!errors.postal_code}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <fieldset>
                        <Form.Group controlId="state">
                          <Form.Label>Endereço temporário?</Form.Label>
                          <div key="inline-radio" className="mb-3">
                            <Form.Check
                              inline
                              label="Indiferente"
                              type="radio"
                              id="is_temporary_address_INDIFERENTE"
                              name="is_temporary_address"
                              value={undefined}
                              checked={
                                values.is_temporary_address === '' || undefined
                              }
                              onChange={() => {
                                setShowOriginAddress(false);
                                setFieldValue(
                                  'is_temporary_address',
                                  undefined,
                                );
                              }}
                            />
                            <Form.Check
                              inline
                              label="Sim"
                              type="radio"
                              id="is_temporary_address_TRUE"
                              name="is_temporary_address"
                              value="true"
                              checked={values.is_temporary_address === 'true'}
                              onChange={() => {
                                setShowOriginAddress(true);
                                setFieldValue('is_temporary_address', 'true');
                              }}
                            />
                            <Form.Check
                              inline
                              label="Não"
                              type="radio"
                              id="is_temporary_address_FALSE"
                              name="is_temporary_address"
                              value="false"
                              checked={values.is_temporary_address === 'false'}
                              onChange={() => {
                                setShowOriginAddress(false);
                                setFieldValue('is_temporary_address', 'false');
                              }}
                            />
                          </div>
                        </Form.Group>
                      </fieldset>
                    </Col>
                  </Row>
                  {showOriginAddress && (
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="origin_address">
                          <Form.Label>Endereço de origem</Form.Label>
                          <Form.Control
                            placeholder="Digite o endereço do trabalhador"
                            onChange={handleChange('origin_address')}
                            value={values.origin_address}
                            onBlur={handleBlur('origin_address')}
                            isValid={
                              touched.origin_address && !errors.origin_address
                            }
                            isInvalid={!!errors.origin_address}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="origin_state">
                          <Form.Label>Estado de origem</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            onChange={event => {
                              loadOrigincitiesByuf(event.target.value);
                              setFieldValue(
                                'origin_state',
                                event.target.value || '',
                              );
                            }}
                            value={values.origin_state}
                            onBlur={handleBlur('origin_state')}
                            isValid={
                              touched.origin_state && !errors.origin_state
                            }
                            isInvalid={!!errors.origin_state}
                          >
                            <option value="">Indiferente</option>
                            {originStates.map(state => (
                              <option value={state.id} key={state.id}>
                                {state.uf}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="origin_city_id">
                          <Form.Label>Cidade de origem</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            onChange={event => {
                              setFieldValue(
                                'origin_city_id',
                                event.target.value || '',
                              );
                            }}
                            value={values.origin_city_id}
                            onBlur={handleBlur('origin_city_id')}
                            isValid={
                              touched.origin_city_id && !errors.origin_city_id
                            }
                            isInvalid={!!errors.origin_city_id}
                          >
                            <option value="">Indiferente</option>
                            {originCities.map(city => (
                              <option value={city.id} key={city.id}>
                                {city.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="origin_postal_code"
                        >
                          <Form.Label>CEP de origem</Form.Label>
                          <Form.Control
                            placeholder="Digite o CEP"
                            onChange={handleChange('origin_postal_code')}
                            value={values.origin_postal_code}
                            onBlur={handleBlur('origin_postal_code')}
                            isValid={
                              touched.origin_postal_code &&
                              !errors.origin_postal_code
                            }
                            isInvalid={!!errors.origin_postal_code}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group controlId="salary_range">
                        <Form.Label>Faixa salarial</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'salary_range',
                              event.target.value || '',
                            );
                          }}
                          value={values.salary_range}
                          onBlur={handleBlur('salary_range')}
                          isValid={touched.salary_range && !errors.salary_range}
                          isInvalid={!!errors.salary_range}
                        >
                          <option value="">Indiferente</option>
                          <option value="1">
                            Menos que 1 Salário Minímo (R$1.110,00)
                          </option>
                          <option value="2">
                            Até 1 Salário Minímo (R$1.100,00)
                          </option>
                          <option value="3">
                            De 1 a 2 Salários Minímo (R$1.100,00 a R$2.200,00)
                          </option>
                          <option value="4">
                            De 2 a 4 Salários Minímo (R$2.200,00 a R$4.400,00)
                          </option>
                          <option value="5">
                            Maior que 4 Salários Minímo (R$4.400,00)
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_unionized">
                        <Form.Label>É sindicalizado?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_unionized',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_unionized}
                          onBlur={handleBlur('is_unionized')}
                          isValid={touched.is_unionized && !errors.is_unionized}
                          isInvalid={!!errors.is_unionized}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="syndicate_id">
                        <Form.Label>Sindicato</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'syndicate_id',
                              event.target.value || '',
                            );
                          }}
                          value={values.syndicate_id}
                          onBlur={handleBlur('syndicate_id')}
                          isValid={touched.syndicate_id && !errors.syndicate_id}
                          isInvalid={!!errors.syndicate_id}
                        >
                          <option value="">Indiferente</option>
                          {unions.map(syndicate => (
                            <option value={syndicate.id} key={syndicate.id}>
                              {syndicate.nome_fantasia}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_syndicate_approved">
                        <Form.Label>Aprovado no sindicato?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_syndicate_approved',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_syndicate_approved}
                          onBlur={handleBlur('is_syndicate_approved')}
                          isValid={
                            touched.is_syndicate_approved &&
                            !errors.is_syndicate_approved
                          }
                          isInvalid={!!errors.is_syndicate_approved}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group controlId="is_discount_agreed">
                        <Form.Label>Aprovou desconto em folha?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_discount_agreed',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_discount_agreed}
                          onBlur={handleBlur('is_discount_agreed')}
                          isValid={
                            touched.is_discount_agreed &&
                            !errors.is_discount_agreed
                          }
                          isInvalid={!!errors.is_discount_agreed}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_privacy_terms_agreed">
                        <Form.Label>Aprovou termos de uso?</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_privacy_terms_agreed',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_privacy_terms_agreed}
                          onBlur={handleBlur('is_privacy_terms_agreed')}
                          isValid={
                            touched.is_privacy_terms_agreed &&
                            !errors.is_privacy_terms_agreed
                          }
                          isInvalid={!!errors.is_privacy_terms_agreed}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="is_syndicate_approved">
                        <Form.Label>
                          Aprovou compartilhamento de dados?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={event => {
                            setFieldValue(
                              'is_syndicate_approved',
                              event.target.value || '',
                            );
                          }}
                          value={values.is_lgpd_agreed}
                          onBlur={handleBlur('is_lgpd_agreed')}
                          isValid={
                            touched.is_lgpd_agreed && !errors.is_lgpd_agreed
                          }
                          isInvalid={!!errors.is_lgpd_agreed}
                        >
                          <option value="">Indiferente</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button variant="primary" type="submit">
                    Salvar
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default EditSegmentModal;
