import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Row, Col, Button, Container, Form } from 'react-bootstrap';
import { Formik } from 'formik';

import api from '../../../../services/api';

interface IWorker {
  id: string;
  cpf: string;
  first_name: string;
  last_name: string;
  rg?: string;
  activity_profile?: 'rural' | 'industrial' | 'market' | 'public-service';
  is_peasant?: boolean;
  is_outsourced?: boolean;
  worker_function?: string;
  genre: 'male' | 'female' | 'other';
  phone: string;
  situation?: 'temporary' | 'permanent';
  email?: string;
  address?: string;
  city_id?: number;
  state?: string;
  postal_code?: string;
  is_temporary_address?: boolean;
  origin_address?: string;
  origin_city_id?: number;
  origin_state?: string;
  origin_postal_code?: string;
  salary_range?: number;
  is_unionized?: boolean;
  is_syndicate_approved: boolean | null;
  syndicate_approved_at: Date;
  syndicate_id?: string;
  city: {
    nome: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
  is_discount_agreed: boolean;
  is_lgpd_agreed: boolean;
  is_privacy_terms_agreed: boolean;
  created_at: Date;
}

interface IBGECities {
  id: number;
  nome: string;
}

interface IBGEStates {
  id: number;
  nome: string;
  uf: string;
}

interface IProps {
  showModal: boolean;
  handleShowModal: (value: boolean) => void;
  worker: IWorker;
  handleWorker: (worker: IWorker) => void;
}

const EditWorkerModal: React.FC<IProps> = ({
  showModal,
  handleShowModal,
  worker,
  handleWorker,
}) => {
  // Data
  const [cities, setCities] = useState<IBGECities[]>([]);
  const [states, setStates] = useState<IBGEStates[]>([]);

  // Form States
  const [showOriginAdress, setShowOriginAdress] = useState(false);
  const [showIsPeasantField, setShowIsPeasantField] = useState(false);
  const [showIsOutsourced, setShowIsOutsourced] = useState(false);

  const handleUpdateWorker = useCallback(
    async values => {
      await api
        .put(`/workers/${worker.id}`, values)
        .then(response => handleWorker(response.data));
      handleShowModal(false);
    },
    [worker, handleShowModal, handleWorker],
  );

  useEffect(() => {
    const loadData = async () => {
      const loadedStates = await api.get('/ibge/states');
      const loadedCities = await api.get(`/ibge/cities/${worker.state}`);

      setStates(loadedStates.data);
      setCities(loadedCities.data);

      if (worker && worker.is_temporary_address === true) {
        setShowOriginAdress(true);
      }

      if (worker && worker.activity_profile === 'rural') {
        setShowIsPeasantField(true);
        setShowIsOutsourced(false);
      } else {
        setShowIsPeasantField(false);
        setShowIsOutsourced(true);
      }
    };

    loadData();
  }, [worker]);

  return (
    <Modal
      show={showModal}
      onHide={() => handleShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      dialogClassName="modal-90w"
    >
      <Formik
        initialValues={{
          cpf: worker ? worker.cpf : undefined,
          name: worker ? `${worker.first_name} ${worker.last_name}` : undefined,
          rg: worker ? worker.rg : undefined,
          activity_profile: worker ? worker.activity_profile : undefined,
          is_peasant: worker ? worker.is_peasant : 0,
          is_outsourced: worker ? worker.is_outsourced : 0,
          worker_function: worker ? worker.worker_function : undefined,
          genre: worker ? worker.genre : undefined,
          phone: worker ? worker.phone : undefined,
          situation: worker ? worker.situation : undefined,
          email: worker ? worker.email : undefined,
          address: worker ? worker.address : undefined,
          city_id: worker ? worker.city_id : undefined,
          state: worker ? worker.state : undefined,
          postal_code: worker ? worker.postal_code : undefined,
          is_temporary_address: worker ? worker.is_temporary_address : 0,
          origin_address: worker ? worker.origin_address : undefined,
          origin_city_id: worker ? worker.origin_city_id : undefined,
          origin_state: worker ? worker.origin_state : undefined,
          origin_postal_code: worker ? worker.origin_postal_code : undefined,
          salary_range: worker ? worker.salary_range : undefined,
          is_unionized: worker ? worker.is_unionized : 0,
          syndicate_id: worker ? worker.syndicate_id : undefined,
          password: undefined,
          password_confirmation: undefined,
        }}
        onSubmit={values => handleUpdateWorker(values)}
      >
        {({ values, handleSubmit, handleChange, handleBlur }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Editar trabalhador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="name">
                        <Form.Label>Nome Completo</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o nome completo"
                          value={values.name}
                          onChange={handleChange('name')}
                          onBlur={handleBlur('name')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="cpf">
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o CPF"
                          value={values.cpf}
                          onChange={handleChange('cpf')}
                          onBlur={handleBlur('cpf')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="rg">
                        <Form.Label>RG</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o RG"
                          value={values.rg}
                          onChange={handleChange('rg')}
                          onBlur={handleBlur('rg')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="genre">
                        <Form.Label>Gênero</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.genre}
                          onChange={handleChange('genre')}
                          onBlur={handleBlur('genre')}
                        >
                          <option value="0">Selecione uma opção</option>
                          <option value="male">Masculino</option>
                          <option value="female">Feminino</option>
                          <option value="other">Outro</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="phone">
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o telefone"
                          value={values.phone}
                          onChange={handleChange('phone')}
                          onBlur={handleBlur('phone')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="email">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Digite o e-mail"
                          value={values.email}
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group controlId="activity_profile">
                        <Form.Label>Perfil de atividade</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.activity_profile}
                          onChange={item => {
                            if (item.target.value === 'rural') {
                              setShowIsPeasantField(true);
                              setShowIsOutsourced(false);
                            }
                            if (item.target.value === 'industrial') {
                              setShowIsOutsourced(true);
                              setShowIsPeasantField(false);
                            }
                            handleChange('activity_profile')(item);
                          }}
                          onBlur={handleBlur('activity_profile')}
                        >
                          <option value="0">Selecione uma opção</option>
                          <option value="rural">Rural</option>
                          <option value="industrial">Industrial</option>
                          <option value="market">Comércio</option>
                          <option value="public-service">
                            Serviço Público
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col-2">
                      <Form.Group controlId="activity_profile_complementaryInfo">
                        {showIsPeasantField && (
                          <div>
                            <Form.Label>Safrista</Form.Label>
                            <div>
                              <Form.Check
                                inline
                                label="Sim"
                                type="radio"
                                name="is_peasant"
                                value="1"
                                checked={values.is_peasant === true}
                                onChange={handleChange('is_peasant')}
                              />
                              <Form.Check
                                inline
                                label="Não"
                                type="radio"
                                name="is_peasant"
                                value="0"
                                checked={values.is_peasant === false}
                                onChange={handleChange('is_peasant')}
                              />
                            </div>
                          </div>
                        )}
                        {showIsOutsourced && (
                          <div>
                            <Form.Label>Terceirizado</Form.Label>
                            <Form.Group controlId="formGroupEmail">
                              <Form.Check
                                inline
                                label="Sim"
                                type="radio"
                                name="is_outsourced"
                                value="1"
                                onChange={handleChange('is_outsourced')}
                                defaultChecked={worker.is_outsourced === true}
                              />
                              <Form.Check
                                inline
                                label="Não"
                                type="radio"
                                name="is_outsourced"
                                value="0"
                                onChange={handleChange('is_outsourced')}
                                defaultChecked={worker.is_outsourced === false}
                              />
                            </Form.Group>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="situation">
                        <Form.Label>Situação</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.situation}
                          onChange={handleChange('situation')}
                          onBlur={handleBlur('situation')}
                        >
                          <option value="0">Selecione uma opção</option>
                          <option value="temporary">Temporário</option>
                          <option value="permanent">Permanente</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="worker_function">
                        <Form.Label>Função do trabalhador</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite a função do trabalhador"
                          value={values.worker_function}
                          onChange={handleChange('worker_function')}
                          onBlur={handleBlur('worker_function')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="salary_range">
                        <Form.Label>Faixa salarial</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.salary_range}
                          onChange={handleChange('salary_range')}
                          onBlur={handleBlur('salary_range')}
                        >
                          <option>Selecione uma opção</option>
                          <option value="1">
                            Menos que 1 Salário Minímo (R$1.110,00)
                          </option>
                          <option value="2">
                            Até 1 Salário Minímo (R$1.100,00)
                          </option>
                          <option value="3">
                            De 1 a 2 Salários Minímo (R$1.100,00 a R$2.200,00)
                          </option>
                          <option value="4">
                            De 2 a 4 Salários Minímo (R$2.200,00 a R$4.400,00)
                          </option>
                          <option value="5">
                            Maior que 4 Salários Minímo (R$4.400,00)
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="col-2">
                      <Form.Group controlId="cep">
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o CEP"
                          value={values.postal_code}
                          onChange={handleChange('postal_code')}
                          onBlur={handleBlur('postal_code')}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group controlId="address">
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o Endereço"
                          value={values.address}
                          onChange={handleChange('address')}
                          onBlur={handleBlur('address')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="state">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.state}
                          onChange={handleChange('state')}
                          onBlur={handleBlur('state')}
                        >
                          <option value="0">Selecione uma opção</option>
                          {states.map(state => (
                            <option value={state.uf}>{state.nome}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="city">
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={values.city_id}
                          onChange={handleChange('city_id')}
                          onBlur={handleBlur('city_id')}
                        >
                          <option value="0">Selecione uma opção</option>
                          {cities.map(city => (
                            <option value={city.id}>{city.nome}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="is_temporary_address">
                        <Form.Label>Endereço temporário?</Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Sim"
                            type="radio"
                            id="is_temporary_address_TRUE"
                            name="is_temporary_address"
                            value="1"
                            checked={values.is_temporary_address === true}
                            onChange={() => setShowOriginAdress(true)}
                          />
                          <Form.Check
                            inline
                            label="Não"
                            type="radio"
                            id="is_temporary_address_FALSE"
                            name="is_temporary_address"
                            value="0"
                            checked={values.is_temporary_address === false}
                            onChange={() => setShowOriginAdress(false)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {showOriginAdress && (
                    <Row>
                      <Col className="col-2">
                        <Form.Group controlId="origin_cep">
                          <Form.Label>CEP de origem</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Digite o CEP"
                            value={values.origin_postal_code}
                            onChange={handleChange('origin_postal_code')}
                            onBlur={handleBlur('origin_postal_code')}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="col-4">
                        <Form.Group controlId="origin_address">
                          <Form.Label>Endereço de origem</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Digite o Endereço"
                            value={values.origin_address}
                            onChange={handleChange('origin_address')}
                            onBlur={handleBlur('origin_address')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="origin_state">
                          <Form.Label>Estado de origem</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.origin_state}
                            onChange={handleChange('origin_state')}
                            onBlur={handleBlur('origin_state')}
                          >
                            <option value="0">Selecione uma opção</option>
                            {states.map(state => (
                              <option value={state.uf}>{state.nome}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="origin_city">
                          <Form.Label>Cidade de origem</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.origin_city_id}
                            onChange={handleChange('origin_city_id')}
                            onBlur={handleBlur('origin_city_id')}
                          >
                            <option value="0">Selecione uma opção</option>
                            {cities.map(city => (
                              <option value={city.id}>{city.nome}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group controlId="password">
                        <Form.Label>Nova senha</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Digite a nova senha"
                          value={values.password}
                          onChange={handleChange('password')}
                          onBlur={handleBlur('password')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="password_confirmation">
                        <Form.Label>Confirmação de senha</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Digite a senha novamente"
                          value={values.password_confirmation}
                          onChange={handleChange('password_confirmation')}
                          onBlur={handleBlur('password_confirmation')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleSubmit()}>
                Salvar
              </Button>
              <Button
                variant="secondary"
                onClick={() => handleShowModal(false)}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default EditWorkerModal;
