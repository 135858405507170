import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { Formik } from 'formik';
import BasePage from '../../../../components/BasePage';

import api from '../../../../services/api';

import { ProfileContainer, Profile, ButtonContainer, Button } from './styles';
import { useAuth } from '../../../../hooks/auth';

interface ICityInfo {
  id: string;
  name: string;
  address?: string;
  address2?: string;
  city_id?: number;
  state?: string;
  postal_code?: string;
  company_id?: string;
  city: {
    nome: string;
    uf: string;
  };
}
interface ISyndicateInfo {
  id: string;
  nome_fantasia: string;
}
interface IIBGEIStates {
  uf: string;
  nome: string;
}

interface IIBGECities {
  id: number;
  nome: string;
  uf: string;
}

interface ICompanyInfo {
  id: string;
  nome_fantasia: string;
  razao_social?: string;
  cnpj?: string;
  syndicate_id?: string;
  syndicate: {
    nome_fantasia: string;
  };
  address?: string;
  address2?: string;
  city_id?: number;
  state?: string;
  postal_code?: string;
  cities?: ICityInfo[];
  city: {
    nome: string;
  };
}

const ShowCompany: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useAuth();

  const [loadingData, setLoadingData] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>();
  const [cityInfo, setCityInfo] = useState<ICityInfo>();
  const [citiesTable, setCitiesTable] = useState<ICityInfo[]>([]);

  const [cityID, setCityID] = useState<string>();
  const [syndicates, setSyndicate] = useState<ISyndicateInfo[]>([]);

  // Complementary info
  const [states, setStates] = useState<IIBGEIStates[]>([]);
  const [cities, setCities] = useState<IIBGECities[]>([]);

  // Company Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleShowEdit = useCallback(async () => {
    await api.get('/ibge/states').then(response => setStates(response.data));

    if (user.role === 'admin')
      await api.get('/unions').then(response => setSyndicate(response.data));

    setShowEditModal(true);
  }, []);

  const handleLoadCities = useCallback(async (uf: string) => {
    await api
      .get(`/ibge/cities/${uf}`)
      .then(response => setCities(response.data));
  }, []);

  const handleUpdateCompany = useCallback(
    async values => {
      await api
        .put(`/companies/${id}`, values)
        .then(response => setCompanyInfo(response.data));

      setShowEditModal(false);
    },
    [id],
  );

  const handleDeleteCompany = useCallback(async () => {
    await api.delete(`/companies/${id}`);
    history.push('/config/companies');
  }, [history, id]);

  // Cities Modal
  const [showDeleteCityModal, setShowDeleteCityModal] = useState(false);
  const [showAddCityModal, setShowAddCityModal] = useState(false);
  const [showEditCityModal, setShowEditCityModal] = useState(false);

  const handleCreateCity = useCallback(
    async values => {
      await api.post('/city', values);

      await api
        .get(`/companies/${id}`)
        .then(async response => setCitiesTable(response.data.cities));

      setShowAddCityModal(false);
    },
    [id],
  );

  const handleUpdateCity = useCallback(
    async values => {
      await api.put(`/city/${cityID}`, values);

      await api
        .get(`/companies/${id}`)
        .then(async response => setCitiesTable(response.data.cities));

      setShowEditCityModal(false);
    },
    [cityID, id],
  );

  const handleDeleteCity = useCallback(async () => {
    await api.delete(`/city/${cityID}`);

    await api.get(`/companies/${id}`).then(async response => {
      setCompanyInfo(response.data);

      setCitiesTable(response.data.cities);
    });

    setShowDeleteCityModal(false);
  }, [cityID, id]);

  const handleOpenModalAddCity = useCallback(async () => {
    await api.get('/ibge/states').then(response => setStates(response.data));
    setShowAddCityModal(true);
  }, []);

  const handleOpenModalEditCity = useCallback(
    async (cityId: string) => {
      setCityID(cityId);

      await api
        .get(`/city/${cityId}`)
        .then(response => setCityInfo(response.data));

      await api.get('/ibge/states').then(response => setStates(response.data));

      if (cityInfo) {
        await api
          .get(`/ibge/cities/${cityInfo.state}`)
          .then(response => setCities(response.data));
      }

      setShowEditCityModal(true);
    },
    [cityInfo],
  );

  const handleOpenModalDeleteCity = useCallback((cityId: string) => {
    setCityID(cityId);
    setShowDeleteCityModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowDeleteCityModal(false);
    setShowAddCityModal(false);
    setShowEditCityModal(false);
  }, []);

  useEffect(() => {
    async function loadData() {
      await api.get(`/companies/${id}`).then(async response => {
        setCompanyInfo(response.data);
        setCitiesTable(response.data.cities);
      });

      await api
        .get(`/ibge/cities/${companyInfo?.state}`)
        .then(response => setCities(response.data));

      setLoadingData(false);
    }

    if (loadingData) {
      loadData();
    }
  }, [loadingData, id, companyInfo]);

  return (
    <BasePage title="Empresa">
      <Container fluid>
        <Row className="mb-3">
          <Col className="d-flex">
            <Button
              variant="primary"
              className="ml-auto"
              onClick={() => handleShowEdit()}
            >
              Editar informações da empresa
            </Button>
            <Button
              variant="danger"
              className="ml-1"
              onClick={() => setShowDeleteModal(true)}
            >
              Remover empresa
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="col-3">
            <ProfileContainer>
              <Profile>
                <h2>{companyInfo?.nome_fantasia}</h2>
              </Profile>
              <span>
                {`Razão social: ${
                  companyInfo?.razao_social
                    ? companyInfo?.razao_social
                    : 'Não informado'
                }`}
              </span>
              <span>
                {`CNPJ: ${
                  companyInfo?.cnpj ? companyInfo?.cnpj : 'Não informado'
                }`}
              </span>
              <span>
                {`Sindicato: ${
                  companyInfo?.syndicate_id
                    ? companyInfo?.syndicate.nome_fantasia
                    : 'Não informado'
                }`}
              </span>
              <hr />
              <span>
                {`Endereço: ${
                  companyInfo?.address ? companyInfo?.address : 'Não informado'
                }`}
              </span>
              <span>
                {`Complemento: ${
                  companyInfo?.address2
                    ? companyInfo?.address2
                    : 'Não informado'
                }`}
              </span>
              <span>
                {`Cidade: ${
                  companyInfo?.city_id ? companyInfo.city.nome : 'Não informado'
                }`}
              </span>
              <span>
                {`Estado: ${
                  companyInfo?.state ? companyInfo?.state : 'Não informado'
                }`}
              </span>
              <span>
                {`CEP: ${
                  companyInfo?.postal_code
                    ? companyInfo?.postal_code
                    : 'Não informado'
                }`}
              </span>
            </ProfileContainer>
          </Col>
          <Col>
            <Row className="mb-3">
              <Col className="d-flex align-items-center">
                <h3 style={{ margin: 0, marginRight: 8 }}>Cidades</h3>
                <Button
                  variant="success"
                  className="btn-sm"
                  onClick={() => handleOpenModalAddCity()}
                >
                  Adicionar cidade
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Nome</th>
                      <th>Cidade</th>
                      <th>Estado</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {citiesTable.map(city => (
                      <tr>
                        <td>{city.name}</td>
                        <td>
                          {city.city ? city.city.nome : 'Cidade não informada.'}
                        </td>
                        <td>
                          {city.state ? city.state : 'Estado não informado.'}
                        </td>
                        <td>
                          <ButtonContainer>
                            <Button
                              variant="primary"
                              onClick={() => handleOpenModalEditCity(city.id)}
                            >
                              Editar
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleOpenModalDeleteCity(city.id)}
                            >
                              <FiTrash2 size={24} />
                            </Button>
                          </ButtonContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Delete company modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Você confirma que deseja excluir esta empresa? Essa ação não poderá
            ser desfeita e todos os dados serão perdidos.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteCompany();
            }}
          >
            Excluir
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit company modal */}
      <Modal
        show={showEditModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        dialogClassName="modal-70w"
      >
        <Formik
          initialValues={{
            nome_fantasia: companyInfo ? companyInfo?.nome_fantasia : undefined,
            razao_social: companyInfo ? companyInfo?.razao_social : undefined,
            cnpj: companyInfo ? companyInfo.cnpj : undefined,
            syndicate_id: companyInfo ? companyInfo.syndicate_id : undefined,
            address: companyInfo ? companyInfo.address : undefined,
            address2: companyInfo ? companyInfo.address2 : undefined,
            city_id: companyInfo ? companyInfo.city_id : undefined,
            state: companyInfo ? companyInfo.state : undefined,
            postal_code: companyInfo ? companyInfo.postal_code : undefined,
          }}
          validationSchema={null}
          onSubmit={values => handleUpdateCompany(values)}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar empresa</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={() => handleSubmit()}>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group controlId="first_name">
                          <Form.Label>Nome da empresa *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nome da empresa"
                            value={values.nome_fantasia}
                            onChange={handleChange('nome_fantasia')}
                            onBlur={handleBlur('nome_fantasia')}
                            isValid={
                              touched.nome_fantasia && !errors.nome_fantasia
                            }
                            isInvalid={!!errors.nome_fantasia}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="razao_social">
                          <Form.Label>Razão Social</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Razão social da empresa"
                            value={values.razao_social}
                            onChange={handleChange('razao_social')}
                            onBlur={handleBlur('razao_social')}
                            isValid={
                              touched.razao_social && !errors.razao_social
                            }
                            isInvalid={!!errors.razao_social}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="cnpj">
                          <Form.Label>CNPJ</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CNPJ do sindicato"
                            value={values.cnpj}
                            onChange={handleChange('cnpj')}
                            onBlur={handleBlur('cnpj')}
                            isValid={touched.cnpj && !errors.cnpj}
                            isInvalid={!!errors.cnpj}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {user.role === 'admin' && (
                      <Row>
                        <Col>
                          <Form.Group controlId="syndicate">
                            <Form.Label>Sindicato</Form.Label>
                            <Form.Control
                              as="select"
                              value={values.syndicate_id}
                              onChange={item => {
                                handleChange('syndicate_id')(item);
                              }}
                              onBlur={handleBlur('syndicate_id')}
                              isValid={
                                touched.syndicate_id && !errors.syndicate_id
                              }
                              isInvalid={!!errors.syndicate_id}
                            >
                              <option value="0">Selecione um Sindicato</option>
                              {syndicates.map(syndicate => (
                                <option key={syndicate.id} value={syndicate.id}>
                                  {syndicate.nome_fantasia}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                    <hr />
                    <Row>
                      <Col>
                        <Form.Group controlId="postal_code">
                          <Form.Label>CEP</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CEP do sindicato"
                            value={values.postal_code}
                            onChange={handleChange('postal_code')}
                            onBlur={handleBlur('postal_code')}
                            isValid={touched.postal_code && !errors.postal_code}
                            isInvalid={!!errors.postal_code}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="address">
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Endereço do sindicato"
                            value={values.address}
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="address2">
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Complemento do endereço"
                            value={values.address2}
                            onChange={handleChange('address2')}
                            onBlur={handleBlur('address2')}
                            isValid={touched.address2 && !errors.address2}
                            isInvalid={!!errors.address2}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="state">
                          <Form.Label>Estado</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.state}
                            onChange={item => {
                              handleChange('state')(item);
                              handleLoadCities(item.target.value);
                            }}
                            onBlur={handleBlur('state')}
                            isValid={touched.state && !errors.state}
                            isInvalid={!!errors.state}
                          >
                            <option value="0">Selecione um Estado</option>
                            {states.map(state => (
                              <option key={state.uf} value={state.uf}>
                                {state.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="city_id">
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.city_id}
                            onChange={handleChange('city_id')}
                            onBlur={handleBlur('city_id')}
                            isValid={touched.city_id && !errors.city_id}
                            isInvalid={!!errors.city_id}
                          >
                            <option value="0">Selecione uma cidade</option>
                            {cities.map(city => (
                              <option key={city.id} value={city.id}>
                                {city.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>

      {/* Delete City Modal */}
      <Modal
        show={showDeleteCityModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Você confirma que deseja excluir esta cidade? Essa ação não poderá
            ser desfeita e todos os dados serão perdidos.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteCity();
            }}
          >
            Excluir
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add City Modal */}
      <Modal
        show={showAddCityModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            name: undefined,
            address: undefined,
            address2: undefined,
            city_id: undefined,
            state: undefined,
            postal_code: undefined,
            company_id: id,
          }}
          onSubmit={values => handleCreateCity(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Adicionar cidade</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Form>
                    <Form.Group controlId="name">
                      <Form.Label>Nome*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite um nome para identificação"
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                      />
                    </Form.Group>

                    <hr />

                    <Form.Group controlId="cep">
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o CEP"
                        value={values.postal_code}
                        onChange={handleChange('postal_code')}
                        onBlur={handleBlur('postal_code')}
                      />
                    </Form.Group>

                    <Form.Group controlId="endereco">
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o Endereço"
                        value={values.address}
                        onChange={handleChange('address')}
                        onBlur={handleBlur('address')}
                      />
                    </Form.Group>

                    <Form.Group controlId="complemento">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o Complemento"
                        value={values.address2}
                        onChange={handleChange('address2')}
                        onBlur={handleBlur('address2')}
                      />
                    </Form.Group>

                    <Form.Group controlId="estado">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.state}
                        onChange={item => {
                          handleChange('state')(item);
                          handleLoadCities(item.target.value);
                        }}
                        onBlur={handleBlur('state')}
                      >
                        <option value="0">Selecione um Estado</option>
                        {states.map(state => (
                          <option key={state.uf} value={state.uf}>
                            {state.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="cidade">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.city_id}
                        onChange={handleChange('city_id')}
                        onBlur={handleBlur('city_id')}
                      >
                        <option value="0">Selecione uma cidade</option>
                        {cities.map(city => (
                          <option key={city.id} value={city.id}>
                            {city.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => handleSubmit()}>
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>

      {/* Edit City Modal */}
      <Modal
        show={showEditCityModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            name: cityInfo?.name,
            address: cityInfo?.address,
            address2: cityInfo?.address2,
            city_id: cityInfo?.city_id,
            state: cityInfo?.state,
            postal_code: cityInfo?.postal_code,
            company_id: id,
          }}
          onSubmit={values => handleUpdateCity(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar cidade</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Form>
                    <Form.Group controlId="name">
                      <Form.Label>Nome*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite um nome para identificação"
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                      />
                    </Form.Group>

                    <hr />

                    <Form.Group controlId="cep">
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o CEP"
                        value={values.postal_code}
                        onChange={handleChange('postal_code')}
                        onBlur={handleBlur('postal_code')}
                      />
                    </Form.Group>

                    <Form.Group controlId="endereco">
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o Endereço"
                        value={values.address}
                        onChange={handleChange('address')}
                        onBlur={handleBlur('address')}
                      />
                    </Form.Group>

                    <Form.Group controlId="complemento">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o Complemento"
                        value={values.address2}
                        onChange={handleChange('address2')}
                        onBlur={handleBlur('address2')}
                      />
                    </Form.Group>

                    <Form.Group controlId="estado">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.state}
                        onChange={item => {
                          handleChange('state')(item);
                          handleLoadCities(item.target.value);
                        }}
                        onBlur={handleBlur('state')}
                        isValid={touched.state && !errors.state}
                        isInvalid={!!errors.state}
                      >
                        <option value="0">Selecione um Estado</option>
                        {states.map(state => (
                          <option key={state.uf} value={state.uf}>
                            {state.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="cidade">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.city_id}
                        onChange={handleChange('city_id')}
                        onBlur={handleBlur('city_id')}
                      >
                        <option value="0">Selecione uma cidade</option>
                        {cities.map(city => (
                          <option key={city.id} value={city.id}>
                            {city.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => handleSubmit()}>
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </BasePage>
  );
};

export default ShowCompany;
