import styled from 'styled-components';
import { Button as ReactButton } from 'react-bootstrap';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled(ReactButton)`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 8px;
`;
