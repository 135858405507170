import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Form, Button, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import LoadingState from '../../components/LoadingState';

import { MainContainer, SignInContainer, LoadingContainer } from './styles';

import api from '../../services/api';

const SignIn: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef(null);
  const history = useHistory();

  const [successSend, setSucessSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(true);

  const formValidation = Yup.object().shape({
    email: Yup.string().required(
      'O e-mail é obrigatório para poder redifinir a sua senha.',
    ),
  });

  const handleForgotPassword = useCallback(
    async values => {
      try {
        setShowEmailForm(false);
        setLoading(true);
        await api.post('/users-password/forgot', values);
        setLoading(false);
        setSucessSend(true);
      } catch (error) {
        setSucessSend(false);
        setLoading(false);
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar localizar o seu e-mail.',
        });
      }
    },
    [addToast, setSucessSend, setLoading],
  );

  useEffect(() => {
    document.title = 'Esqueci minha senha - Viva Voz - Rede Suco de Laranja';
  }, []);

  return (
    <MainContainer>
      <Container>
        <Row className="justify-content-center">
          {loading && (
            <LoadingContainer>
              <LoadingState />
            </LoadingContainer>
          )}
          {showEmailForm && (
            <SignInContainer>
              <h2>Esqueci minha senha</h2>
              <p>
                Preencha os dados abaixo e solicite um e-mail para redefinir a
                sua senha:
              </p>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={handleForgotPassword}
                validationSchema={formValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Digite aqui o seu e-mail"
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Button variant="primary" type="submit">
                          Redefinir senha
                        </Button>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          variant="outline-primary"
                          onClick={() => history.push('/')}
                        >
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </SignInContainer>
          )}
          {successSend && (
            <SignInContainer>
              <h2>Sucesso!</h2>
              <p>Verifique o seu e-mail para poder redefinir a sua senha.</p>
            </SignInContainer>
          )}
        </Row>
      </Container>
    </MainContainer>
  );
};

export default SignIn;
