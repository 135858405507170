/* eslint-disable import/no-duplicates */
import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

import { DatePicker } from './styles';

registerLocale('ptBR', ptBR);

interface IAnswer {
  id: string;
  text: string;
}

interface IQuestion {
  id: string;
  text: string;
  type: string;
  answers: IAnswer[];
}

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
  questions: IQuestion[];
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  researchId: string;
  handleResearch: (research: IResearch) => void;
}

const AddResearchModal: React.FC<IProps> = ({
  show,
  handleShow,
  researchId,
  handleResearch,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [research, setResearch] = useState<IResearch>();

  const [startDate, setStartDate] = useState<any>(null);
  const [finishDate, setFinishDate] = useState<any>(null);

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleUpdateResearch = useCallback(
    async values => {
      try {
        const updatedResearch = await api.patch<IResearch>(
          `/research/${researchId}`,
          values,
        );

        addToast({
          title: 'Sucesso',
          body: 'A pesquisa foi editada com sucesso.',
        });

        handleResearch(updatedResearch.data);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar editar a pesquisa.',
        });
      } finally {
        handleClose();
      }
    },
    [addToast, handleClose, handleResearch, researchId],
  );

  useEffect(() => {
    const loadData = async () => {
      const loadedResearch = await api.get<IResearch>(
        `/research/${researchId}`,
      );
      setResearch(loadedResearch.data);
      setStartDate(new Date(loadedResearch.data.start_date));
      setFinishDate(new Date(loadedResearch.data.finish_date));

      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [researchId, loading]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading && !research && (
        <Row>
          <Col>
            <Spinner animation="border" role="status" variant="success">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {research && !loading && (
        <Formik
          initialValues={{
            title: research.title,
            description: research.description,
            start_date: research.start_date,
            finish_date: research.finish_date,
          }}
          onSubmit={handleUpdateResearch}
        >
          {({ handleSubmit, handleChange, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar pesquisa</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Título *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o título da pesquisa"
                        value={values.title}
                        onChange={handleChange('title')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleChange('description')}
                        value={values.description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Data de início</Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={date => {
                          setFieldValue('start_date', date);
                          setStartDate(date);
                        }}
                        locale="ptBR"
                        timeInputLabel="Início:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Data de fim</Form.Label>
                      <DatePicker
                        selected={finishDate}
                        onChange={date => {
                          setFieldValue('finish_date', date);
                          setFinishDate(date);
                        }}
                        locale="ptBR"
                        timeInputLabel="Fim:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Salvar
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddResearchModal;
