import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Form,
  Button,
  Col,
  Image,
  Spinner,
} from 'react-bootstrap';
import { FiLogIn } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

import { SignInContainer } from './styles';

interface ISignIn {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { addToast } = useToasts();
  const { signIn } = useAuth();
  const formRef = useRef(null);
  const [loading, setLoading] = useState<boolean>();

  const formValidation = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  });

  const handleSignIn = useCallback(
    async (data: ISignIn) => {
      setLoading(true);
      try {
        await signIn(data);
        setLoading(false);
        addToast('Login bem-sucedido!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      } catch (error) {
        setLoading(false);
        addToast('Erro no login. Verifique suas credenciais.', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    },
    [signIn, addToast],
  );

  useEffect(() => {
    document.title = 'Login - Viva Voz - Rede Suco de Laranja';
  }, []);
  
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Row
      className="vh-100 w-full flex items-center justify-center"
      style={{ overflow: 'hidden', background: '#f6f7fc' }}
    >
      <Col
        sm={10}
        md={6}
        className="d-flex align-items-center justify-content-center"
        style={{ overflow: 'hidden', background: '#f6f7fc' }}
      >
        <SignInContainer>
          <h1>Login</h1>
          <p>Entre com seus dados abaixo para poder acessar o sistema</p>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={values => handleSignIn(values)}
            validationSchema={formValidation}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Digite aqui o seu e-mail"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Digite aqui a sua senha"
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values.password}
                    isValid={touched.password && !errors.password}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col className="w-full">
                    {!loading && (
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        className="w-100"
                      >
                        <FiLogIn className="mr-2" />
                        Logar
                      </Button>
                    )}
                    {loading && (
                      <Button
                        variant="primary"
                        size="lg"
                        className="w-100"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </SignInContainer>
      </Col>

      {/* Parte Direita (Imagem) */}
      <Col
        sm={12}
        md={6}
        className="d-flex align-items-center w-2/3 justify-content-center overflow-hidden"
      >
        <div
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '80%',
            marginRight: '50px',
            borderRadius: '25px',
            overflow: 'hidden',
          }}
        />
      </Col>
    </Row>
  );
};

export default SignIn;
