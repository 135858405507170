import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import BasePage from '../../components/BasePage';
import Pagination from '../../components/Pagination';

import LoadingState from '../../components/LoadingState';
import FilterBar from '../../components/Workers/FilterBar';

import api from '../../services/api';

interface IWorker {
  id: string;
  first_name: string;
  last_name: string;
  cpf: string;
  phone: string;
  activity_profile: string;
}

const Workers: React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingWorkers, setLoadingWorkers] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [workers, setWorkers] = useState<IWorker[]>([]);
  const [totalWorkers, setTotalWorkers] = useState(0);

  const history = useHistory();

  useEffect(() => {
    document.title = 'Trabalhadores - Viva Voz - Rede Suco de Laranja';

    const loadWorkers = async () => {
      const loadedWorkers = await api.get(`/workers?page=${currentPage}`);
      setWorkers(loadedWorkers.data);
      setTotalWorkers(loadedWorkers.headers['x-total-workers']);
      setLoadingData(false);
      setLoadingWorkers(false);
    };
    loadWorkers();
  }, [loadingData, currentPage]);

  return (
    <BasePage title="Trabalhadores">
      {loadingData && <LoadingState />}
      {loadingData === false && (
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <FilterBar
                handleWorkers={setWorkers}
                pageHandler={setCurrentPage}
                currentPage={currentPage}
              />
            </Col>
          </Row>
          <Row className="d-flex">
            <Col>
              {loadingWorkers === false && (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th style={{ width: '15%' }}>CPF</th>
                        <th style={{ width: '15%' }}>Telefone</th>
                        <th style={{ width: '8%' }}>Categoria</th>
                        <th style={{ width: '10%' }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workers.map(worker => (
                        <tr key={worker.id}>
                          <td>{`${worker.first_name} ${worker.last_name}`}</td>
                          <td>{worker.cpf}</td>
                          <td>{worker.phone}</td>
                          <td>
                            {worker.activity_profile === 'industrial' &&
                              'Industrial'}
                            {worker.activity_profile === 'rural' && 'Rural'}
                            {worker.activity_profile === 'market' && 'Comércio'}
                            {worker.activity_profile === 'public-service' &&
                              'Serviço Público'}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => {
                                history.push(`/workers/${worker.id}`);
                              }}
                            >
                              <FiEye size={24} className="mr-1" />
                              Visualizar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    totalItems={totalWorkers}
                    currentPage={currentPage}
                    pageHandler={setCurrentPage}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </BasePage>
  );
};

export default Workers;
