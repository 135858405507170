export const whereGotCovidArray = [
  { label: 'Na empresa onde trabalho', value: 1 },
  { label: 'Através de alguém da família', value: 2 },
  { label: 'No transporte público ou da empresa', value: 3 },
  { label: 'Não sei', value: 4 },
];

export const covidRisksArray = [
  {
    label: 'Os ônibus da empresa estão sempre lotados e não há distanciamento.',
    value: 1,
  },
  {
    label:
      'Não há higienizações nas máquinas e equipamentos nos locais de trabalho',
    value: 2,
  },
  { label: 'Alojamentos com pouco espaço e muitas pessoas', value: 3 },
  {
    label:
      'Não estão sendo feitas medidas de temperatura quando chegamos ao trabalho',
    value: 4,
  },
  {
    label:
      'Não há teste antes de saímos do nosso estado para trabalhar nas fazendas',
    value: 5,
  },
  {
    label: 'Não há proteção acrílica entre máquinas para evitar contaminações',
    value: 6,
  },
  {
    label:
      'Trabalhadores do grupo de risco não são afastados ( idosos, grávidas e pessoas com outras doenças)',
    value: 7,
  },
  {
    label:
      'Empresa exige retorno ao trabalho antes de acabar a quarentena, mesmo quando temos atestado',
    value: 8,
  },
  {
    label:
      'Trabalhadores doentes não são isolados adequadamente quando estão doentes nos alojamentos coletivos',
    value: 9,
  },
  {
    label:
      'Não há estrutura de atendimento emergencial para quem passa mal no trabalho',
    value: 10,
  },
  {
    label:
      'Há sobrecarga por falta de pessoal. Empresa não repõe trabalhadores quando alguém se afasta por Covid',
    value: 11,
  },
  {
    label:
      'Faltam equipamentos de proteção para nos proteger (luvas e máscaras)',
    value: 12,
  },
  { label: 'Lojas lotadas com clientes, sem distanciamento', value: 13 },
];

export const gotCovidArray = [
  { label: 'Sim', value: 'sim' },
  { label: 'Não', value: 'nao' },
  { label: 'Talvez', value: 'talvez' },
];

export const HowManyGotCovidArray = [
  { label: 'Nenhum', value: 'nenhum' },
  { label: '1 - 10', value: '1-10' },
  { label: '10 - 50', value: '10-50' },
  { label: 'Mais de 50', value: '50+' },
];
