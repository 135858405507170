import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingState from '../../../components/LoadingState';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

import InvoiceData from './InvoiceData';
import PaymentData from './PaymentData';

interface ITransaction {
  id: string;
  invoice_id: string;
  worker_id: string;
  transaction_date: Date;
  value: number | string;
  transaction_method: string;
}

interface IInvoice {
  id: string;
  order: number;
  worker_id: string;
  syndicate_id: string;
  value: number;
  is_paid: boolean;
  invoice_date: Date;
  due_date: Date;
  subscription_id: string;
  transactions: ITransaction[];
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  selectedInvoice: string;
  handleLoading: (value: boolean) => void;
  handleRefreshInvoices: () => Promise<void>;
}

const EditInvoiceModal: React.FC<IProps> = ({
  show,
  handleShow,
  selectedInvoice,
  handleLoading,
  handleRefreshInvoices,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<IInvoice>();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleUpdateInvoice = useCallback(
    async ({ value: invoice_value, ...rest }) => {
      try {
        const value: number = parseFloat(
          invoice_value.replace('R$ ', '').replace('.', ''),
        );

        await api.patch(`/financial/invoices/${selectedInvoice}`, {
          ...rest,
          value,
        });

        handleClose();
        handleLoading(true);

        addToast({
          title: 'Sucesso',
          body: 'A fatura foi atualizada com sucesso.',
        });

        await handleRefreshInvoices();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar atualizara a fatura. Tente novamente.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [
      addToast,
      handleLoading,
      handleClose,
      handleRefreshInvoices,
      selectedInvoice,
    ],
  );

  const loadInvoice = useCallback(async () => {
    const loadedInvoice = await api.get(
      `/financial/invoices/${selectedInvoice}`,
    );
    setInvoice(loadedInvoice.data);
  }, [selectedInvoice]);

  const cleanup = useCallback((mounted: boolean) => {
    if (!mounted) {
      setInvoice({} as IInvoice);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (mounted) {
        await loadInvoice();
        setLoading(false);
      }
    };

    if (mounted && selectedInvoice) {
      loadData();
    }

    return () => {
      mounted = false;
      cleanup(mounted);
    };
  }, [selectedInvoice, loading, loadInvoice, cleanup]);

  const validationSchema = Yup.object().shape({
    syndicate_id: Yup.string().required(),
    worker_id: Yup.string().required(),
    value: Yup.string().required(),
    invoice_date: Yup.date().required(),
    due_date: Yup.date().required(),
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      {loading && !invoice && <LoadingState />}
      {!loading && invoice && (
        <Formik
          initialValues={{
            syndicate_id: invoice.syndicate_id,
            worker_id: invoice.worker_id,
            value: invoice.value,
            invoice_date: new Date(invoice.invoice_date),
            due_date: new Date(invoice.due_date),
          }}
          onSubmit={handleUpdateInvoice}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar fatura</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs defaultActiveKey="invoice-data" id="tab" className="mb-3">
                  <Tab eventKey="invoice-data" title="Dados da fatura">
                    <InvoiceData invoice={invoice} />
                  </Tab>
                  <Tab eventKey="payment-data" title="Adicionar pagamento">
                    <PaymentData
                      invoice={invoice}
                      handleLoading={setLoading}
                      handleRefreshInvoices={handleRefreshInvoices}
                      refreshInvoice={loadInvoice}
                    />
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {invoice.is_paid ? 'Fechar' : 'Cancelar'}
                </Button>
                {invoice.is_paid === false && (
                  <Button variant="primary" type="submit">
                    Salvar
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditInvoiceModal;
