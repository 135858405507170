import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import BasePage from '../../components/BasePage';

import { Link } from './styles';

const Config: React.FC = () => {
  const { user } = useAuth();

  useEffect(() => {
    document.title = 'Configurações - Viva Voz - Rede Suco de Laranja';
  }, []);

  return (
    <BasePage title="Configurações">
      <Container fluid>
        <Row>
          {user.role === 'admin' && (
            <Col className="col-4 mb-3">
              <Link to="/settings/unions">Sindicatos</Link>
            </Col>
          )}

          <Col className="col-4 mb-3">
            <Link to="/settings/companies">Empresas</Link>
          </Col>

          <Col className="col-4 mb-3">
            <Link to="/settings/users">Usuários</Link>
          </Col>
          {user.role === 'admin' && (
            <Col className="col-4 mb-3">
              <Link to="/settings/news/categories">Categorias de Notícias</Link>
            </Col>
          )}
          {user.role === 'admin' && (
            <Col className="col-4">
              <Link to="/settings/unions/partners-categories">
                Categorias de Convênios
              </Link>
            </Col>
          )}
          {user.role === 'admin' && (
            <Col className="col-4">
              <Link to="/settings/workers-segments">
                Segmentos de Trabalhadores
              </Link>
            </Col>
          )}
          {user.role === 'admin' && (
            <Col className="col-4">
              <Link to="/settings/foward-complaint">
                Redirecionamento de denúncias
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    </BasePage>
  );
};

export default Config;
