import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';
import Pagination from '../../components/Pagination';
import CreateNotificationModal from './CreateNotificationModal';

import api from '../../services/api';

interface INotification {
  id: string;
  app_id: string;
  converted: number;
  data: any;
  priority: any;
  successful: number;
  received: any;
  canceled: number;
  errored: number;
  failed: number;
  remaining: number;
  content_available: any;
  headings: {
    [key: string]: string;
  };
  contents: {
    [key: string]: string;
  };
  included_segments: string[];
  excluded_segments: string[];
  queued_at: number;
  competed_at: number;
  filters: any;
  url: string;
  web_url: string;
  app_url: string;
  name: string;
}

interface IRequest {
  total_count: number;
  notifications: INotification[];
}

const Notifications: React.FC = () => {
  const [loading, setLoading] = useState(true);

  // Modal
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const renderContent = (item: any) => {
    let data = '';
    Object.entries(item).forEach(([, value]: any) => {
      data = value;
    });

    return data;
  };

  useEffect(() => {
    document.title = 'Notificações - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const response = await api.get<IRequest>('/mobile-notifications', {
        params: { page },
      });
      const { total_count, notifications: loadedNotifications } = response.data;

      setNotifications(loadedNotifications);
      setTotalItems(total_count);

      setLoading(false);
    };

    loadData();
  }, [page]);

  return (
    <BasePage title="Notificações">
      {loading && <LoadingState />}
      {!loading && (
        <>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="success"
                onClick={() => setShowCreateModal(true)}
              >
                Enviar notificação
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Título</th>
                    <th>Conteúdo</th>
                    <th>Bem sucedido</th>
                    <th>Com erro</th>
                    <th>Com falha</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications?.map(notification => (
                    <tr key={notification.id}>
                      <td>
                        {notification.name ? notification.name : 'Não definido'}
                      </td>
                      <td>{renderContent(notification.headings)}</td>
                      <td>{renderContent(notification.contents)}</td>
                      <td>{notification.successful}</td>
                      <td>{notification.errored}</td>
                      <td>{notification.failed}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={page}
                pageHandler={setPage}
                totalItems={totalItems}
              />
            </Col>
          </Row>
        </>
      )}
      <CreateNotificationModal
        show={showCreateModal}
        handleShow={setShowCreateModal}
        handleLoading={setLoading}
        handleNotifications={setNotifications}
        handleTotalItems={setTotalItems}
      />
    </BasePage>
  );
};

export default Notifications;
