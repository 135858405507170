import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

interface IProps {
  workerId: string;
  showModal: boolean;
  handleShowModal: (value: boolean) => void;
}

const DeleteWorkerModal: React.FC<IProps> = ({
  workerId,
  showModal,
  handleShowModal,
}) => {
  const history = useHistory();

  const handleDeleteWorker = useCallback(async () => {
    await api.delete(`/workers/${workerId}`);
    history.push('/workers');
  }, [workerId, history]);

  return (
    <Modal
      show={showModal}
      onHide={() => handleShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Excluir trabalhador</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você realmente deseja remover este trabalhador? Essa ação não poderá ser
        desfeita e todos os dados serão perdidos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleDeleteWorker()}>
          Excluir
        </Button>
        <Button variant="secondary" onClick={() => handleShowModal(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteWorkerModal;
