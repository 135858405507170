import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';

import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Leaflet from 'leaflet';

import { useAuth } from '../../../../hooks/auth';

import { Container, Sidebar, LogsContainer, LogContainer } from './styles';

import mapMarker from '../../../../assets/marker.png';

const mapPinIcon = Leaflet.icon({
  iconUrl: mapMarker,
  iconSize: [25, 40],
  iconAnchor: [40 / 2, 40 + 9],
  className: 'map-marker',
});

interface IMessages {
  id: string;
  key_from_server: 'income' | 'outcome';
  message_type: 'text' | 'audio' | 'document' | 'image';
  data: string;
  media_url: string;
  media_server_url: string;
  media_mime_type: string;
  media_size: number;
  media_server_size: number;
  latitude: number;
  longitude: number;
  created_at: Date;
}

interface ILog {
  id: string;
  type: string;
  module: string;
  message: string;
  feedback: string;
  created_at: Date;
}

interface IComplaint {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  messages: IMessages[];
}

interface ICoordinates {
  lat: number;
  lng: number;
}

interface IProps {
  complaint: IComplaint | undefined;
  messages: IMessages[];
  logs: ILog[] | undefined;
}

const RightSidebar: React.FC<IProps> = ({ complaint, messages, logs }) => {
  const { user } = useAuth();

  const [mapCoordinates, setMapCoordinates] = useState<ICoordinates>();

  // Map
  useEffect(() => {
    const loadMapInfo = async () => {
      const incomeMessages = await Promise.all(
        messages.filter(message => message.key_from_server === 'income'),
      );

      const filteredMessage = incomeMessages[incomeMessages.length - 1];

      if (filteredMessage) {
        setMapCoordinates({
          lat: filteredMessage.latitude,
          lng: filteredMessage.longitude,
        });
      }
    };

    loadMapInfo();
  }, [messages]);

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Sidebar>
            <h3>Localização do trabalhador</h3>
            {mapCoordinates ? (
              <MapContainer
                center={mapCoordinates}
                zoom={13}
                scrollWheelZoom
                style={{ height: 250 }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={mapCoordinates} icon={mapPinIcon} />
              </MapContainer>
            ) : (
              <p>O trabalhador não compartilhou os dados geográficos.</p>
            )}
          </Sidebar>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Sidebar>
            <h3>Histórico</h3>
            <LogsContainer>
              {logs?.map(log => (
                <LogContainer>
                  <div className="message">{log.message}</div>
                  {log.type === 'feedback' && user.role === 'admin' ? (
                    <div className="feedack">
                      <span>Feedback: </span>
                      {log.feedback}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="timestamp">
                    {format(new Date(log.created_at), "dd/MM/yyyy 'às' HH:mm")}
                  </div>
                </LogContainer>
              ))}
            </LogsContainer>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
};

export default RightSidebar;
