/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Table, Button, Badge } from 'react-bootstrap';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import BasePage from '../../../components/BasePage';
import FilterBar from '../../../components/Financial/Invoices/FilterBar';
import LoadingState from '../../../components/LoadingState';
import { useToast } from '../../../hooks/useToast';
import Pagination from '../../../components/Pagination';

import api from '../../../services/api';

import AddSubscriptionModal from '../AddSubscriptionModal';
import AddInvoiceModal from '../AddInvoiceModal';
import DeleteInvoiceModal from '../DeleteInvoiceModal';
import EditInvoiceModal from '../EditInvoiceModal';

interface IInvoice {
  id: string;
  order: number;
  worker_id: string;
  syndicate_id: string;
  value: number;
  is_paid: boolean;
  invoice_date: Date;
  due_date: Date;
  worker: {
    id: string;
    first_name: string;
    last_name: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

interface ISelectedInvoice {
  action: 'edit' | 'remove';
  invoiceId: string;
}

const Financial: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
  const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ISelectedInvoice>(
    {} as ISelectedInvoice,
  );

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [totalInvoices, setTotalInvoices] = useState(0);

  const listInvoices = useCallback(async () => {
    try {
      const loadedInvoices = await api.get('/financial/invoices', {
        params: { page: currentPage },
      });
      setInvoices(loadedInvoices.data);
      setTotalInvoices(loadedInvoices.headers['x-total-invoices']);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu um erro ao carregar as faturas. Tente novamente.',
      });
    }
  }, [addToast, currentPage]);

  const parseCurrency = useCallback((value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }, []);

  useEffect(() => {
    if (selectedInvoice.action === 'edit') {
      setShowEditInvoiceModal(true);
    }

    if (selectedInvoice.action === 'remove') {
      setShowDeleteInvoiceModal(true);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    document.title = `Financeiro - ${process.env.REACT_APP_NAME}`;

    let mounted = true;
    const loadData = async () => {
      if (mounted) {
        await listInvoices();
        setLoading(false);
      }
    };

    if (mounted && loading) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [loading, listInvoices]);

  return (
    <BasePage title="Financeiro - Faturas">
      {loading && <LoadingState />}
      <Container fluid>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            <Button
              variant="success"
              className="mr-1"
              onClick={() => setShowSubscriptionModal(true)}
            >
              Adicionar contribuição recorrente
            </Button>
            <Button
              variant="primary"
              className="mr-1"
              onClick={() => setShowAddInvoiceModal(true)}
            >
              Adicionar fatura
            </Button>
            <Button
              variant="info"
              onClick={() => history.push('/financial/subscriptions')}
            >
              Gerenciar contribuições recorrentes
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <FilterBar handleInvoices={setInvoices} handleLoading={setLoading} />
        </Row>
        {!loading && (
          <>
            <Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Faturado para</th>
                    <th>Valor</th>
                    <th style={{ width: '7%' }}>Data da fatura</th>
                    <th style={{ width: '7%' }}>Data de vencimento</th>
                    <th>Sindicato</th>
                    <th style={{ width: '5%' }}>Status</th>
                    <th style={{ width: '10%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices?.map(invoice => (
                    <tr key={invoice.id}>
                      <td style={{ verticalAlign: 'middle' }}>
                        {invoice.order}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {`${invoice.worker.first_name} ${invoice.worker.last_name}`}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {parseCurrency(invoice.value)}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {format(new Date(invoice.invoice_date), 'dd/MM/yyy')}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {format(new Date(invoice.due_date), 'dd/MM/yyyy')}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {invoice?.syndicate?.nome_fantasia}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {invoice.is_paid && (
                          <Badge variant="success">Pago</Badge>
                        )}
                        {invoice.is_paid === false && (
                          <Badge variant="danger">Não pago</Badge>
                        )}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Button
                          variant="primary"
                          className="mr-1"
                          onClick={() => {
                            setSelectedInvoice({
                              action: 'edit',
                              invoiceId: invoice.id,
                            });
                          }}
                        >
                          <FiEdit color="#ffffff" size={20} />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => {
                            setSelectedInvoice({
                              action: 'remove',
                              invoiceId: invoice.id,
                            });
                          }}
                        >
                          <FiTrash2 color="#ffffff" size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col>
                <Pagination
                  currentPage={currentPage}
                  pageHandler={setCurrentPage}
                  totalItems={totalInvoices}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>

      <AddSubscriptionModal
        show={showSubscriptionModal}
        handleShow={setShowSubscriptionModal}
        updateInvoices={listInvoices}
        handleLoading={setLoading}
      />

      <AddInvoiceModal
        show={showAddInvoiceModal}
        handleShow={setShowAddInvoiceModal}
        handleLoading={setLoading}
        handleRefreshInvoices={listInvoices}
      />

      <EditInvoiceModal
        show={showEditInvoiceModal}
        handleShow={setShowEditInvoiceModal}
        selectedInvoice={selectedInvoice.invoiceId}
        handleLoading={setLoading}
        handleRefreshInvoices={listInvoices}
      />

      <DeleteInvoiceModal
        show={showDeleteInvoiceModal}
        handleShow={setShowDeleteInvoiceModal}
        handleRefreshInvoices={listInvoices}
        selectedInvoice={selectedInvoice.invoiceId}
        handleLoading={setLoading}
      />
    </BasePage>
  );
};

export default Financial;
