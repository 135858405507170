import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';

import statesArray from './states';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/useToast';

interface ISelect {
  value: string | number;
  label: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface ICity {
  id: string;
  name: string;
}

interface ICompany {
  id: string;
  nome_fantasia: string;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  refreshFowards: () => Promise<void>;
}

const AddFowardModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  refreshFowards,
}) => {
  const { addToast } = useToast();

  const [states, setStates] = useState<ISelect[]>([]);
  const [unions, setUnions] = useState<ISelect[]>([]);
  const [unionsCities, setUnionsCities] = useState<ISelect[]>();
  const [companies, setCompanies] = useState<ISelect[]>();
  const [fowardUnions, setFowardUnions] = useState<ISelect[]>();

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleCreateFowardComplaint = useCallback(
    async values => {
      try {
        handleLoading(true);
        handleClose();
        const response = await api.post('/complaints/foward', values);

        if (response.status === 200) {
          addToast({
            title: 'Sucesso',
            body: 'Redirecionamento criado com sucesso.',
          });

          await refreshFowards();
        }
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar criar um novo redirecionamento de denúncias.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [handleLoading, refreshFowards, addToast, handleClose],
  );

  const typeSelectOptions = [
    { value: 'industrial', label: 'Industrial' },
    { value: 'rural', label: 'Rural' },
    { value: 'market', label: 'Comércio' },
    { value: 'public-service', label: 'Serviço Público' },
  ];

  const handleUpdateCities = useCallback(async (company: string | number) => {
    try {
      const loadedCities = await api.get<ICity[]>(
        `/companies/list-cities/${company}`,
      );
      setUnionsCities(
        loadedCities.data.map(city => {
          return {
            label: city.name,
            value: city.id,
          };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const loadedUnions = await api.get<ISyndicate[]>('/unions');
      const loadedCompanies = await api.get<ICompany[]>('/companies');

      if (mounted) {
        setStates(
          statesArray.map(state => {
            return {
              label: state.label,
              value: state.value,
            };
          }),
        );

        const parsedUnions = loadedUnions.data.map(syndicate => {
          return {
            label: syndicate.nome_fantasia,
            value: syndicate.id,
          };
        });

        setFowardUnions(parsedUnions);

        setUnions([
          { label: 'Não sabe o sindicato', value: 0 },
          ...parsedUnions,
        ]);

        setCompanies(
          loadedCompanies.data.map(company => {
            return {
              label: company.nome_fantasia,
              value: company.id,
            };
          }),
        );
      }
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório').min(3),
    state: Yup.string(),
    type: Yup.string(),
    syndicate_id: Yup.string(),
    company_id: Yup.string(),
    city_id: Yup.string(),
    foward_syndicate_id: Yup.string().required('O campo é obrigatório'),
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Formik
        initialValues={{
          name: '',
          state: '',
          type: '',
          syndicate_id: '',
          company_id: '',
          city_id: '',
          foward_syndicate_id: '',
        }}
        onSubmit={handleCreateFowardComplaint}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar redirecionamento de denúncias</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um título"
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                      onChange={handleChange}
                      name="name"
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="name" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Selecione as condições para redirecionamento</h5>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="formState">
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.state && !errors.state}
                      isInvalid={!!errors.state}
                    >
                      <Select
                        options={states}
                        placeholder="Selecione um estado"
                        name="state"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue('state', event?.value);
                          }
                          return setFieldValue('state', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="state" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formType">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.type && !errors.type}
                      isInvalid={!!errors.type}
                    >
                      <Select
                        options={typeSelectOptions}
                        placeholder="Selecione um tipo"
                        name="type"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue('type', event?.value);
                          }
                          return setFieldValue('type', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="type" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formUnion">
                    <Form.Label>Sindicato</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.syndicate_id && !errors.syndicate_id}
                      isInvalid={!!errors.syndicate_id}
                    >
                      <Select
                        options={unions}
                        placeholder="Selecione um sindicato"
                        name="syndicate_id"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue('syndicate_id', event?.value);
                          }
                          return setFieldValue('syndicate_id', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="syndicate_id" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.company_id && !errors.company_id}
                      isInvalid={!!errors.company_id}
                    >
                      <Select
                        options={companies}
                        placeholder="Selecione uma empresa"
                        name="company_id"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            handleUpdateCities(event?.value);
                            return setFieldValue('company_id', event?.value);
                          }
                          return setFieldValue('company_id', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="company_id" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.city_id && !errors.city_id}
                      isInvalid={!!errors.city_id}
                    >
                      <Select
                        options={unionsCities}
                        placeholder="Selecione uma cidade"
                        name="city_id"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue('city_id', event?.value);
                          }
                          return setFieldValue('city_id', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="city_id" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Redirecionar para</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Sindicato</Form.Label>
                    <Form.Control
                      as="div"
                      custom
                      isValid={
                        touched.foward_syndicate_id &&
                        !errors.foward_syndicate_id
                      }
                      isInvalid={!!errors.foward_syndicate_id}
                    >
                      <Select
                        options={fowardUnions}
                        placeholder="Selecione o sindicato"
                        name="foward_syndicate_id"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue(
                              'foward_syndicate_id',
                              event?.value,
                            );
                          }
                          return setFieldValue('foward_syndicate_id', '');
                        }}
                        isClearable
                      />
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name="foward_syndicate_id" />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddFowardModal;
