import React, { useCallback } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { Formik } from 'formik';

import { useToast } from '../../../../hooks/useToast';
import api from '../../../../services/api';

import { Container } from './styles';

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  syndicate_id?: string;
}

interface IProps {
  handleUsers: (users: IUser[]) => void;
  handleLoading: (value: boolean) => void;
  currentPage: number;
  pageHandler: (value: number) => void;
}

const FilterBar: React.FC<IProps> = ({
  handleUsers,
  handleLoading,
  currentPage,
  pageHandler,
}) => {
  const { addToast } = useToast();

  const filterUsers = useCallback(
    async values => {
      try {
        handleLoading(true);

        const response = await api.get('/users', {
          params: { ...values, page: currentPage },
        });

        handleUsers(response.data);
        pageHandler(1);

        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar os usuários.',
        });
      }
    },
    [addToast, handleUsers, handleLoading, currentPage, pageHandler],
  );

  return (
    <Container>
      <Formik
        initialValues={{
          name: '',
        }}
        onSubmit={filterUsers}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="SearchByName">
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    onChange={handleChange('name')}
                    value={values.name}
                  />
                </Form.Group>
              </Col>
              <Col className="col-1 d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  <FiSearch color="#ffffff" size={20} />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
