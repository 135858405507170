/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Table, Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import BasePage from '../../../../components/BasePage';
import LoadingState from '../../../../components/LoadingState';
import { useToast } from '../../../../hooks/useToast';

import { Aside } from './styles';

import icons from './icons';

import api from '../../../../services/api';

interface ICategory {
  id: string;
  title: string;
  icon: string;
  description: string;
}

const PartnersCategories: React.FC = () => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>();
  const [loadedCategoryId, setLoadedCategoryId] = useState('');

  const [categoryInfo, setCategoryInfo] = useState<ICategory>();

  // Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleClose = useCallback(() => {
    setShowDeleteModal(false);
    setShowUpdateModal(false);
  }, []);

  const handleUpdateCategory = useCallback(
    async values => {
      const { id, ...rest } = values;

      console.log(values);

      try {
        setLoadingCategories(true);
        await api.patch<ICategory>(`/unions-partners/categories/${id}`, {
          ...rest,
        });
        const updatedCategories = await api.get('/unions-partners/categories');
        setCategories(updatedCategories.data);
        handleClose();
        setLoadingCategories(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar um novo sindicato.',
        });
      }
    },
    [addToast, handleClose],
  );

  const handleOpenUpdateCategory = useCallback(async (categoryId: string) => {
    const loadedCategory = await api.get(
      `/unions-partners/categories/${categoryId}`,
    );
    setCategoryInfo(loadedCategory.data);
    setShowUpdateModal(true);
  }, []);

  const handleDeleteCategory = useCallback(
    async categoryId => {
      try {
        setLoadingCategories(true);
        await api.delete(`/unions-partners/categories/${categoryId}`);
        const updatedCategories = await api.get('/unions-partners/categories');
        setCategories(updatedCategories.data);
        handleClose();
        setLoadingCategories(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar um novo sindicato.',
        });
      }
    },
    [addToast, handleClose],
  );

  const handleCreateCategory = useCallback(
    async values => {
      try {
        setLoadingCategories(true);

        await api.post('/unions-partners/categories', values);
        const updatedCategories = await api.get('/unions-partners/categories');

        setCategories(updatedCategories.data);

        setLoadingCategories(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar adicionar um novo sindicato.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    const loadData = async () => {
      const loadedCategories = await api.get<ICategory[]>(
        '/unions-partners/categories',
      );
      setCategories(loadedCategories.data);
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <BasePage title="Categorias de Convênios">
      {loading && <LoadingState />}
      {!loading && (
        <Row>
          <Col className="col-5">
            <Aside>
              <Row className="mb-3">
                <Col>
                  <h4>Adicionar nova categoria</h4>
                  <hr />
                </Col>
              </Row>
              <Formik
                initialValues={{
                  title: '',
                  icon: '',
                  description: '',
                }}
                onSubmit={(values, actions) => {
                  handleCreateCategory(values);
                  actions.resetForm();
                }}
              >
                {({ handleSubmit, values, errors, handleChange, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupName">
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Digite o nome da categoria.."
                            onChange={handleChange('title')}
                            value={values.title}
                            isValid={touched.title && !errors.title}
                            isInvalid={!!errors.title}
                          />
                          <Form.Text id="nameHelpBlock" muted>
                            Nome que aparecerá no sistema
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupName">
                          <Form.Label>Ícone</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            onChange={handleChange('icon')}
                            value={values.icon}
                          >
                            <option value="">Nenhum</option>
                            {icons.map((icon: string) => (
                              <option key={icon} value={icon}>
                                {icon}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupDescription">
                          <Form.Label>Descrição</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={handleChange('description')}
                            value={values.description}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Text id="descriptionHelpBlock" muted>
                            Descrição que detalha a categoria
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Button variant="primary" type="submit">
                          Adicionar nova categoria
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Aside>
          </Col>
          <Col className="col-7">
            {loadingCategories && <LoadingState />}
            {!loadingCategories && (
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th style={{ width: '30%' }}>Nome</th>
                      <th>Descrição</th>
                      <th style={{ width: '15%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.map(category => (
                      <tr key={category.id}>
                        {/* <td>1</td> */}
                        <td>{category.title}</td>
                        <td>{category.description}</td>
                        <td>
                          <div>
                            <Button
                              variant="primary"
                              onClick={() => {
                                handleOpenUpdateCategory(category.id);
                              }}
                            >
                              <FiEdit color="#fff" size={20} />
                            </Button>
                            <Button
                              variant="danger"
                              className="ml-1"
                              onClick={() => {
                                setShowDeleteModal(true);
                                setLoadedCategoryId(category.id);
                              }}
                            >
                              <FiTrash2 color="#fff" size={20} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            )}
          </Col>
        </Row>
      )}

      {/* Update Category Modal */}
      <Modal
        show={showUpdateModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            id: categoryInfo?.id,
            title: categoryInfo?.title,
            icon: categoryInfo?.icon,
            description: categoryInfo?.description,
          }}
          onSubmit={handleUpdateCategory}
        >
          {({ handleSubmit, values, errors, handleChange, touched }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar categoria</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupName">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o nome da categoria.."
                        onChange={handleChange('title')}
                        value={values.title}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupName">
                      <Form.Label>Ícone</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        onChange={handleChange('icon')}
                        value={values.icon}
                      >
                        <option value="">Nenhum</option>
                        {icons.map((icon: string) => (
                          <option key={icon} value={icon}>
                            {icon}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupDescription">
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleChange('description')}
                        value={values.description}
                        isValid={touched.description && !errors.description}
                        isInvalid={!!errors.description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Salvar
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Category Modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Remover categoria?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem certeza de que deseja remover a categoria? Essa ação não
          poderá ser desfeita e todos os dados serão perdidos.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteCategory(loadedCategoryId)}
          >
            Remover
          </Button>
        </Modal.Footer>
      </Modal>
    </BasePage>
  );
};

export default PartnersCategories;
