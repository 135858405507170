/* eslint-disable */
import React, { useEffect, useCallback, useState } from 'react';
import { Container, Row, Col, Table, Button, Badge } from 'react-bootstrap';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import BasePage from '../../../components/BasePage';
import FilterBar from '../../../components/Financial/Subscriptions/FilterBar';
import LoadingState from '../../../components/LoadingState';
import Pagination from '../../../components/Pagination';

import { useAuth } from '../../../hooks/auth';

import AddSubscriptionModal from '../AddSubscriptionModal';
import DeleteSubscriptionModal from '../DeleteSubscriptionModal';
import EditSubscriptionModal from '../EditSubscriptionModal';

import api from '../../../services/api';

interface ISubscription {
  id: string;
  base_salary: number;
  discount_percentage: number;
  discount_value: number;
  recurring_frequency:
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'semester'
    | 'yearly';
  is_active: boolean;
  invoice_date: Date;
  next_invoice_date: Date;
  worker: {
    id: string;
    first_name: string;
    last_name: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

interface ISelectedSubscription {
  subscriptionId: string;
  action: 'edit' | 'remove';
}

const Subscriptions: React.FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] =
    useState<ISelectedSubscription>({} as ISelectedSubscription);

  const [showAddSubscriptionModal, setShowAddSubscriptionModal] =
    useState(false);
  const [showEditSubscriptionModal, setShowEditSubscriptionModal] =
    useState(false);
  const [showDeleteSubscriptionModal, setShowDeleteSubscriptionModal] =
    useState(false);

  const showCRUDModal = useCallback((action: 'edit' | 'remove', id: string) => {
    setSelectedSubscription({ action, subscriptionId: id });
  }, []);

  const updateSubscriptionsList = useCallback(async () => {
    const loadedSubscriptions = await api.get('/financial/subscriptions', {
      params: { page: currentPage },
    });
    setSubscriptions(loadedSubscriptions.data);
    setTotalSubscriptions(loadedSubscriptions.headers['x-total-subscriptions']);
  }, [currentPage]);

  const parseCurrency = useCallback((value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }, []);

  const parseRecurringFrequency = useCallback((value: string) => {
    switch (value) {
      case 'monthly':
        return 'Mensal';
        break;
      case 'bimonthly':
        return 'Bimestral';
        break;
      case 'quarterly':
        return 'Trimestral';
        break;
      case 'semester':
        return 'Semestral';
        break;
      case 'yearly':
        return 'Anual';
        break;
      default:
        return 'Mensal';
    }
  }, []);

  useEffect(() => {
    if (selectedSubscription.action === 'edit') {
      setShowEditSubscriptionModal(true);
    }

    if (selectedSubscription.action === 'remove') {
      setShowDeleteSubscriptionModal(true);
    }
  }, [selectedSubscription]);

  const cleanSelected = useCallback(() => {
    setSelectedSubscription({} as ISelectedSubscription);
  }, []);

  useEffect(() => {
    document.title = `Financeiro - ${process.env.REACT_APP_NAME}`;

    const loadData = async () => {
      await updateSubscriptionsList();
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading, updateSubscriptionsList]);

  return (
    <BasePage title="Financeiro - Contribuições recorrentes">
      {loading && <LoadingState />}
      {!loading && subscriptions && (
        <Container fluid>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="success"
                className="mr-1"
                onClick={() => setShowAddSubscriptionModal(true)}
              >
                Adicionar contribuição recorrente
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <FilterBar handleSubscriptions={setSubscriptions} />
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Trabalhador</th>
                  {user.role === 'admin' && <th>Sindicato</th>}
                  <th>Valor</th>
                  <th>Frequência</th>
                  <th style={{ width: '5%' }}>Status</th>
                  <th style={{ width: '10%' }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map(subscription => (
                  <tr key={subscription.id}>
                    <td style={{ verticalAlign: 'middle' }}>
                      {`${subscription.worker.first_name} ${subscription.worker.last_name}`}
                    </td>
                    {user.role === 'admin' && (
                      <td style={{ verticalAlign: 'middle' }}>
                        {subscription.syndicate.nome_fantasia}
                      </td>
                    )}
                    <td style={{ verticalAlign: 'middle' }}>
                      {parseCurrency(subscription.discount_value)}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {parseRecurringFrequency(
                        subscription.recurring_frequency,
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {subscription.is_active && (
                        <Badge variant="success">Ativo</Badge>
                      )}
                      {subscription.is_active === false && (
                        <Badge variant="danger">Cancelado</Badge>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        variant="primary"
                        className="mr-1"
                        onClick={() => showCRUDModal('edit', subscription.id)}
                      >
                        <FiEdit color="#ffffff" size={20} />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => showCRUDModal('remove', subscription.id)}
                      >
                        <FiTrash2 color="#ffffff" size={20} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col>
              <Pagination
                totalItems={totalSubscriptions}
                pageHandler={setCurrentPage}
                currentPage={currentPage}
              />
            </Col>
          </Row>
        </Container>
      )}

      <AddSubscriptionModal
        handleLoading={setLoading}
        handleShow={setShowAddSubscriptionModal}
        show={showAddSubscriptionModal}
      />

      <EditSubscriptionModal
        handleLoading={setLoading}
        handleShow={setShowEditSubscriptionModal}
        show={showEditSubscriptionModal}
        refreshSubscriptions={updateSubscriptionsList}
        selectedSubscription={selectedSubscription.subscriptionId}
        cleanSelected={cleanSelected}
      />

      <DeleteSubscriptionModal
        show={showDeleteSubscriptionModal}
        handleShow={setShowDeleteSubscriptionModal}
        handleLoading={setLoading}
        refreshSubscriptions={updateSubscriptionsList}
        subscriptionId={selectedSubscription.subscriptionId}
      />
    </BasePage>
  );
};

export default Subscriptions;
