import styled from 'styled-components';
import { Form as ReactForm } from 'react-bootstrap';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ChatHeader = styled.header`
  order: 1;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #dedede;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const ChatBody = styled.div`
  order: 2;
  flex: 1 1 0;
  position: relative;
  z-index: 1;
`;

export const MessageContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
`;

export const MessageHelper = styled.div`
  flex: 1 1 auto;
  min-height: 12px;
`;

export const MessageList = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

export const ChatFooter = styled.footer`
  order: 3;
  position: relative;
  padding: 16px 24px;
  background: #f7f7f7;
  border-radius: 10px;
  margin-top: 16px;
`;

export const Protocol = styled.h3`
  color: #f38725;
`;

export const Form = styled(ReactForm)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .form-group {
    margin: 0;
    display: flex;
    flex: 1;
  }

  button {
    margin-left: 8px;
  }
`;
