import React from 'react';
import { Link } from 'react-router-dom';
import { IoCogOutline } from 'react-icons/io5';

import Navigation from './Navigation';

import { Container, Logo } from './styles';
import { NavLink } from './Navigation/styles';

import logoSymbol from '../../../assets/logo/1x/symbol.png';

const Sidebar: React.FC = () => {
  return (
    <Container>
      <Link to="/dashboard">
        <Logo src={logoSymbol} alt="Viva Voz - Rede Suco de Laranja" />
      </Link>

      <Navigation />

      <NavLink to="/settings">
        <IoCogOutline size={24} />
        Config.
      </NavLink>
    </Container>
  );
};

export default Sidebar;
