import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Container } from './styles';

const LoadingState: React.FC = () => {
  return (
    <Container>
      <Spinner animation="border" role="status" variant="success">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default LoadingState;
