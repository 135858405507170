import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Modal, Col, Row, Container, Form, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BasePage from '../../../components/BasePage';
import LoadingState from '../../../components/LoadingState';
import Pagination from '../../../components/Pagination';
import FilterBar from '../../../components/Config/Companies/FilterBar';

import api from '../../../services/api';

import { ButtonContainer, Button } from './styles';

interface IIBGEStates {
  uf: string;
  nome: string;
}

interface IIBGEStates {
  id: number;
  nome: string;
}

interface ICity {
  id: string;
  name: string;
}

interface ICompany {
  id: string;
  nome_fantasia: string;
  cities: ICity[];
}

const Companies: React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // CRUD States
  const [companyID, setCompanyID] = useState('');
  const [states, setStates] = useState<IIBGEStates[]>([]);
  const [cities, setCities] = useState<IIBGEStates[]>([]);

  const history = useHistory();

  // Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowDelete = useCallback((id: string) => {
    setCompanyID(id);
    setShowDeleteModal(true);
  }, []);

  const handleShowAdd = useCallback(async () => {
    await api.get('/ibge/states').then(response => setStates(response.data));
    setShowAddModal(true);
  }, []);

  const handleLoadCities = useCallback(async (uf: string) => {
    await api
      .get(`/ibge/cities/${uf}`)
      .then(response => setCities(response.data));
  }, []);

  const handleCreateCompany = useCallback(async companyInfo => {
    await api.post('/companies', companyInfo);
    await api.get('/companies').then(response => setCompanies(response.data));
    setShowAddModal(false);
  }, []);

  const handleDeleteCompany = useCallback(async () => {
    await api.delete(`/companies/${companyID}`);
    await api.get('/companies').then(response => setCompanies(response.data));
    setShowDeleteModal(false);
  }, [companyID]);

  const handleClose = useCallback(() => {
    setShowDeleteModal(false);
    setShowAddModal(false);
  }, []);

  const handleCities = useCallback((company: ICompany) => {
    const output: any = [];
    company.cities.map(city => {
      return output.push(city.name);
    });

    return output.join(', ');
  }, []);

  useEffect(() => {
    const loadCompanies = async () => {
      const response = await api.get('/companies');

      setCompanies(response.data);
      setTotalCompanies(response.headers['x-total-companies']);

      setLoadingData(false);
      setLoadingCompanies(false);
    };

    loadCompanies();
  }, [loadingData]);

  const validationSchema = Yup.object().shape({
    nome_fantasia: Yup.string().required('O campo nome é obrigatório.'),
    razao_social: Yup.string(),
    cnpj: Yup.string(),
    address: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    postal_code: Yup.string(),
  });

  return (
    <BasePage title="Empresas">
      {loadingData && <LoadingState />}
      {loadingData === false && (
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <FilterBar
                pageHandler={setCurrentPage}
                currentPage={currentPage}
                handleCompanies={setCompanies}
                handleLoading={setLoadingCompanies}
              />
            </Col>
            <Col className="d-flex align-items-center col-2">
              <Button
                variant="success"
                className="ml-auto"
                onClick={() => handleShowAdd()}
              >
                Adicionar empresa
              </Button>
            </Col>
          </Row>
          <Row>
            {loadingCompanies && <LoadingState />}
            {loadingCompanies === false && (
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th style={{ width: '40%' }}>Nome</th>
                      <th>Cidades</th>
                      <th style={{ width: '10%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies.map(company => (
                      <tr>
                        {/* <td>1</td> */}
                        <td>{company.nome_fantasia}</td>
                        <td>{handleCities(company)}</td>
                        <td>
                          <ButtonContainer>
                            <Button
                              variant="primary"
                              onClick={() => {
                                history.push(
                                  `/settings/companies/${company.id}`,
                                );
                              }}
                            >
                              <FiEdit size={20} />
                              Editar
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleShowDelete(company.id)}
                            >
                              <FiTrash2 size={20} />
                            </Button>
                          </ButtonContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pageHandler={setCurrentPage}
                  currentPage={currentPage}
                  totalItems={totalCompanies}
                />
              </Col>
            )}
          </Row>
        </Container>
      )}

      {/* Delete company modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Você confirma que deseja excluir esta empresa? Essa ação não poderá
            ser desfeita e todos os dados serão perdidos.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteCompany();
            }}
          >
            Excluir
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add company modal */}
      <Modal
        show={showAddModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        dialogClassName="modal-70w"
      >
        <Formik
          initialValues={{
            nome_fantasia: undefined,
            razao_social: undefined,
            cnpj: undefined,
            phone: undefined,
            email: undefined,
            type: undefined,
            address: undefined,
            address2: undefined,
            city_id: undefined,
            state: undefined,
            postal_code: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleCreateCompany(values)}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Adicionar empresa</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={() => handleSubmit()}>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group controlId="first_name">
                          <Form.Label>Nome da empresa *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nome do sindicato"
                            value={values.nome_fantasia}
                            onChange={handleChange('nome_fantasia')}
                            onBlur={handleBlur('nome_fantasia')}
                            isValid={
                              touched.nome_fantasia && !errors.nome_fantasia
                            }
                            isInvalid={!!errors.nome_fantasia}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="razao_social">
                          <Form.Label>Razão Social</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Razão social do sindicato"
                            value={values.razao_social}
                            onChange={handleChange('razao_social')}
                            onBlur={handleBlur('razao_social')}
                            isValid={
                              touched.razao_social && !errors.razao_social
                            }
                            isInvalid={!!errors.razao_social}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="cnpj">
                          <Form.Label>CNPJ</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CNPJ do sindicato"
                            value={values.cnpj}
                            onChange={handleChange('cnpj')}
                            onBlur={handleBlur('cnpj')}
                            isValid={touched.cnpj && !errors.cnpj}
                            isInvalid={!!errors.cnpj}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Form.Group controlId="postal_code">
                          <Form.Label>CEP</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="CEP do sindicato"
                            value={values.postal_code}
                            onChange={handleChange('postal_code')}
                            onBlur={handleBlur('postal_code')}
                            isValid={touched.postal_code && !errors.postal_code}
                            isInvalid={!!errors.postal_code}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="address">
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Endereço do sindicato"
                            value={values.address}
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="address2">
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Complemento do endereço"
                            value={values.address2}
                            onChange={handleChange('address2')}
                            onBlur={handleBlur('address2')}
                            isValid={touched.address2 && !errors.address2}
                            isInvalid={!!errors.address2}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="state">
                          <Form.Label>Estado</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.state}
                            onChange={item => {
                              handleChange('state')(item);
                              handleLoadCities(item.target.value);
                            }}
                            onBlur={handleBlur('state')}
                            isValid={touched.state && !errors.state}
                            isInvalid={!!errors.state}
                          >
                            <option value="0">Selecione um Estado</option>
                            {states.map(state => (
                              <option key={state.uf} value={state.uf}>
                                {state.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="city">
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control
                            as="select"
                            value={values.city_id}
                            onChange={handleChange('city_id')}
                            onBlur={handleBlur('city_id')}
                            isValid={touched.city_id && !errors.city_id}
                            isInvalid={!!errors.city_id}
                          >
                            <option value="0" disabled>
                              Selecione uma cidade
                            </option>
                            {cities.map(city => (
                              <option key={city.id} value={city.id}>
                                {city.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </BasePage>
  );
};

export default Companies;
