import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { format } from 'date-fns';
import BasePage from '../../../components/BasePage';
import LoadingState from '../../../components/LoadingState';

import api from '../../../services/api';
import { useToast } from '../../../hooks/useToast';

import EditResearchModal from '../EditResearchModal';
import DeleteResearchModal from '../DeleteResearchModal';
import AddQuestionModal from './AddQuestionModal';
import RemoveQuestionModal from './RemoveQuestionModal';
import EditQuestionModal from './EditQuestionModal';

interface IAnswer {
  id: string;
  text: string;
}

interface IQuestion {
  id: string;
  text: string;
  type: string;
  answers: IAnswer[];
}

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
  questions: IQuestion[];
}

interface ISelectedQuestion {
  questionId: string;
  action: 'edit' | 'remove';
}

const ShowResearch: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [research, setResearch] = useState<IResearch>({} as IResearch);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showRemoveQuestionModal, setShowRemoveQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<ISelectedQuestion>(
    {} as ISelectedQuestion,
  );
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);

  const publishResearch = useCallback(async () => {
    try {
      const updatedResearch = await api.patch(`/research/${id}`, {
        status: 'published',
      });

      addToast({
        title: 'Sucesso',
        body: 'A pesquisa foi publicada com sucesso.',
      });

      setResearch(updatedResearch.data);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar publicar a pesquisa.',
      });
    }
  }, [addToast, id]);

  const finishResearch = useCallback(async () => {
    try {
      const updatedResearch = await api.patch(`/research/${id}`, {
        status: 'finished',
      });

      addToast({
        title: 'Sucesso',
        body: 'A pesquisa foi finalizada com sucesso.',
      });

      setResearch(updatedResearch.data);
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar finalizar a pesquisa.',
      });
    }
  }, [addToast, id]);

  useEffect(() => {
    if (selectedQuestion.action === 'edit') {
      setShowEditQuestionModal(true);
    }
  }, [selectedQuestion]);

  const editQuestionModal = useCallback((questionId: string) => {
    setSelectedQuestion({ action: 'edit', questionId });
  }, []);

  const removeQuestionModal = useCallback((questionId: string) => {
    setSelectedQuestion({ action: 'remove', questionId });
    setShowRemoveQuestionModal(true);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const loadedResearch = await api.get(`/research/${id}`);
      setResearch(loadedResearch.data);

      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading, id]);

  return (
    <BasePage title={!research ? 'Pesquisa' : `Pesquisa - ${research.title}`}>
      <>
        {loading && <LoadingState />}
        {!loading && research && (
          <Container fluid>
            <Row>
              <Col className="col-9">
                <Row className="mb-4">
                  <Col className="d-flex align-items-center">
                    <h2>Questionário</h2>
                  </Col>
                  {research.status === 'draft' && (
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="success"
                        onClick={() => setShowAddQuestionModal(true)}
                      >
                        Adicionar questão
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    {research.questions.map((question, index) => (
                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>
                            {`${index + 1}. ${question.text}`}
                            <small className="ml-1">
                              {question.type === 'radio'
                                ? '(única seleção)'
                                : '(múltipla seleção)'}
                            </small>
                          </Card.Title>
                          <Card.Subtitle className="text-muted">
                            Opções de respostas:
                          </Card.Subtitle>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                          {question.answers.map(answer => (
                            <ListGroupItem>{answer.text}</ListGroupItem>
                          ))}
                        </ListGroup>
                        {research.status === 'draft' && (
                          <Card.Footer>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => editQuestionModal(question.id)}
                            >
                              Editar
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              className="ml-1"
                              onClick={() => removeQuestionModal(question.id)}
                            >
                              Remover
                            </Button>
                          </Card.Footer>
                        )}
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Col>
              <Col className="col-3">
                <Card>
                  <Card.Header>
                    Opções da pesquisa
                    {research.status === 'draft' && (
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => setShowEditModal(true)}
                      >
                        Editar
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col>
                          <p>
                            <strong className="mr-1">Status:</strong>
                            {research.status === 'finished' && 'Finalizado'}
                            {research.status === 'published' && 'Publicado'}
                            {research.status === 'draft' && 'Rascunho'}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            <strong className="mr-1">Descrição:</strong>
                            {research.description}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            <strong className="mr-1">Data de início:</strong>
                            {format(
                              new Date(research.start_date),
                              "dd/MM/yyyy ' às ' HH:mm",
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            <strong className="mr-1">Data de fim:</strong>
                            {format(
                              new Date(research.finish_date),
                              "dd/MM/yyyy ' às ' HH:mm",
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col>
                        <Button
                          variant="link"
                          style={{ color: 'red' }}
                          onClick={() => setShowDeleteModal(true)}
                        >
                          Remover
                        </Button>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        {research.status === 'published' && (
                          <Button
                            variant="primary"
                            onClick={() => finishResearch()}
                          >
                            Finalizar
                          </Button>
                        )}
                        {research.status === 'draft' && (
                          <Button
                            variant="primary"
                            onClick={() => publishResearch()}
                          >
                            Publicar
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        )}

        <EditResearchModal
          handleShow={setShowEditModal}
          show={showEditModal}
          researchId={id}
          handleResearch={setResearch}
        />

        <DeleteResearchModal
          selectedResearch={id}
          handleShow={setShowDeleteModal}
          show={showDeleteModal}
          handleLoading={setLoading}
          handleResearches={() => null}
        />

        <AddQuestionModal
          show={showAddQuestionModal}
          handleShow={setShowAddQuestionModal}
          handleLoading={setLoading}
          handleResearch={setResearch}
          researchId={id}
        />

        <EditQuestionModal
          show={showEditQuestionModal}
          handleShow={setShowEditQuestionModal}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          questionId={selectedQuestion.questionId}
          handleSelected={setSelectedQuestion}
          handleLoading={setLoading}
          handleResearch={setResearch}
        />

        <RemoveQuestionModal
          show={showRemoveQuestionModal}
          handleShow={setShowRemoveQuestionModal}
          handleResearch={setResearch}
          handleLoading={setLoading}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          questionId={selectedQuestion.questionId}
          handleSelected={setSelectedQuestion}
        />
      </>
    </BasePage>
  );
};

export default ShowResearch;
