import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-height: 24px;
  justify-content: flex-end;
  padding: 8px 32px;

  font-weight: 300;
  font-size: 12px;
  color: #333333;
`;
