import React, { useCallback } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import NotificationControl from './NotificationControl';

import { Container } from './styles';

import { useAuth } from '../../../hooks/auth';

interface IHeaderProps {
  title: string;
}

const Header: React.FC<IHeaderProps> = ({ title }) => {
  const { signOut } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <Row style={{ width: '100%' }}>
        <Col className="col-8 d-flex align-items-center">
          <h2>{title}</h2>
        </Col>
        <Col className="col-4 d-flex align-items-center justify-content-end">
          <NotificationControl />
          <Button
            variant="primary"
            className="ml-1"
            onClick={() => handleSignOut()}
          >
            Sair
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
