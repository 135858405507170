import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Data = styled.span`
  font-size: 40px;
  color: #006633;
`;

export const Text = styled.p`
  margin-bottom: 0;
`;
