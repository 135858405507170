import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import api from '../../../../services/api';

interface IProps {
  segmentId: string;
}

const TotalWorkers: React.FC<IProps> = ({ segmentId }) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get(`/workers-segments/${segmentId}/workers`);

      const { totalWorkers } = response.data;

      setTotal(totalWorkers);
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [segmentId, loading]);

  return (
    <Button variant="primary" block disabled>
      {loading && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {!loading && total}
    </Button>
  );
};

export default TotalWorkers;
