import React, { useState } from 'react';
import { SignInContainer } from '../../pages/SignIn/styles';
import { Container, Toast } from './styles';

interface ToastContent {
  id?: string;
  title: string;
  body: string;
  type?: string;
}

interface ToastProps {
  messages: ToastContent[];
}

const ToastContainer: React.FC<ToastProps> = ({ messages }) => {
  const [show, setShow] = useState(true);

  return (
    <Container>
      {messages.map(message => (
        <Toast
          key={message.id}
          onClose={() => setShow(false)}
          show={show}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">{message.title}</strong>
            <small>agora</small>
          </Toast.Header>
          <Toast.Body>{message.body}</Toast.Body>
        </Toast>
      ))}
    </Container>
  );
};

export default ToastContainer;
