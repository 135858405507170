import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { FiFile, FiDownload } from 'react-icons/fi';
import * as Tone from 'tone';

import { Button } from 'react-bootstrap';
import { Container, DocumentWrapper } from './styles';

interface IMessageProps {
  id: string;
  key_from_server: 'income' | 'outcome';
  message_type: 'text' | 'audio' | 'document' | 'image';
  data: string;
  media_url: string;
  media_server_url: string;
  media_mime_type: string;
  media_size: number;
  media_server_size: number;
  latitude: number;
  longitude: number;
  created_at: Date;
}

interface IProps {
  content: IMessageProps;
}

const Message: React.FC<IProps> = ({ content }: IProps) => {
  const handleAudio = useCallback(
    (value: string) => {
      const file = content.media_server_url;
      const buffer = new Tone.Buffer();

      const shift1 = new Tone.PitchShift({
        pitch: -6,
      }).toDestination();

      const shift2 = new Tone.PitchShift({
        pitch: 8,
      }).toDestination();

      const player = new Tone.Player();

      player.connect(shift1);
      player.connect(shift2);

      buffer.load(file).then(() => {
        player.buffer = buffer;

        if (value === 'play') {
          player.start();
        }

        if (value === 'stop') {
          player.stop();
        }
      });
    },
    [content.media_server_url],
  );

  const handleDownloadFile = useCallback(fileName => {
    const data = fileName;

    const a = document.createElement('a');

    a.style.display = 'none';

    a.href = data;

    a.setAttribute('download', fileName);
    a.click();

    window.URL.revokeObjectURL(a.href);
  }, []);

  return (
    <Container type={content.key_from_server} key={content.id}>
      {content.message_type === 'text' && <span>{content.data}</span>}

      {content.message_type === 'image' && content.media_server_url && (
        <img src={content.media_server_url} alt="Viva Voz" />
      )}

      {content.message_type === 'audio' && (
        <>
          <p>Mensagem de áudio: </p>
          <Button
            variant="primary"
            className="mb-1"
            onClick={() => {
              if (content && content.media_server_url) {
                handleAudio('play');
              }
            }}
          >
            Play
          </Button>
          <Button variant="secondary" onClick={() => handleAudio('pause')}>
            Stop
          </Button>
        </>
      )}

      {content.message_type === 'document' && content.media_server_url && (
        <DocumentWrapper
          onClick={() => handleDownloadFile(content.media_server_url)}
        >
          <span>
            <FiFile size={24} />
            {content.data}
          </span>
          <FiDownload size={24} />
        </DocumentWrapper>
      )}

      <span className="timestamp">
        {format(new Date(content.created_at), "dd/MM/yyyy ' às ' HH:mm")}
      </span>
    </Container>
  );
};

export default Message;
