import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { Formik } from 'formik';
import { FiPaperclip, FiVolume2, FiInfo, FiCalendar } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import BasePage from '../../../components/BasePage';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/useToast';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Title,
  Aside,
  StatusContainer,
  TimestampContainer,
  TrashLink,
  CategoryBox,
  DatePicker,
  PreviewFeaturedImage,
} from './styles';

import api from '../../../services/api';

interface ICategories {
  id: string;
  title: string;
}

const CreateNewsContent: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const [categories, setCategories] = useState<ICategories[]>();
  const [publishedDate, setPublishedDate] = useState<any>(new Date());

  const [status, setStatus] = useState<
    string | 'draft' | 'revision' | 'published'
  >('draft');
  const [timestamp, setTimestamp] = useState<'now' | any>('now');

  const [hasFeaturedImg, setHasFeaturedImg] = useState(false);
  const [featuredImgLabel, setFeaturedImgLabel] = useState('Selecionar imagem');
  const [featuredAudioLabel, setFeaturedAudioLabel] = useState(
    'Selecionar Audio',
  );

  const [featuredImgSrc, setFeaturedImgSrc] = useState('');

  const [showExcerpt, setShowExcerpt] = useState(false);
  const [showStatusBox, setShowStatusBox] = useState(false);
  const [showTimestampBox, setShowTimestampBox] = useState(false);

  const handleCreatePost = useCallback(
    async values => {
      const data = new FormData();
      data.append('featured_image', values.featured_image);
      
      data.append('audio_file', values.audio_file);
    
      data.append('document', JSON.stringify(values));

      try {
        const newPost = await api.post('/news', data, {
          headers: { 'Content-Type': `multipart/form-data;` },
        });

        addToast({
          title: 'Sucesso',
          body: 'Notícia criada com sucesso.',
        });
        

        history.push(`/news/${newPost.data.id}`);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar criar uma nova notícia.',
        });
      }
    },
    [addToast, history],
  );

  const handleAudioUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && event.target.files) { 
      setFeaturedAudioLabel(event.target.files[0].name);
    }
  };
  const handleImageUpload = useCallback(event => {
    if (event.target.files[0]) {
      setHasFeaturedImg(true);

      const alt = event.target.files[0].name;
      const src = URL.createObjectURL(event.target.files[0]);

      setFeaturedImgLabel(alt);
      setFeaturedImgSrc(src);
    }
  }, []);

  useEffect(() => {
    document.title = 'Criar notícia - Viva Voz - Rede Suco de Laranja';

    api
      .get<ICategories[]>('/news/categories')
      .then(response => setCategories(response.data));
  }, []);

  return (
    <BasePage title="Nova notícia">
      <Formik
        initialValues={{
          title: '',
          excerpt: '',
          content: '',
          status: 'draft',
          type: 'default',
          author_id: user.id,
          categories: [],
          featured_image: '',
          audio_file: '',
          publishedAt: publishedDate,
          syndicate_id: user.syndicate_id,
        }}
        onSubmit={handleCreatePost}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          // <Form onSubmit={handleSubmit}>
          <Row>
            <Col className="col-9">
              <Row className="mb-3">
                <Col>
                  <Title
                    type="text"
                    placeholder="Digite o título aqui..."
                    value={values.title}
                    onChange={handleChange('title')}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  {showExcerpt ? (
                    <button
                      key="hide-exerpt"
                      type="button"
                      onClick={() => setShowExcerpt(false)}
                      className="mb-3"
                      style={{ background: 'transparent' }}
                    >
                      Clique aqui para fechar o box de resumo
                    </button>
                  ) : (
                    <button
                      key="show-exerpt"
                      type="button"
                      onClick={() => setShowExcerpt(true)}
                      style={{ background: 'transparent' }}
                    >
                      Clique aqui para abrir o box de resumo
                    </button>
                  )}
                  {showExcerpt && (
                    <Editor
                      apiKey={process.env.REACT_APP_TINY_API_KEY}
                      id="excerpt-editor"
                      key="excerpt-editor"
                      value={values.excerpt}
                      onEditorChange={handleChange('excerpt')}
                      init={{
                        height: '200',
                        menubar: false,
                        plugins: [
                          'advlist autolink charmap',
                          'searchreplace visualblocks',
                          'paste wordcount',
                        ],
                        toolbar:
                          // eslint-disable-next-line no-multi-str
                          'undo redo | bold italic',
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_API_KEY}
                    id="content-editor"
                    key="content-editor"
                    value={values.content}
                    onEditorChange={handleChange('content')}
                    init={{
                      height: '650',
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image imagetools charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        // eslint-disable-next-line no-multi-str
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat code | image media preview print | fullscreen help',
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-3">
              <Aside>
                <h3>Publicar</h3>
                <div className="mb-3 d-flex justify-content-end">
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      setFieldValue('status', 'draft');
                      return handleSubmit();
                    }}
                  >
                    Salvar rascunho
                  </Button>
                </div>
                <StatusContainer>
                  <div className="status-info">
                    <FiInfo size={20} className="mr-2" />
                    <p style={{ margin: 0, padding: 0 }}>
                      Status:
                      <span className="ml-1">
                        {status === 'draft' && 'Rascunho'}
                        {status === 'revision' && 'Revisão pendente'}
                        {status === 'published' && 'Publicado'}
                      </span>
                    </p>
                    <Button
                      variant="link"
                      onClick={() => setShowStatusBox(true)}
                      className="ml-2"
                    >
                      Editar
                    </Button>
                  </div>
                  {showStatusBox && (
                    <Form.Group
                      controlId="status-select"
                      className="status-select"
                    >
                      <Form.Control
                        as="select"
                        custom
                        onChange={handleChange('status')}
                        value={values.status}
                      >
                        <option key="status-draft" value="draft">
                          Rascunho
                        </option>
                        <option key="status-revision" value="revision">
                          Revisão pendente
                        </option>
                      </Form.Control>
                      <Button
                        type="button"
                        variant="primary"
                        className="ml-2"
                        onClick={() => {
                          setStatus(values.status);
                          setShowStatusBox(false);
                        }}
                      >
                        Ok
                      </Button>
                    </Form.Group>
                  )}
                </StatusContainer>
                <TimestampContainer>
                  <div className="timestamp-info">
                    <FiCalendar size={20} className="mr-2" />
                    <p style={{ padding: 0, margin: 0 }}>
                      Publicação:
                      <span className="ml-1">
                        {timestamp === 'now' && 'imediatamente'}
                        {timestamp !== 'now' &&
                          format(values.publishedAt, "dd/MM/yyyy ' às ' HH:mm")}
                      </span>
                    </p>
                    <Button
                      variant="link"
                      onClick={() => setShowTimestampBox(true)}
                      className="ml-2"
                    >
                      Editar
                    </Button>
                  </div>

                  {showTimestampBox && (
                    <Form.Group>
                      <DatePicker
                        selected={publishedDate}
                        onChange={date => {
                          setPublishedDate(date);
                          handleChange('publishedAt');
                        }}
                        locale="pt-BR"
                        timeInputLabel="Hora:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                      />
                      <Button
                        type="button"
                        variant="primary"
                        className="ml-2"
                        onClick={() => {
                          setTimestamp(values.publishedAt);
                          setShowTimestampBox(false);
                        }}
                      >
                        Ok
                      </Button>
                    </Form.Group>
                  )}
                </TimestampContainer>
                <hr />
                <Row className="d-flex align-items-center">
                  <Col className="col d-flex justify-content-end">
                    {user.role !== 'admin' && (
                      <Button
                        variant="primary"
                        onClick={() => {
                          setFieldValue('status', 'revision');
                          return handleSubmit();
                        }}
                      >
                        Enviar para revisão
                      </Button>
                    )}

                    {user.role === 'admin' && (
                      <Button
                        variant="primary"
                        onClick={() => {
                          setFieldValue('status', 'published');
                          return handleSubmit();
                        }}
                      >
                        Publicar
                      </Button>
                    )}
                  </Col>
                </Row>
              </Aside>
              <Aside>
                <h3>Formato</h3>
                <div key="format" className="mb-3 mt-3">
                  <Form.Group
                    className="d-flex align-items-center"
                    style={{ margin: 0 }}
                  >
                    <Form.Check
                      type="radio"
                      id="default"
                      name="format"
                      onChange={handleChange('type')}
                      value="default"
                      checked={values.type === 'default'}
                    />
                    <Form.Label column style={{ padding: 0 }}>
                      <FiPaperclip size={20} style={{ marginRight: 4 }} />
                      Padrão
                    </Form.Label>
                  </Form.Group>
                  {/* <Form.Group
                      className="d-flex align-items-center"
                      style={{ margin: 0 }}
                    >
                      <Form.Check
                        type="radio"
                        id="audio"
                        name="format"
                        onChange={handleChange('type')}
                        value="audio"
                        checked={values.type === 'audio'}
                      />
                      <Form.Label column style={{ padding: 0 }}>
                        <FiVolume2 size={20} style={{ marginRight: 4 }} />
                        Áudio
                      </Form.Label>
                    </Form.Group> */}
                </div>
              </Aside>
              <Aside>
                <h3>Categorias</h3>
                <CategoryBox>
                  <div className="category-list">
                    {categories?.map(category => (
                      <Form.Check
                        key={category.id}
                        name="categories[]"
                        type="checkbox"
                        id={category.id}
                        label={category.title}
                        onChange={handleChange('categories')}
                        value={category.id}
                        checked={
                          category.id ===
                          values.categories.find(value => value === category.id)
                        }
                      />
                    ))}
                  </div>
                </CategoryBox>
              </Aside>
              <Aside>
                <h3>Imagem destacada</h3>

                {hasFeaturedImg && (
                  <PreviewFeaturedImage
                    src={featuredImgSrc || values.featured_image}
                    alt={featuredImgLabel}
                  />
                )}

                <Form.File
                  accept="image/png, image/jpeg, image/jpg"
                  id="featured-image"
                  label={featuredImgLabel}
                  data-browse="Selecionar"
                  name="featured_image"
                  custom
                  className="mt-3"
                  onChange={(event: any) => {
                    handleImageUpload(event);
                    setFieldValue('featured_image', event.target.files[0]);
                  }}
                />
              </Aside>
              <Aside>
                <h3>Audio</h3>

                <Form.File
                  accept="audio/*"
                  id="audio_file"
                  label={featuredAudioLabel}
                  data-browse="Selecionar"
                  name="audio_file"
                  custom
                  className="mt-3"
                  onChange={(event: any) => {
                    setFieldValue('audio_file', event.target.files?.[0]);
                    handleAudioUpload(event);
                  }}
                />
              </Aside>
            </Col>
          </Row>
          // </Form>
        )}
      </Formik>
    </BasePage>
  );
};

export default CreateNewsContent;
