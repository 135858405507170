import styled from 'styled-components';

export const Header = styled.div`
  border-radius: 8px;
  background: #f7f7f7;
  width: 100%;
  margin-bottom: 40px;
  padding: 16px;

  h4 {
    font-weight: lighter;
    font-size: 16px;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;

  h4 {
    color: #006633;
    margin-bottom: 16px;
    font-size: 16px;
  }
`;
