import React, { useEffect, useState, useCallback } from 'react';
import { Container, Modal, Form, Col, Row, Table } from 'react-bootstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BasePage from '../../../components/BasePage';
import FilterBar from '../../../components/Config/Users/FilterBar';
import Pagination from '../../../components/Pagination';
import LoadingState from '../../../components/LoadingState';
import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import { ButtonContainer, Button } from './styles';

interface IUserInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  syndicate_id?: string;
}

interface IUnions {
  id: string;
  nome_fantasia: string;
}

const Users: React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState<IUserInfo[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [unions, setUnions] = useState<IUnions[]>([]);

  const { user } = useAuth();

  const handleShowAddModal = useCallback(() => {
    setShowAddModal(true);
  }, []);

  const handleShowDeleteModal = useCallback(id => {
    setShowDeleteModal(true);
    setUserID(id);
  }, []);

  const handleShowEditModal = useCallback(async id => {
    await api.get(`/users/${id}`).then(response => setUserInfo(response.data));
    setShowEditModal(true);
  }, []);

  const handleAddUser = useCallback(
    async values => {
      let addUser = {};

      if (values.syndicate.length > 0) {
        addUser = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          role: values.role,
          syndicate_id: values.syndicate,
        };
      } else {
        addUser = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          role: user.role,
          syndicate_id: user.syndicate_id,
        };
      }

      await api.post('/users/create', addUser);
      await api.get('/users').then(response => setUsers(response.data));
      setShowAddModal(false);
    },
    [user],
  );

  const handleUpdateUser = useCallback(async userUpdateData => {
    await api.put(`/users/${userUpdateData.id}`, userUpdateData);
    await api.get('/users').then(response => setUsers(response.data));
    setShowEditModal(false);
  }, []);

  const handleDeleteUser = useCallback(async id => {
    await api.delete(`/users/${id}`);
    await api.get('/users').then(response => setUsers(response.data));
    setShowDeleteModal(false);
  }, []);

  const handleClose = useCallback(() => {
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowAddModal(false);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const loadedUsers = await api.get('/users');

      setUsers(loadedUsers.data);
      setTotalUsers(loadedUsers.headers['x-total-users']);

      if (user.role === 'admin') {
        await api.get('/unions').then(response => setUnions(response.data));
      }

      setLoadingData(false);
      setLoadingUsers(false);
    };

    loadData();
  }, [loadingData, user]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('O nome é obrigatório.'),
    last_name: Yup.string().required('O sobrenome é obrigatório.'),
    email: Yup.string().email().required('o e-mail é obrigatório'),
    syndicate: Yup.string(),
    role: Yup.string(),
    password: Yup.string().min(6),
    password_confirmation: Yup.string()
      .min(6)
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas.'),
  });

  return (
    <BasePage title="Usuários">
      {loadingData && <LoadingState />}
      {loadingData === false && (
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <FilterBar
                currentPage={currentPage}
                pageHandler={setCurrentPage}
                handleUsers={setUsers}
                handleLoading={setLoadingUsers}
              />
            </Col>
            <Col className="col-2 d-flex align-items-center">
              <Button
                variant="success"
                className="ml-auto"
                onClick={handleShowAddModal}
              >
                Adicionar usuário
              </Button>
            </Col>
          </Row>
          <Row>
            {loadingUsers && <LoadingState />}
            {loadingUsers === false && (
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th style={{ width: '10%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(userData => (
                      <tr>
                        {/* <td>1</td> */}
                        <td>{`${userData.first_name} ${userData.last_name}`}</td>
                        <td>{userData.email}</td>
                        <td>
                          <ButtonContainer>
                            <Button
                              variant="primary"
                              onClick={() => handleShowEditModal(userData.id)}
                            >
                              <FiEdit size={20} />
                              Editar
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleShowDeleteModal(userData.id)}
                            >
                              <FiTrash2 size={20} />
                            </Button>
                          </ButtonContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
          <Pagination
            currentPage={currentPage}
            pageHandler={setCurrentPage}
            totalItems={totalUsers}
          />
        </Container>
      )}

      {/* Delete user modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Você cofirma que quer excluir este usuário? Essa ação não poderá ser
            desfeita e todos os dados do usuário serão perdidos.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDeleteUser(userID)}>
            Excluir
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit user Modal */}
      <Modal
        show={showEditModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            id: userInfo?.id,
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name,
            email: userInfo?.email,
            syndicate: userInfo?.syndicate_id || 0,
            role: userInfo?.role,
            password: '',
            password_confirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleUpdateUser(values)}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar usuário</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={() => handleSubmit()}>
                  <Form.Group controlId="first_name">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Primeiro nome"
                      value={values.first_name}
                      onChange={handleChange('first_name')}
                      onBlur={handleBlur('first_name')}
                      isValid={touched.first_name && !errors.first_name}
                      isInvalid={!!errors.first_name}
                    />
                  </Form.Group>

                  <Form.Group controlId="last_name">
                    <Form.Label>Sobrenome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Sobrenome"
                      value={values.last_name}
                      onChange={handleChange('last_name')}
                      onBlur={handleBlur('last_name')}
                      isValid={touched.last_name && !errors.last_name}
                      isInvalid={!!errors.last_name}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Digite o e-mail"
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />
                  </Form.Group>

                  {user.role === 'admin' && (
                    <Form.Group controlId="syndicate">
                      <Form.Label>Sindicato</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.syndicate}
                        onChange={handleChange('syndicate')}
                        onBlur={handleBlur('syndicate')}
                        isValid={touched.syndicate && !errors.syndicate}
                        isInvalid={!!errors.syndicate}
                      >
                        <option value="0" disabled>
                          Selecione um sindicato
                        </option>
                        <option value="0">Nenhum</option>
                        {unions.map(syndicate => (
                          <option key={syndicate.id} value={syndicate.id}>
                            {syndicate.nome_fantasia}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}

                  {user.role === 'admin' && (
                    <Form.Group controlId="role">
                      <Form.Label>Função</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.role}
                        onChange={handleChange('role')}
                        onBlur={handleBlur('role')}
                        isValid={touched.role && !errors.role}
                        isInvalid={!!errors.role}
                      >
                        <option value="0" disabled>
                          Selecione uma função
                        </option>
                        <option value="admin">Administrador</option>
                        <option value="syndicate">Sindicato</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Form.Group controlId="password">
                    <Form.Label>Nova senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                  </Form.Group>

                  <Form.Group controlId="password_confirmation">
                    <Form.Label>Confirme a nova senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirme a nova senha"
                      value={values.password_confirmation}
                      onChange={handleChange('password_confirmation')}
                      onBlur={handleBlur('password_confirmation')}
                      isValid={
                        touched.password_confirmation &&
                        !errors.password_confirmation
                      }
                      isInvalid={!!errors.password_confirmation}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>

      {/* Add user modal */}
      <Modal
        show={showAddModal}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            syndicate: '',
            role: '',
            password: '',
            password_confirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleAddUser(values)}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            errors,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Adicionar usuário</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={() => handleSubmit()}>
                  <Form.Group controlId="first_name">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Primeiro nome"
                      value={values.first_name}
                      onChange={handleChange('first_name')}
                      onBlur={handleBlur('first_name')}
                      isValid={touched.first_name && !errors.first_name}
                      isInvalid={!!errors.first_name}
                    />
                  </Form.Group>

                  <Form.Group controlId="last_name">
                    <Form.Label>Sobrenome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Sobrenome"
                      value={values.last_name}
                      onChange={handleChange('last_name')}
                      onBlur={handleBlur('last_name')}
                      isValid={touched.last_name && !errors.last_name}
                      isInvalid={!!errors.last_name}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Digite o e-mail"
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />
                  </Form.Group>

                  {user.role === 'admin' && (
                    <Form.Group controlId="syndicate">
                      <Form.Label>Sindicato</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.syndicate}
                        onChange={handleChange('syndicate')}
                        onBlur={handleBlur('syndicate')}
                        isValid={touched.syndicate && !errors.syndicate}
                        isInvalid={!!errors.syndicate}
                      >
                        <option value="0" disabled>
                          Selecione um sindicato
                        </option>
                        <option value="0">Nenhum</option>
                        {unions.map(syndicate => (
                          <option key={syndicate.id} value={syndicate.id}>
                            {syndicate.nome_fantasia}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}

                  {user.role === 'admin' && (
                    <Form.Group controlId="role">
                      <Form.Label>Função</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.role}
                        onChange={handleChange('role')}
                        onBlur={handleBlur('role')}
                        isValid={touched.role && !errors.role}
                        isInvalid={!!errors.role}
                      >
                        <option value="0" disabled>
                          Selecione uma função
                        </option>
                        <option value="admin">Administrador</option>
                        <option value="syndicate">Sindicato</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Form.Group controlId="password">
                    <Form.Label>Nova senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                  </Form.Group>

                  <Form.Group controlId="password_confirmation">
                    <Form.Label>Confirme a nova senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirme a nova senha"
                      value={values.password_confirmation}
                      onChange={handleChange('password_confirmation')}
                      onBlur={handleBlur('password_confirmation')}
                      isValid={
                        touched.password_confirmation &&
                        !errors.password_confirmation
                      }
                      isInvalid={!!errors.password_confirmation}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </BasePage>
  );
};

export default Users;
