import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Routes';

// public pages
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

// private pages
import Dashboard from '../pages/Dashboard';
import Complaints from '../pages/Complaints';
import ShowComplaint from '../pages/Complaints/ShowComplaint';
import UnionsPartners from '../pages/UnionsPartners';
import Notifications from '../pages/Notifications';
import Research from '../pages/Research';
import EditResearch from '../pages/Research/EditResearch';
import ResearchResults from '../pages/Research/ResearchResults';

// Financial pages
import Invoices from '../pages/Financial/Invoices';
import Subscriptions from '../pages/Financial/Subscriptions';

// News pages
import News from '../pages/News';
import CreateNewsContent from '../pages/News/Create';
import ShowNewsContent from '../pages/News/Show';

import Workers from '../pages/Workers';
import Profile from '../pages/Workers/Profile';

// Config pages
import Config from '../pages/Config';
import Unions from '../pages/Config/Unions';
import PartnersCategories from '../pages/Config/Unions/PartnersCategories';
import Companies from '../pages/Config/Companies';
import ShowCompany from '../pages/Config/Companies/ShowCompany';
import Users from '../pages/Config/Users';
import WorkersSegments from '../pages/Config/WorkersSegments';
import FowardComplaint from '../pages/Config/FowardComplaint';
// Categories
import NewsCategories from '../pages/Config/News/Category';
import CovidComplaints from '../pages/CovidComplaints';
import ShowCovidComplaint from '../pages/CovidComplaints/ShowCovidComplaint';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/complaints" exact component={Complaints} isPrivate />
      <Route path="/complaints/:id" exact component={ShowComplaint} isPrivate />
      <Route
        path="/covid-complaints"
        exact
        component={CovidComplaints}
        isPrivate
      />
      <Route
        path="/covid-complaints/:id"
        exact
        component={ShowCovidComplaint}
        isPrivate
      />
      <Route path="/partners" exact component={UnionsPartners} isPrivate />

      <Route path="/news" exact component={News} isPrivate />
      <Route
        path="/news/create"
        exact
        component={CreateNewsContent}
        isPrivate
      />
      <Route path="/news/:id" exact component={ShowNewsContent} isPrivate />

      <Route
        path="/notifications"
        exact
        component={Notifications}
        isPrivate
        isAdminOnly
      />

      <Route path="/financial/invoices" exact component={Invoices} isPrivate />
      <Route
        path="/financial/subscriptions"
        exact
        component={Subscriptions}
        isPrivate
      />
      <Route path="/financial" component={Invoices} exact isPrivate>
        <Redirect to="/financial/invoices" />
      </Route>

      <Route
        path="/research"
        exact
        component={Research}
        isPrivate
        isAdminOnly
      />
      <Route
        path="/research/:id"
        exact
        component={EditResearch}
        isPrivate
        isAdminOnly
      />
      <Route
        path="/research/results/:id"
        exact
        component={ResearchResults}
        isPrivate
        isAdminOnly
      />

      <Route path="/workers" exact component={Workers} isPrivate />
      <Route path="/workers/:id" exact component={Profile} isPrivate />

      <Route path="/settings" exact component={Config} isPrivate />
      <Route
        path="/settings/unions"
        exact
        component={Unions}
        isPrivate
        isAdminOnly
      />
      <Route
        path="/settings/unions/partners-categories"
        exact
        component={PartnersCategories}
        isPrivate
        isAdminOnly
      />
      <Route path="/settings/companies" exact component={Companies} isPrivate />
      <Route
        path="/settings/companies/:id"
        exact
        component={ShowCompany}
        isPrivate
      />
      <Route path="/settings/users" exact component={Users} isPrivate />
      <Route
        path="/settings/news"
        exact
        component={NewsCategories}
        isPrivate
        isAdminOnly
      >
        <Redirect to="/settings/news/categories" />
      </Route>
      <Route
        path="/settings/news/categories"
        exact
        component={NewsCategories}
        isPrivate
        isAdminOnly
      />
      <Route
        path="/settings/workers-segments"
        exact
        component={WorkersSegments}
        isPrivate
        isAdminOnly
      />
      <Route
        path="/settings/foward-complaint"
        exact
        component={FowardComplaint}
        isPrivate
        isAdminOnly
      />
    </Switch>
  );
};

export default Routes;
