/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  Button,
  Container as ReactContainer,
  Form,
} from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import { parse } from 'date-fns';
import { Container } from './styles';
import { useAuth } from '../../../../hooks/auth';

import api from '../../../../services/api';

interface ISelect {
  value: string;
  label: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface IInvoice {
  id: string;
  order: number;
  worker_id: string;
  syndicate_id: string;
  value: number;
  is_paid: boolean;
  invoice_date: Date;
  due_date: Date;
  worker: {
    id: string;
    first_name: string;
    last_name: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

interface IProps {
  handleInvoices: (invoices: IInvoice[]) => void;
  handleLoading: (value: boolean) => void;
}

const FilterBar: React.FC<IProps> = ({ handleInvoices, handleLoading }) => {
  const { user } = useAuth();

  const [unions, setUnions] = useState<ISelect[]>();

  const handleFilterInvoices = useCallback(
    async values => {
      Object.keys(values).forEach(
        value => values[value] === '' && delete values[value],
      );

      const { due_date: dueDate, ...rest } = values;

      try {
        const due_date = dueDate
          ? parse(dueDate, 'dd/MM/yyyy', new Date())
          : '';
        const filteredInvoices = await api.get('/financial/invoices', {
          params: { ...rest, due_date },
        });
        handleInvoices(filteredInvoices.data);
      } catch (error) {
        console.log(error);
      }
    },
    [handleInvoices],
  );

  const paymentStatus = [
    { value: 'paid', label: 'Pago' },
    { value: 'unpaid', label: 'Não pago' },
  ];

  const parseUnions = useCallback((loadedUnions: ISyndicate[]) => {
    return setUnions(
      loadedUnions.map(syndicate => {
        return {
          label: syndicate.nome_fantasia,
          value: syndicate.id,
        };
      }),
    );
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (user.role === 'admin') {
        const loadedUnions = await api.get('/unions');
        if (mounted) {
          parseUnions(loadedUnions.data);
        }
      }
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [parseUnions, user]);

  return (
    <Container>
      <ReactContainer fluid>
        <Formik
          initialValues={{
            worker_name: '',
            due_date: '',
            syndicate_id: '',
            is_paid: '',
          }}
          onSubmit={handleFilterInvoices}
        >
          {({
            handleSubmit,
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Nome do trabalhador"
                      name="worker_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.worker_name}
                      isValid={touched.worker_name && !errors.worker_name}
                      isInvalid={!!errors.worker_name}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicDueDate">
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.due_date && !errors.due_date}
                    >
                      <InputMask
                        mask="99/99/9999"
                        placeholder="Data de vencimento"
                        className="form-control"
                        onChange={handleChange}
                        name="due_date"
                        onBlur={handleBlur}
                        value={values.due_date}
                      />
                    </Form.Control>
                  </Form.Group>
                </Col>
                {user.role === 'admin' && (
                  <Col>
                    <Form.Group controlId="formUnions">
                      <Form.Control
                        as="div"
                        custom
                        isValid={touched.syndicate_id && !errors.syndicate_id}
                        isInvalid={!!errors.syndicate_id}
                      >
                        <Select
                          options={unions}
                          placeholder="Sindicato"
                          isClearable
                          name="syndicate_id"
                          onBlur={handleBlur}
                          onChange={event => {
                            if (event) {
                              return setFieldValue(
                                'syndicate_id',
                                event?.value,
                              );
                            }
                            return setFieldValue('syndicate_id', '');
                          }}
                        />
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Form.Group controlId="formStatus">
                    <Form.Control
                      as="div"
                      custom
                      isValid={touched.is_paid && !errors.is_paid}
                      isInvalid={!!errors.is_paid}
                    >
                      <Select
                        options={paymentStatus}
                        placeholder="Status"
                        isClearable
                        onChange={event => {
                          if (event) {
                            return setFieldValue(
                              'is_paid',
                              event?.value === 'paid',
                            );
                          }

                          return setFieldValue('is_paid', '');
                        }}
                        onBlur={handleBlur}
                        name="is_paid"
                      />
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col className="col-1 d-flex justify-content-end">
                  <Button variant="primary" type="submit">
                    <FiSearch color="#ffffff" size={20} />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ReactContainer>
    </Container>
  );
};

export default FilterBar;
