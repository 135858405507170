import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006633;
  width: 100%;
  height: 100%;
`;

export const SignInContainer = styled.div`
  background: #f6f7fc;
  padding: 24px;
`;
