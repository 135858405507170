import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Table, Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { FiSearch, FiTrash2, FiEdit, FiX } from 'react-icons/fi';
import BasePage from '../../../../components/BasePage';
import { useToast } from '../../../../hooks/useToast';

import { Aside } from './styles';

import api from '../../../../services/api';

interface IPosts {
  title: string;
}

interface ICategories {
  id: string;
  title: string;
  slug: string;
  parent_id: string;
  description: string;
  posts: IPosts[];
  childs: ICategories[];
}

const NewsCategories: React.FC = () => {
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [editCategory, setEditCategory] = useState<ICategories>(
    {} as ICategories,
  );

  const [handleShowDeleteModal, setHandleShowDeleteModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);

  const [crudCategorie, setCrudCategorie] = useState<string | string[]>();

  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    setHandleShowDeleteModal(false);
    setEditShowModal(false);
  }, []);

  // const handleSearch = useCallback(values => {
  //   console.log(values);
  // }, []);

  const handleShowEditModal = useCallback(async (id: string) => {
    setCrudCategorie(id);
    const loadCategory = await api.get(`/news/categories/${id}`);
    setEditCategory(loadCategory.data);
    setEditShowModal(true);
  }, []);

  const handleDeleteCategory = useCallback(async () => {
    try {
      await api.delete(`/news/categories/${crudCategorie}`);

      addToast({
        title: 'Sucesso',
        body: 'Categoria removida com sucesso.',
      });

      const loadCategories = await api.get('/news/categories');
      setCategories(loadCategories.data);

      handleClose();
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu um erro ao tentar remover a categoria. Tente novamente.',
      });
    }
  }, [crudCategorie, addToast, handleClose]);

  const handleCreateCategory = useCallback(
    async (values, { resetForm }) => {
      try {
        await api.post('/news/categories', values);
        const refreshCategories = await api.get('/news/categories');
        setCategories(refreshCategories.data);
        resetForm();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar adicionar uma nova categoria. Tente novamente.',
        });
      }
    },
    [addToast],
  );

  const handleUpdateCategory = useCallback(
    async ({ id, title, parent_id, description }) => {
      try {
        await api.patch(`/news/categories/${id}`, {
          title,
          parent_id,
          description,
        });
        const refreshCategories = await api.get('/news/categories');

        setCategories(refreshCategories.data);

        addToast({
          title: 'Sucesso',
          body: 'Categoria atualizada com sucesso.',
        });

        handleClose();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu um erro ao tentar adicionar uma nova categoria. Tente novamente.',
        });
      }
    },
    [addToast, handleClose],
  );

  useEffect(() => {
    document.title = 'Editar categorias - Viva Voz - Rede Suco de Laranja';

    api
      .get<ICategories[]>('/news/categories')
      .then(response => setCategories(response.data));
  }, []);

  return (
    <>
      <BasePage title="Categorias">
        <Row>
          <Col className="col-5">
            <Aside>
              <Row className="mb-3">
                <Col>
                  <h4>Adicionar nova categoria</h4>
                  <hr />
                </Col>
              </Row>
              <Formik
                initialValues={{
                  title: '',
                  parent_id: '',
                  description: '',
                }}
                onSubmit={handleCreateCategory}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupName">
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Digite o nome da categoria.."
                            onChange={handleChange('title')}
                            value={values.title}
                            isValid={touched.title && !errors.title}
                            isInvalid={!!errors.title}
                          />
                          <Form.Text id="nameHelpBlock" muted>
                            Nome que aparecerá no sistema
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupParent">
                          <Form.Label>Categoria Ascendente</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={handleChange('parent_id')}
                            value={values.parent_id}
                            isValid={touched.parent_id && !errors.parent_id}
                            isInvalid={!!errors.parent_id}
                          >
                            <option value="">Sem parente</option>
                            {categories.map(category => (
                              <option value={category.id} key={category.id}>
                                {category.title}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Text id="parentHelpBlock" muted>
                            Selecione a categoria da qual a nova categoria
                            descende
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="groupDescription">
                          <Form.Label>Descrição</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={handleChange('description')}
                            value={values.description}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Text id="descriptionHelpBlock" muted>
                            Descrição que detalha as categorias
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Button variant="primary" type="submit">
                          Adicionar nova categoria
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Aside>
          </Col>
          <Col className="col-7">
            {/* <Row className="mb-3">
              <Col className="col-7" />
              <Col className="col-5">
                <Formik
                  initialValues={{
                    search: '',
                  }}
                  onSubmit={handleSearch}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    resetForm,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        <Col
                          className="col-10 d-flex"
                          style={{ position: 'relative' }}
                        >
                          <Form.Group
                            className="d-flex justify-content-center align-items-center"
                            style={{ position: 'relative', flex: 1 }}
                          >
                            <Form.Control
                              type="text"
                              value={values.search}
                              onChange={handleChange('search')}
                              onBlur={handleBlur('search')}
                              placeholder="Pesquise a categoria"
                              style={{ paddingRight: '32px' }}
                            />
                            {values.search.length > 0 && (
                              <FiX
                                size={20}
                                color="#cccccc"
                                onClick={() => resetForm()}
                                style={{ position: 'absolute', right: 8 }}
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="col-2">
                          <Button variant="primary" type="submit">
                            <FiSearch size={20} color="#ffffff" />
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th
                        style={{
                          width: '8%',
                          textAlign: 'center',
                          verticalAlign: 'center',
                        }}
                      >
                        <Form.Check type="checkbox" />
                      </th> */}
                      <th style={{ width: '25%' }}>Nome</th>
                      <th>Descrição</th>
                      <th style={{ width: '8%', textAlign: 'center' }}>
                        Posts
                      </th>
                      <th style={{ width: '15%', textAlign: 'center' }}>
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map(category => (
                      <tr key={category.id}>
                        {/* <td
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'center',
                          }}
                        >
                          {category.slug !== 'categoria-padrao' && (
                            <Form.Check type="checkbox" value={category.id} />
                          )}
                        </td> */}
                        <td>{category.title}</td>
                        <td>{category.description}</td>
                        <td style={{ textAlign: 'center' }}>
                          {category.posts.length}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {category.slug !== 'categoria-padrao' && (
                            <>
                              <Button
                                variant="primary"
                                onClick={() => handleShowEditModal(category.id)}
                              >
                                <FiEdit size={20} color="#ffffff" />
                              </Button>
                              <Button
                                variant="danger"
                                className="ml-2"
                                onClick={() => {
                                  setCrudCategorie(category.id);
                                  setHandleShowDeleteModal(true);
                                }}
                              >
                                <FiTrash2 size={20} color="#ffffff" />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </BasePage>

      {/* //Delete modal */}
      <Modal
        show={handleShowDeleteModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Remover categoria</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Você confirma a remoção da categoria? Atenção, essa ação não poderá
            ser desfeita.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => handleDeleteCategory()}>
            Remover
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //Edit modal */}
      <Modal
        show={editShowModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            id: editCategory.id,
            title: editCategory.title,
            parent_id: editCategory.parent_id,
            description: editCategory.description,
          }}
          onSubmit={handleUpdateCategory}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar categoria</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupName">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o nome da categoria.."
                        onChange={handleChange('title')}
                        value={values.title}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                      <Form.Text id="nameHelpBlock" muted>
                        Nome que aparecerá no sistema
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupParent">
                      <Form.Label>Categoria Ascendente</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={handleChange('parent_id')}
                        value={values.parent_id}
                        isValid={touched.parent_id && !errors.parent_id}
                        isInvalid={!!errors.parent_id}
                      >
                        <option value="">Sem parente</option>
                        {categories.map(category => (
                          <option value={category.id} key={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Text id="parentHelpBlock" muted>
                        Selecione a categoria da qual a nova categoria descende
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="groupDescription">
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleChange('description')}
                        value={values.description}
                        isValid={touched.description && !errors.description}
                        isInvalid={!!errors.description}
                      />
                      <Form.Text id="descriptionHelpBlock" muted>
                        Descrição que detalha as categorias
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="success" type="submit">
                  Salvar
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default NewsCategories;
