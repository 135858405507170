import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BasePage from '../../components/BasePage';
import LoadingState from '../../components/LoadingState';
import FilterBar from '../../components/Complaints/FilterBar';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

interface IComplaints {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  company: {
    id: string;
    nome_fantasia: string;
  };
  city: {
    id: string;
    name: string;
  };
  is_archived: boolean;
  created_at: Date;
  updated_at: Date;
}

const Complaints: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingComplaints, setLoadingComplaints] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [complaints, setComplaints] = useState<IComplaints[]>();
  const [totalComplaints, setTotalComplaints] = useState(0);

  const receivedComplaints = complaints?.filter(
    complaint => complaint.status === 1,
  );

  const underAnalysisComplaints = complaints?.filter(
    complaint => complaint.status === 2,
  );

  useEffect(() => {
    document.title = 'Denúncias - Viva Voz - Rede Suco de Laranja';

    const loadData = async () => {
      const response = await api.get<IComplaints[]>('/complaints', {
        params: { page: currentPage, iscovid: false },
      });
      setComplaints(response.data);
      setTotalComplaints(response.headers['x-total-complaints']);

      setLoading(false);
      setLoadingComplaints(false);
    };

    loadData();
  }, [loading, currentPage]);

  return (
    <BasePage title="Denúncias">
      {loading && <LoadingState />}
      {loading === false && (
        <Container fluid>
          <FilterBar
            handleLoading={setLoadingComplaints}
            handleComplaints={setComplaints}
            currentPage={currentPage}
            pageHandler={setCurrentPage}
          />
          <Row>
            {loadingComplaints && <LoadingState />}
            {loadingComplaints === false && (
              <Col>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th style={{ width: '15%' }}>Protocolo</th>
                      <th>Empresa</th>
                      <th>Cidade</th>
                      <th style={{ width: '13%' }}>Recebido em</th>
                      <th style={{ width: '13%' }}>Última atualização</th>
                      <th style={{ width: '8%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {complaints && (
                      <>
                        <tr className="table-active">
                          <td colSpan={6}>Status: Recebido</td>
                        </tr>
                        {receivedComplaints?.map(complaint => (
                          <tr key={complaint.id}>
                            <>
                              <td>{complaint.protocol}</td>
                              <td>{complaint.company.nome_fantasia}</td>
                              <td>
                                {complaint.city
                                  ? complaint.city.name
                                  : 'Não especificado'}
                              </td>
                              <td>
                                {format(
                                  new Date(complaint.created_at),
                                  "dd/MM/yyyy ' às ' HH:mm",
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(complaint.updated_at),
                                  "dd/MM/yyyy ' às ' HH:mm",
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    // eslint-disable-next-line prettier/prettier
                                    history.push(`/complaints/${complaint.id}`);
                                  }}
                                >
                                  Visualizar
                                </Button>
                              </td>
                            </>
                          </tr>
                        ))}
                        <tr className="table-active">
                          <td colSpan={6}>Status: Em análise</td>
                        </tr>
                        {underAnalysisComplaints?.map(complaint => (
                          <tr key={complaint.id}>
                            <>
                              <td>{complaint.protocol}</td>
                              <td>{complaint.company.nome_fantasia}</td>
                              <td>
                                {complaint.city
                                  ? complaint.city.name
                                  : 'Não especificado'}
                              </td>
                              <td>
                                {format(
                                  new Date(complaint.created_at),
                                  "dd/MM/yyyy ' às ' HH:mm",
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(complaint.created_at),
                                  "dd/MM/yyyy ' às ' HH:mm",
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    // eslint-disable-next-line prettier/prettier
                                    history.push(`/complaints/${complaint.id}`);
                                  }}
                                >
                                  Visualizar
                                </Button>
                              </td>
                            </>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </Table>
                <Pagination
                  totalItems={totalComplaints}
                  currentPage={currentPage}
                  pageHandler={setCurrentPage}
                />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </BasePage>
  );
};

export default Complaints;
