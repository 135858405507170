import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, FieldArray } from 'formik';
import Select from 'react-select';
import { FiMinusCircle } from 'react-icons/fi';
import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

interface IAnswer {
  id: string;
  text: string;
}

interface IQuestion {
  id: string;
  text: string;
  type: string;
  answers: IAnswer[];
  research_id: string;
}

interface IResearch {
  id: string;
  title: string;
  status: string;
  description: string;
  start_date: Date;
  finish_date: Date;
  questions: IQuestion[];
}

interface ISelectedQuestion {
  questionId: string;
  action: 'edit' | 'remove';
}

interface IProps {
  questionId: string;
  show: boolean;
  handleShow: (value: boolean) => void;
  handleSelected: (value: ISelectedQuestion) => void;
  handleLoading: (value: boolean) => void;
  handleResearch: (research: IResearch) => void;
}

const EditQuestionModal: React.FC<IProps> = ({
  questionId,
  show,
  handleShow,
  handleSelected,
  handleLoading,
  handleResearch,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState<IQuestion>();
  const [answers, setAnswers] = useState<string[]>();

  const handleClose = useCallback(() => {
    setLoading(true);
    handleSelected({} as ISelectedQuestion);
    setQuestion({} as IQuestion);
    setAnswers(['']);
    handleShow(false);
  }, [handleShow, handleSelected]);

  const handleUpdateQuestion = useCallback(
    async values => {
      try {
        handleLoading(true);

        await api.put(`/research/question/${questionId}`, values);

        const updatedResearch = await api.get(
          `/research/${question?.research_id}`,
        );

        handleResearch(updatedResearch.data);

        addToast({
          title: 'Sucesso',
          body: 'A questão foi atualizada com sucesso.',
        });

        handleClose();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar atualizar a questão. Tente novamente.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [
      handleLoading,
      handleClose,
      questionId,
      addToast,
      handleResearch,
      question,
    ],
  );

  const questionTypes = [
    { value: 'radio', label: 'Única seleção' },
    { value: 'checkbox', label: 'Múltipla seleção' },
  ];

  useEffect(() => {
    const loadData = async () => {
      const questionData = await api.get<IQuestion>(
        `/research/question/${questionId}`,
      );
      const { answers: loadedAnswers } = questionData.data;

      setAnswers(loadedAnswers.map(answer => answer.text));

      setQuestion(questionData.data);
      setLoading(false);
    };

    if (loading && !!questionId) {
      loadData();
    }
  }, [loading, questionId]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!loading && question && (
        <Formik
          initialValues={{
            text: question.text,
            type: question.type,
            research_id: question.research_id,
            answers,
          }}
          onSubmit={handleUpdateQuestion}
        >
          {({ handleSubmit, values, handleChange, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar questão</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Texto da questão *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o texto da questão"
                        onChange={handleChange('text')}
                        value={values.text}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Tipo de resposta *</Form.Label>
                      <Select
                        options={questionTypes}
                        defaultValue={
                          values.type === 'radio'
                            ? questionTypes[0]
                            : questionTypes[1]
                        }
                        placeholder="Selecione um tipo de resposta"
                        onChange={event => setFieldValue('type', event?.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <FieldArray name="answers">
                  {({ push, remove }) => (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <Button
                            variant="outline-primary"
                            onClick={() => push('')}
                          >
                            Adicionar resposta
                          </Button>
                        </Col>
                      </Row>
                      {values.answers?.map((answer, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index}>
                          <Col className="d-flex flex-row align-items-center">
                            <Form.Group
                              controlId={`formBasicText-${index}`}
                              style={{ flex: 1 }}
                            >
                              <Field
                                name={`answers.${index}`}
                                type="text"
                                placeholder="Digite uma resposta para a questão"
                                className="form-control"
                              />
                            </Form.Group>
                            <Button
                              variant="danger"
                              className="ml-1"
                              style={{ marginBottom: 16 }}
                              onClick={() => remove(index)}
                            >
                              <FiMinusCircle color="#ffffff" size={18} />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </FieldArray>
                <hr />
                <Row>
                  <Col>
                    <Form.Text className="text-muted">
                      Campos com * são obrigatórios.
                    </Form.Text>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Salvar alterações
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditQuestionModal;
