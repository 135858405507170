/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FiArchive } from 'react-icons/fi';
import { Formik } from 'formik';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
  Form as ReactForm,
} from 'react-bootstrap';

import BasePage from '../../../components/BasePage';
import Message from '../../../components/Message';
import LoadingState from '../../../components/LoadingState';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';

import api from '../../../services/api';

import { useToast } from '../../../hooks/useToast';

import {
  Main,
  Chat,
  ChatHeader,
  ChatBody,
  MessageContainer,
  MessageHelper,
  MessageList,
  ChatFooter,
  Protocol,
  Form,
  CovidContainer,
  CovidItem,
  CovidTitle,
  CovidValue,
} from './styles';
import { covidRisksArray, whereGotCovidArray } from '../covidValues';

interface IMessages {
  id: string;
  key_from_server: 'income' | 'outcome';
  message_type: 'text' | 'audio' | 'document' | 'image';
  data: string;
  media_url: string;
  media_server_url: string;
  media_mime_type: string;
  media_size: number;
  media_server_size: number;
  latitude: number;
  longitude: number;
  created_at: Date;
}

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  role: string;
}

interface IComplaint {
  id: string;
  protocol: string;
  status: number;
  priority: number;
  company_id: string;
  messages: IMessages[];
  assignedUsers: IUser[];
  is_archived: boolean;
  got_covid: string;
  got_covid_more: string;
  how_many_got_covid: string;
  where_got_covid: string;
  covid_risk: string;
}

interface ILog {
  id: string;
  type: string;
  module: string;
  message: string;
  feedback: string;
  created_at: Date;
}

interface IResponse {
  complaint: IComplaint;
  logs: ILog[];
}

interface IInputHandle {
  message: string;
}

const ShowCovidComplaint: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { addToast } = useToast();

  const [loadingData, setLoadingData] = useState(true);
  const [complaintInfo, setComplaintInfo] = useState<IComplaint>();
  const [logs, setLogs] = useState<ILog[]>();
  const [messages, setMessages] = useState<IMessages[]>([]);
  const [statusButton, setStatusButton] = useState<number>();
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [complaintStatus, setComplaintStatus] = useState<number>();
  const whereList = whereGotCovidArray;
  const riskList = covidRisksArray;
  const [wheregot, setWhereGot] = useState<
    { label: string; value: number }[] | undefined
  >();
  const [risks, setRisks] = useState<
    { label: string; value: number }[] | undefined
  >();

  const handleUpdateStatus = useCallback(
    async values => {
      try {
        await api.put(`/complaints/${id}`, {
          status: complaintStatus,
          feedback: values.feedback,
        });

        setStatusButton(complaintStatus);

        setShowUpdateStatusModal(false);

        addToast({
          title: 'Sucesso',
          body: 'Denúncia atualizada com sucesso.',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar atualizar a denúncia.',
        });
      }
    },
    [id, addToast, complaintStatus],
  );

  const handleArchive = useCallback(
    async values => {
      try {
        await api.put(`/complaints/${id}`, {
          is_archived: true,
          feedback: values.feedback,
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar arquivar a denúncia.',
        });
      } finally {
        addToast({
          title: 'Sucesso',
          body: 'Denúncia arquivada com sucesso.',
        });
        setShowArchiveModal(false);
        history.push('/complaints');
      }
    },
    [id, addToast, history],
  );

  const handleUnarchive = useCallback(async () => {
    try {
      await api.put(`/complaints/${id}`, {
        is_archived: false,
      });
    } catch (error) {
      addToast({
        title: 'Erro',
        body: 'Ocorreu algum erro ao tentar desarquivar a denúncia.',
      });
    } finally {
      addToast({
        title: 'Sucesso',
        body: 'Denúncia desarquivada com sucesso.',
      });
    }
  }, [id, addToast]);

  const handleSendMessage = useCallback(
    async (values: IInputHandle, { resetForm }) => {
      const { message } = values;

      resetForm({});

      await api
        .post('/messages', { message, complaint_id: id })
        .then(response => setMessages([...messages, response.data]));
    },
    [id, messages],
  );

  useEffect(() => {
    async function loadData() {
      await api.get<IResponse>(`/complaints/${id}`).then(response => {
        setComplaintInfo(response.data.complaint);
        setMessages(response.data.complaint.messages);
        setStatusButton(response.data.complaint.status);
        setLogs(response.data.logs);
      });
      setLoadingData(false);
    }

    if (loadingData) {
      loadData();
    }
    getWhere();
    getRisks();
  }, [loadingData, id]);

  async function getWhere() {
    if (!complaintInfo) return;

    const res: any = [];

    const values = complaintInfo?.where_got_covid.split(',');
    for await (const item of whereList) {
      for await (const it of values) {
        if (it.toString() === item.value.toString()) {
          res.push(item);
        }
      }
    }
    setWhereGot(res);
  }

  async function getRisks() {
    if (!complaintInfo) return;

    const res: any = [];
    const values = complaintInfo?.covid_risk.split(',');
    for await (const item of riskList) {
      for await (const it of values) {
        if (it.toString().trim() === item.value.toString()) {
          res.push(item);
        }
      }
    }
    setRisks(res);
  }

  return (
    <BasePage title="Denúncia">
      {loadingData && <LoadingState />}
      {loadingData === false && (
        <Container style={{ height: '100%' }} fluid>
          <Row style={{ height: '100%' }}>
            <Col className="col-3">
              <LeftSidebar complaint={complaintInfo} />
            </Col>
            <Col>
              <Main>
                <Chat>
                  <ChatHeader>
                    <Protocol>{complaintInfo?.protocol}</Protocol>
                    {statusButton === 1 && (
                      <ButtonGroup className="ml-auto">
                        <Button>Status: Enviado</Button>

                        <DropdownButton
                          as={ButtonGroup}
                          title=""
                          id="bg-nested-dropdown"
                          style={{ zIndex: 1100 }}
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              setComplaintStatus(2);
                              setShowUpdateStatusModal(true);
                            }}
                          >
                            Marcar como em análise
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => {
                              setComplaintStatus(3);
                              setShowUpdateStatusModal(true);
                            }}
                          >
                            Marcar como finalizado
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                    )}
                    {statusButton === 2 && (
                      <ButtonGroup className="ml-auto">
                        <Button>Status: Em análise</Button>

                        <DropdownButton
                          as={ButtonGroup}
                          title=""
                          id="bg-nested-dropdown"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              setComplaintStatus(3);
                              setShowUpdateStatusModal(true);
                            }}
                          >
                            Marcar como finalizado
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                    )}
                    {statusButton === 3 && (
                      <Button className="ml-auto" variant="primary">
                        Status: Finalizado
                      </Button>
                    )}
                    {!complaintInfo?.is_archived && (
                      <Button
                        variant="secondary"
                        className="ml-1"
                        onClick={() => setShowArchiveModal(true)}
                      >
                        <FiArchive color="#ffffff" size={20} />
                      </Button>
                    )}
                    {complaintInfo?.is_archived && (
                      <Button
                        variant="secondary"
                        className="ml-1"
                        onClick={() => handleUnarchive()}
                      >
                        Desarquivar
                      </Button>
                    )}
                  </ChatHeader>
                  <ChatBody>
                    <MessageContainer>
                      <MessageHelper />
                      <MessageList>
                        <CovidContainer>
                          <CovidItem>
                            <CovidTitle>Teve Covid?</CovidTitle>
                            <CovidValue>{complaintInfo?.got_covid}</CovidValue>
                          </CovidItem>
                          <CovidItem>
                            <CovidTitle>Teve Covid mais de uma vez?</CovidTitle>
                            <CovidValue>
                              {complaintInfo?.got_covid_more}
                            </CovidValue>
                          </CovidItem>
                          <CovidItem>
                            <CovidTitle>
                              Quantos colegas de trabalho tiveram covid?
                            </CovidTitle>
                            <CovidValue>
                              {complaintInfo?.how_many_got_covid}
                            </CovidValue>
                          </CovidItem>
                          <CovidItem>
                            <CovidTitle>
                              Onde acha que se contaminou?
                            </CovidTitle>

                            {wheregot?.map(x => (
                              <CovidValue>{`- ${x.label}`}</CovidValue>
                            ))}
                          </CovidItem>
                          <CovidItem>
                            <CovidTitle>
                              Quais riscos de covid existem onde trabalha?
                            </CovidTitle>

                            {risks?.map(x => (
                              <CovidValue>{`- ${x.label}`}</CovidValue>
                            ))}
                          </CovidItem>
                        </CovidContainer>

                        {messages.map(message => (
                          <Message content={message} key={message.id} />
                        ))}
                      </MessageList>
                    </MessageContainer>
                  </ChatBody>
                  <ChatFooter>
                    <Formik
                      initialValues={{
                        message: '',
                      }}
                      onSubmit={handleSendMessage}
                    >
                      {({ values, handleBlur, handleChange, handleSubmit }) => (
                        <Form>
                          <Form.Group controlId="message">
                            <Form.Control
                              as="textarea"
                              rows={1}
                              placeholder="Comece digitando aqui a sua mensagem..."
                              onChange={handleChange('message')}
                              onBlur={handleBlur('message')}
                              value={values.message}
                              disabled={complaintInfo?.status === 3}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            onClick={() => handleSubmit()}
                            disabled={complaintInfo?.status === 3}
                          >
                            Enviar
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </ChatFooter>
                </Chat>
              </Main>
            </Col>
            <Col className="col-3">
              <RightSidebar
                complaint={complaintInfo}
                messages={messages}
                logs={logs}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        show={showUpdateStatusModal}
        onHide={() => setShowUpdateStatusModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            feedback: '',
          }}
          onSubmit={handleUpdateStatus}
        >
          {({ handleSubmit, values, handleChange }) => (
            <ReactForm onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Atualizar status da denúncia</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Para atualizar o status da denúncia, repasse o feedback do
                  andamento para a TIE.
                </p>
                <ReactForm.Group controlId="feedback-form">
                  <ReactForm.Label>Feedback</ReactForm.Label>
                  <ReactForm.Control
                    as="textarea"
                    rows={5}
                    placeholder="Descreva o encaminhamento da denúncia"
                    name="feedback"
                    onChange={handleChange('feedback')}
                    value={values.feedback}
                  />
                </ReactForm.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowUpdateStatusModal(false)}
                >
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Atualizar
                </Button>
              </Modal.Footer>
            </ReactForm>
          )}
        </Formik>
      </Modal>

      <Modal
        show={showArchiveModal}
        onHide={() => setShowArchiveModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            feedback: '',
          }}
          onSubmit={handleArchive}
        >
          {({ handleSubmit, values, handleChange }) => (
            <ReactForm onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Arquivar denúncia</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Para arquivar a denúncia, informe o motivo para a TIE.</p>
                <ReactForm.Group controlId="feedback-form">
                  <ReactForm.Label>Feedback</ReactForm.Label>
                  <ReactForm.Control
                    as="textarea"
                    rows={5}
                    placeholder="Descreva o motivo do arquivamento da denúncia para a TIE"
                    name="feedback"
                    onChange={handleChange('feedback')}
                    value={values.feedback}
                  />
                </ReactForm.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowArchiveModal(false)}
                >
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Atualizar
                </Button>
              </Modal.Footer>
            </ReactForm>
          )}
        </Formik>
      </Modal>
    </BasePage>
  );
};

export default ShowCovidComplaint;
