import styled from 'styled-components';

interface IKeyProps {
  type: 'income' | 'outcome';
}

export const Container = styled.div<IKeyProps>`
  background: ${props => (props.type === 'income' ? '#f7f7f7' : '#cef2e0')};
  border-radius: 10px;
  max-width: 65%;
  width: fit-content;
  margin-bottom: 8px;
  padding: 16px;
  position: relative;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.type === 'income' ? 'flex-start' : 'flex-end')};

  .timestamp {
    font-weight: 400;
    font-size: 10px;
    text-align: right;
    margin-top: 4px;
    color: gray;
  }
`;

export const DocumentWrapper = styled.a`
  padding: 8px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  cursor: pointer;

  color: black;

  &:hover {
    color: black;
  }

  span {
    svg {
      margin-right: 8px;
    }
  }

  svg {
    margin-left: 16px;
  }
`;
