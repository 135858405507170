import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import Select from 'react-select';
import { useToast } from '../../../hooks/useToast';

import api from '../../../services/api';

interface ISelect {
  value: string;
  label: string;
}

interface ISegment {
  id: string;
  name: string;
}

interface IProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  handleNotifications: (notifications: []) => void;
  handleTotalItems: (number: number) => void;
}

const CreateNotificationModal: React.FC<IProps> = ({
  show,
  handleShow,
  handleLoading,
  handleNotifications,
  handleTotalItems,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [segments, setSegments] = useState<ISelect[]>([]);
  const [showSegmentsField, setShowSegmentsField] = useState(true);

  const handleClose = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleCreateNotification = useCallback(
    async values => {
      try {
        handleLoading(true);
        await api.post('/mobile-notifications', values);

        addToast({
          title: 'Sucesso',
          body: 'Notificação enviada com sucesso.',
        });

        const updatedNotifications = await api.get('/mobile-notifications');

        const { total_count, notifications: loadedNotifications } =
          updatedNotifications.data;

        handleNotifications(loadedNotifications);
        handleTotalItems(total_count);

        handleClose();
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao enviar a notificação.',
        });
      } finally {
        handleLoading(false);
      }
    },
    [
      handleLoading,
      handleClose,
      addToast,
      handleTotalItems,
      handleNotifications,
    ],
  );

  const parseSegments = useCallback((workersSegments: ISegment[]) => {
    const parsedSegments = workersSegments.map(segment => {
      return {
        value: segment.id,
        label: segment.name,
      };
    });

    setSegments(parsedSegments);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const loadedSegments = await api.get<ISegment[]>('/workers-segments');
      parseSegments(loadedSegments.data);

      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading, parseSegments]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{
          name: '',
          sendToAll: false,
          segment_id: '',
          title: '',
          message: '',
        }}
        onSubmit={handleCreateNotification}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Enviar notificação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="nome">
                    <Form.Label>Nome da notificação</Form.Label>
                    <Form.Control
                      placeholder="Nome"
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />
                    <Form.Text className="text-muted">
                      Campo usado apenas internamente.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="nome">
                    <Form.Label>Enviar para todos dispositivos?</Form.Label>
                    <div key="inline-radio" className="mb-3">
                      <Form.Check
                        inline
                        label="Não"
                        name="sendAllDevices"
                        type="radio"
                        id="inline-FALSE"
                        value="false"
                        checked={!!values.sendToAll === false}
                        onChange={() => {
                          setFieldValue('sendToAll', false);
                          setShowSegmentsField(true);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Sim"
                        name="sendAllDevices"
                        type="radio"
                        id="inline-TRUE"
                        value="true"
                        checked={!!values.sendToAll === true}
                        onChange={() => {
                          setFieldValue('sendToAll', true);
                          setShowSegmentsField(false);
                        }}
                      />
                    </div>
                    <Form.Text className="text-muted">
                      Se marcado, todos os dispositivos com o aplicativo
                      instalado receberão a notificação.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {showSegmentsField && (
                <Row>
                  <Col>
                    <Form.Group controlId="nome">
                      <Form.Label>Segmento de Trabalhadores</Form.Label>
                      <Select
                        options={segments}
                        placeholder="Selecione o segmento"
                        isClearable
                        onChange={selectedSegment => {
                          setFieldValue('segment_id', selectedSegment?.value);
                        }}
                      />
                      <Form.Text className="text-muted">
                        Selecione o segmento de usuários que receberão a
                        notificação.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <hr />
              <Row>
                <Col>
                  <Form.Group controlId="nome">
                    <Form.Label>Título da notificação</Form.Label>
                    <Form.Control
                      placeholder="Título"
                      onChange={handleChange('title')}
                      onBlur={handleBlur('title')}
                      isValid={touched.title && !errors.title}
                      isInvalid={!!errors.title}
                    />
                    <Form.Text className="text-muted">
                      O usuário receberá como título.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="nome">
                    <Form.Label>Mensagem</Form.Label>
                    <Form.Control
                      placeholder="Mensagem"
                      onChange={handleChange('message')}
                      onBlur={handleBlur('message')}
                      isValid={touched.message && !errors.message}
                      isInvalid={!!errors.message}
                    />
                    <Form.Text className="text-muted">
                      O usuário receberá como mensagem da notificação. Opte por
                      mensagens curtas.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateNotificationModal;
