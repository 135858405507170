import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { FiSearch } from 'react-icons/fi';

import { Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';

import { useToast } from '../../../../hooks/useToast';

import api from '../../../../services/api';

import { Container } from './styles';

interface ISelect {
  [key: string]: any;
}

interface IState {
  id: number;
  uf: string;
}

interface ISyndicateInfo {
  id: string;
  nome_fantasia: string;
  razao_social: string;
  cnpj: string;
  email: string;
  type: string;
  phone: string;
  address: string;
  address2: string;
  city_id: number;
  state: string;
  postal_code: string;
  avatar: string;
  discount_percentage: number;
}

interface IProps {
  handleUnions: (data: ISyndicateInfo[]) => void;
  handleLoading: (value: boolean) => void | boolean;
  currentPage: number;
  pageHandler: (value: number) => void | number;
}

const FilterBar: React.FC<IProps> = ({
  handleUnions,
  handleLoading,
  currentPage,
  pageHandler,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState<ISelect[]>([]);

  const unionsType = useMemo(
    () => [
      { value: 'industrial', label: 'Industrial' },
      { value: 'rural', label: 'Rural' },
      { value: 'market', label: 'Comércio' },
      { value: 'public-service', label: 'Serviço Público' },
    ],
    [],
  );

  const filterUnions = useCallback(
    async values => {
      try {
        handleLoading(true);
        const response = await api.get('/unions', {
          params: { ...values, page: currentPage },
        });
        pageHandler(1);
        handleUnions(response.data);
        handleLoading(false);
      } catch (error) {
        addToast({
          title: 'Erro',
          body: 'Ocorreu algum erro ao tentar filtrar as notícias.',
        });
      }
    },
    [addToast, handleUnions, handleLoading, currentPage, pageHandler],
  );

  const parseStates = useCallback(async (loadedStates: IState[]) => {
    const parsedStates = await Promise.all(
      loadedStates.map(state => {
        return {
          label: state.uf,
          value: state.uf,
        };
      }),
    );

    setStates(parsedStates);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const loadedStates = await api.get<IState[]>('/ibge/states');
      parseStates(loadedStates.data);
      setLoading(false);
    };

    if (loading) {
      loadData();
    }
  }, [loading, parseStates]);

  return (
    <Container>
      <Formik
        initialValues={{
          nome_fantasia: '',
          type: '',
          state: '',
        }}
        onSubmit={filterUnions}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="SearchByName">
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    onChange={handleChange('nome_fantasia')}
                    value={values.nome_fantasia}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="FilterPostsByType">
                  <Select
                    placeholder="Tipo"
                    options={unionsType}
                    onChange={type => setFieldValue('type', type?.value)}
                    isClearable
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="FilterPostsByType">
                  <Select
                    placeholder="Estado"
                    options={states}
                    onChange={state => setFieldValue('state', state?.value)}
                    isClearable
                  />
                </Form.Group>
              </Col>
              <Col className="col-1">
                <Button variant="primary" type="submit">
                  <FiSearch color="#ffffff" size={20} />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FilterBar;
