import React, { useEffect, useCallback, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Container as ReactContainer,
  Form,
} from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import Select from 'react-select';
import { Formik } from 'formik';
import { Container } from './styles';
import { useAuth } from '../../../../hooks/auth';

import api from '../../../../services/api';

interface ISelect {
  value: string;
  label: string;
}

interface ISyndicate {
  id: string;
  nome_fantasia: string;
}

interface ISubscription {
  id: string;
  base_salary: number;
  discount_percentage: number;
  discount_value: number;
  recurring_frequency:
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'semester'
    | 'yearly';
  is_active: boolean;
  invoice_date: Date;
  next_invoice_date: Date;
  worker: {
    id: string;
    first_name: string;
    last_name: string;
  };
  syndicate: {
    id: string;
    nome_fantasia: string;
  };
}

interface IProps {
  handleSubscriptions: (subcriptions: ISubscription[]) => void;
}

const FilterBar: React.FC<IProps> = ({ handleSubscriptions }) => {
  const { user } = useAuth();

  const [unions, setUnions] = useState<ISelect[]>();

  const handleFilterSubscriptions = useCallback(
    async ({ recurring_status: status, ...rest }) => {
      try {
        let recurring_status;
        if (status) {
          recurring_status = status === 'active';
        }
        const loadedSubscriptions = await api.get('/financial/subscriptions', {
          params: { ...rest, is_active: recurring_status },
        });

        handleSubscriptions(loadedSubscriptions.data);
      } catch (error) {
        console.log(error);
      }
    },
    [handleSubscriptions],
  );

  const recurringStatus = [
    { value: 'active', label: 'Ativo' },
    { value: 'inactive', label: 'Inativo' },
  ];

  const recurringFrequency = [
    { value: 'monthly', label: 'Mensal' },
    { value: 'bimonthly', label: 'Bimestral' },
    { value: 'quarterly', label: 'Trimestral' },
    { value: 'semester', label: 'Semestral' },
    { value: 'yearly', label: 'Anual' },
  ];

  const parseUnions = useCallback((loadedUnions: ISyndicate[]) => {
    return setUnions(
      loadedUnions.map(syndicate => {
        return {
          label: syndicate.nome_fantasia,
          value: syndicate.id,
        };
      }),
    );
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (user.role === 'admin') {
        const loadedUnions = await api.get('/unions');
        if (mounted) {
          parseUnions(loadedUnions.data);
        }
      }
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [parseUnions, user]);

  return (
    <Container>
      <ReactContainer fluid>
        <Formik
          initialValues={{
            worker_name: '',
            syndicate_id: '',
            recurring_frequency: '',
            recurring_status: '',
          }}
          onSubmit={handleFilterSubscriptions}
        >
          {({
            handleSubmit,
            values,
            errors,
            setFieldValue,
            touched,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="formWorkerName">
                    <Form.Control
                      type="text"
                      placeholder="Nome do trabalhador"
                      onChange={handleChange}
                      name="worker_name"
                      onBlur={handleBlur}
                      value={values.worker_name}
                      isValid={touched.worker_name && !errors.worker_name}
                      isInvalid={!!errors.worker_name}
                    />
                  </Form.Group>
                </Col>
                {user.role === 'admin' && (
                  <Col>
                    <Form.Group controlId="formUnion">
                      <Form.Control
                        isValid={touched.syndicate_id && !errors.syndicate_id}
                        isInvalid={!!errors.syndicate_id}
                        as="div"
                        custom
                      >
                        <Select
                          options={unions}
                          placeholder="Sindicato"
                          isClearable
                          name="syndicate_id"
                          onBlur={handleBlur}
                          onChange={event => {
                            if (event) {
                              return setFieldValue(
                                'syndicate_id',
                                event?.value,
                              );
                            }
                            return setFieldValue('syndicate_id', '');
                          }}
                        />
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Form.Group controlId="formRecurringFrequency">
                    <Form.Control
                      as="div"
                      custom
                      isValid={
                        touched.recurring_frequency &&
                        !errors.recurring_frequency
                      }
                      isInvalid={!!errors.recurring_frequency}
                    >
                      <Select
                        options={recurringFrequency}
                        placeholder="Frequência"
                        isClearable
                        name="recurring_frequency"
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue(
                              'recurring_frequency',
                              event?.value,
                            );
                          }
                          return setFieldValue('recurring_frequency', '');
                        }}
                      />
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formStatus">
                    <Form.Control
                      as="div"
                      custom
                      isValid={
                        touched.recurring_status && !errors.recurring_status
                      }
                      isInvalid={!!errors.recurring_status}
                    >
                      <Select
                        options={recurringStatus}
                        placeholder="Status"
                        isClearable
                        onBlur={handleBlur}
                        onChange={event => {
                          if (event) {
                            return setFieldValue(
                              'recurring_status',
                              event?.value,
                            );
                          }
                          return setFieldValue('recurring_status', '');
                        }}
                      />
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col className="col-1 d-flex justify-content-end">
                  <Button variant="primary" type="submit">
                    <FiSearch color="#ffffff" size={20} />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ReactContainer>
    </Container>
  );
};

export default FilterBar;
