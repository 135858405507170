import React, { useContext, createContext, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ToastContainer from '../components/Toast';

interface ToastContent {
  id?: string;
  title: string;
  body: string;
  type?: string;
}

interface ToastContextData {
  addToast(data: ToastContent): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastContent[]>([]);

  const addToast = useCallback(({ title, body, type }: ToastContent) => {
    const toast = {
      id: uuid(),
      title,
      body,
      type,
    };

    setMessages(oldMessages => [...oldMessages, toast]);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      <ToastContainer messages={messages} />
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used with a ToastProvider');
  }

  return context;
}

export { useToast, ToastProvider };
